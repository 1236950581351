import React, { useEffect, useState } from 'react';
import moment from 'moment';
import StyledLink from '../StyledComponents/StyledLink';
import StyledTitle from '../StyledComponents/StyledTitle';
import StyledModal from '../StyledComponents/StyledModal';
import useTypedSelector from '../../hooks/useTypedSelector';
import { DateTime } from '../../assets/vars';
import errorIcon from '../../assets/img/popup-alert-icon.svg';
import { IParams } from '../../types/test';
import { useActions } from '../../hooks/useActions';
import * as Styled from './StyledInterviewRecord';
import { attentionIcon, dateIcon, timeIcon } from './icons';
import InterviewInfo from './InterviewInfo/InterviewInfo';

export interface VacancyInterview {
  id: string;
  name: string;
  max_place: string;
  date: number | string;
}

interface PropsInterface {
  interview?: string;
  vacancyID: IParams;
}

export interface VAI {
  [key: string]: VacancyInterview[];
}

const InterviewRecord: React.FC<PropsInterface> = ({
  vacancyID,
  interview,
}) => {
  const { profile } = useTypedSelector(r => r.auth);
  const { timeSlots, registration } = useTypedSelector(r => r.interview);
  const { vacancy, currentStage } = useTypedSelector(r => r.automatic);
  const {
    fetchInterviewSlots,
    fetchInterviewVacancyUser,
    sendInterviewReg,
  } = useActions();
  const [interviews, setInterviews] = useState<VAI>({});
  const [slotsPerDate, setSlotsPerDate] = useState<VacancyInterview[]>([]);
  const [checked, setChecked] = useState({ date: '', time: '' });
  const [error, setError] = useState<boolean>(false);

  const handleViewSlots = (date: string) => {
    setChecked(prev => {
      return { ...prev, date };
    });
    setSlotsPerDate(interviews[date]);
  };

  const handleTime = (time: string) => {
    setChecked(prev => {
      return { ...prev, time };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (checked.time) {
      const vacId = slotsPerDate[0].id;
      sendInterviewReg(vacId, vacancyID as string);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (currentStage.action === 'START') {
      fetchInterviewSlots(vacancy.id);
    } else if (currentStage.action === 'FINISH') {
      fetchInterviewVacancyUser(vacancy.id);
    }
  }, [currentStage.action]);

  useEffect(() => {
    const b: VAI = {};
    timeSlots.slots.forEach((item: VacancyInterview) => {
      const key = moment(item.date, DateTime.API_DATE_FORMAT_RESPONSE).unix();
      const i = String(moment.unix(key).format('YYYYMMDD'));
      if (b[i]) {
        b[i].push(item);
      } else {
        b[i] = [];
        b[i].push(item);
      }
    });
    setInterviews(b);
  }, [timeSlots.slots]);

  if (timeSlots.error) {
    return <div>Error</div>;
  }

  return (
    <>
      {currentStage.action === 'START' && (
        <>
          {error && (
            <StyledModal setOpened={setError} icon={errorIcon}>
              <div className="text-wrap">
                <h2 className="title">Ошибка</h2>
                <p className="text">Выберите дату и время собеседования</p>
              </div>
            </StyledModal>
          )}
          <Styled.FinishBlock>
            <div className="row justify-content-lg-between">
              <Styled.Col1 className="col-xl-7 col-lg-7">
                <Styled.Name>
                  {profile.last_name} {profile.name}
                </Styled.Name>
                <StyledTitle>
                  Поздравляем с прохождением всех этапов!
                </StyledTitle>
                <Styled.Text>
                  Сейчас мы уверены, что вы именно тот сотрудник, кто нам нужен.
                </Styled.Text>
                {interview === 'online' && (
                  <Styled.AttentionBlock>
                    {attentionIcon}
                    <h3 className="title">Внимание</h3>
                    <p className="text">
                      Собеседование будет проходить в формате online-конференции
                    </p>
                  </Styled.AttentionBlock>
                )}
                {interview === 'offline' && (
                  <Styled.AttentionBlock $offline>
                    {attentionIcon}
                    <h3 className="title">Внимание</h3>
                    <p className="text">
                      Собеседование будет проходить по адресу ул. Барклая, 6
                      строение 5 Москва, 121087
                    </p>
                  </Styled.AttentionBlock>
                )}
              </Styled.Col1>
              <div className="col-xl-5 col-lg-5">
                <Styled.FormTitle>
                  Выберите удобную дату и время для записи на собеседование:
                </Styled.FormTitle>
                <form onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
                  <Styled.Fieldset>
                    <legend>{dateIcon} Дата собеседования:</legend>
                    <Styled.RadioWrapper>
                      {Object.keys(interviews).map(date => {
                        return (
                          <Styled.Radio key={date}>
                            <input
                              className="visually-hidden"
                              onClick={() => {
                                handleViewSlots(date);
                              }}
                              type="radio"
                              name="date"
                              value={`date${date}`}
                              id={`date${date}`}
                              defaultChecked={date === checked.date}
                            />
                            <label htmlFor={`date${date}`}>
                              {moment(date, 'YYYYMMDD').format('DD.MM.YYYY')}
                            </label>
                          </Styled.Radio>
                        );
                      })}
                    </Styled.RadioWrapper>
                  </Styled.Fieldset>
                  <Styled.Fieldset>
                    <legend>{timeIcon} Время собеседования:</legend>
                    <Styled.RadioWrapper>
                      {slotsPerDate.map(item => (
                        <Styled.Radio key={item.date}>
                          <input
                            className="visually-hidden"
                            type="radio"
                            name="time"
                            value={`time1-${item.date}`}
                            id={`itime1-${item.date}`}
                            onClick={() => handleTime(String(item.date))}
                          />
                          <label htmlFor={`itime1-${item.date}`}>
                            {moment(
                              item.date,
                              DateTime.API_DATE_FORMAT_RESPONSE,
                            ).format('HH:mm')}
                          </label>
                        </Styled.Radio>
                      ))}
                    </Styled.RadioWrapper>
                  </Styled.Fieldset>
                  <Styled.LinkWrap>
                    <StyledLink type="submit" button text="Записаться" />
                  </Styled.LinkWrap>
                </form>
              </div>
            </div>
          </Styled.FinishBlock>
        </>
      )}

      {currentStage.action === 'FINISH' && registration.date && (
        <InterviewInfo registration={registration} />
      )}
    </>
  );
};

export default InterviewRecord;
