import React, { useEffect, useMemo } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Presentation from '../../components/Presentation/Presentation';
import TrialWork from '../../components/TrialWork/TrialWork';
import StyledLoader from '../../components/StyledComponents/StyledLoader';
import StyledProgress from '../../components/StyledComponents/StyledProgress';
import { useActions } from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';
import * as Styled from './StyledPassing';
import { getPageType, PageStateEnum } from './functions';
import TestText from '../../components/TestText/TestText';
import TestAudioVideo from '../../components/TestAudioVideo/TestAudioVideo';
import InterviewRecord from '../../components/InterviewRecord/InterviewRecord';
import { logoIcon } from './icons';

const Passing: React.FC = () => {
  const history = useHistory();
  const {
    checkAuthorization,
    fetchStageVacancy,
    fetchCurrentStage,
    fetchVacancy,
    fetchProfile,
    setNextStage,
    fetchCompany,
  } = useActions();
  const { auth } = useTypedSelector(r => r.auth);
  const { vacancy, stages, currentStage } = useTypedSelector(r => r.automatic);

  useEffect(() => {
    if (auth.isAuth === null) {
      checkAuthorization();
    } else if (auth.isAuth === false) {
      history.push('/login');
    } else {
      fetchProfile();
    }
  }, [auth.isAuth]);

  const params: { id: string } = useParams();
  const vacancyID = params ? params.id : null;

  useEffect(() => {
    fetchVacancy(vacancyID);
  }, [vacancyID]);

  useEffect(() => {
    if (vacancy.id !== null) {
      fetchStageVacancy(vacancy.id);
      fetchCurrentStage(vacancy.id);
      fetchCompany(vacancy.company_id);
    }
  }, [vacancy.id]);

  useEffect(() => {
    if (currentStage.group === null) {
      setNextStage(vacancy.id);
    }
  }, [currentStage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage.group]);

  const viewPage = useMemo(() => {
    switch (getPageType(currentStage.group).page) {
      case PageStateEnum.RESUME: {
        return <Presentation vacancyID={vacancyID} />;
      }
      case PageStateEnum.TRIALWORK: {
        return <TrialWork vacancyID={vacancyID} />;
      }
      case PageStateEnum.TESTING: {
        return <TestText vacancyID={vacancyID} />;
      }
      case PageStateEnum.AVTESTING: {
        return <TestAudioVideo vacancyID={vacancyID} />;
      }
      case PageStateEnum.INTERVIEW: {
        return <InterviewRecord vacancyID={vacancyID} interview="online" />;
      }
      default: {
        return <StyledLoader />;
      }
    }
  }, [currentStage]);

  return (
    <>
      <Styled.Header>
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-3 d-flex align-items-sm-center justify-content-sm-center justify-content-lg-start">
              <NavLink className="logo" to="/" target="_blank">
                {logoIcon}
              </NavLink>
            </div>
            <Styled.Column className="col-lg-9 d-none d-sm-flex justify-content-center justify-content-lg-end">
              {stages[0].code !== '' && (
                <StyledProgress
                  stages={stages}
                  active_stage={currentStage.group}
                />
              )}
            </Styled.Column>
          </div>
        </div>
      </Styled.Header>
      <main className="main">
        <div className="container">{viewPage}</div>
      </main>
    </>
  );
};

export default Passing;
