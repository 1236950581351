export const url = `${process.env.REACT_APP_API_URL}api/`;

export const config = {
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers,Access-Control-Allow-Origin,Access-Control-Allow-Methods,Origin,Content-Type,Authorization,withCredentials',
  },
};
