import React from 'react';
import styled from 'styled-components';

const StyledTimeComponent = styled.div`
  width: 75px;
  color: var(--main-accent-color);

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
  }

  .time-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: ':';
      position: absolute;
      left: calc(50% - 2px);
      top: 2px;
    }

    @media (min-width: 768px) {
      letter-spacing: 2.37px;
      width: 120px;

      &::before {
        top: 7px;
      }
    }

    // @media (min-width: 1200px) {
    // }
  }

  .time-number {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 45px;
    }
  }

  .time-desc {
    display: block;
    font-size: 5px;
    line-height: 10px;
    text-transform: uppercase;
    color: var(--main-text-color);

    @media (min-width: 768px) {
      font-size: 8px;
    }
  }

  &.test {
    color: var(--main-accent-color);
  }

  &.finish {
    color: var(--error-text-color);
  }
`;

const icon = (
  <svg
    width="37"
    height="37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    viewBox="17.06 17.06 39.89 39.89"
  >
    <circle cx="37" cy="37" r="19.444"></circle>
    <path d="M31.444 29h11m-11 16h11m-1.428-1.111v-3.196c0-.333-.15-.649-.407-.86l-7.329-6a1.111 1.111 0 0 1-.407-.86v-2.862c0-.614.497-1.111 1.111-1.111h5.92c.614 0 1.112.497 1.112 1.111v2.863c0 .333-.15.648-.407.86l-7.329 6a1.111 1.111 0 0 0-.407.859v3.196c0 .613.497 1.111 1.111 1.111h5.92c.614 0 1.112-.498 1.112-1.111Z"></path>{' '}
  </svg>
);

interface PropsInterface {
  time: number;
  additionalClass?: string;
}

const StyledTimeSquare: React.FC<PropsInterface> = ({
  time,
  additionalClass,
}) => {
  const min = `${String(Math.floor(time / 60)).padStart(2, '0')}`;

  const second = `${String(time % 60).padStart(2, '0')}`;

  return (
    <StyledTimeComponent className={additionalClass}>
      <div className="time-wrap">
        <div>
          <p className="time-number">{min}</p>
          <span className="time-desc">минуты</span>
        </div>
        <div>
          <p className="time-number">{second}</p>
          <span className="time-desc">секунды</span>
        </div>
      </div>
    </StyledTimeComponent>
  );
};

export default StyledTimeSquare;
