import React from 'react';
import styled from 'styled-components';

const StyledTextareaComponent = styled.textarea`
  width: 100%;
  display: block;
  border: 1px solid var(--ghost-text-color);
  border-radius: 3px;
  padding: 13px 17px;
  font: inherit;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  color: var(--main-text-color);
  resize: none;
  min-height: 96px;
  background-color: transparent;
  margin-bottom: 16px;
  transition: border 0.5s;

  &::placeholder {
    color: var(--ghost-text-color);
  }

  &:hover,
  &:focus {
    border-color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

interface PropsInterface {
  name?: string;
  id?: string;
  placeholder?: string;
  handleСhange?: (val: any) => void;
  value?: string | number;
}

const StyledTextarea: React.FC<PropsInterface> = ({
  name,
  id,
  placeholder,
  children,
  handleСhange,
  value,
}) => {
  return (
    <>
      <label className="visually-hidden" htmlFor={id}>
        {children}
      </label>
      <StyledTextareaComponent
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={handleСhange}
        value={value}
      ></StyledTextareaComponent>
    </>
  );
};

export default StyledTextarea;
