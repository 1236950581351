import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setAuthorization } from '../../store/actions/test';
import StyledLink from '../../components/StyledComponents/StyledLink';
import * as Styled from './StyledLogin';

interface LoginInterface {}

const Login: React.FunctionComponent<LoginInterface> = () => {
  const dispatch = useDispatch();

  const handleSubmitForm = (values: any) => {
    dispatch(setAuthorization(values));
  };

  return (
    <Styled.Container className="container">
      <Formik
        validationSchema={Yup.object().shape({
          password: Yup.string().required('Вы не заполнили поле'),
          login: Yup.string().required('Вы не заполнили поле'),
        })}
        enableReinitialize
        onSubmit={handleSubmitForm}
        initialValues={{
          login: '',
          password: '',
        }}
      >
        {({ errors, touched }) => (
          <Styled.FormContainer>
            <Styled.Title>Войти</Styled.Title>
            <div>
              <Styled.Label htmlFor="authorize-tel">Логин</Styled.Label>
              <Styled.Input
                id="authorize-tel"
                type="text"
                name="login"
                placeholder="Логин"
              />
            </div>
            <div>
              <Styled.Label htmlFor="authorize-password">Пароль</Styled.Label>
              <Styled.Input
                id="authorize-password"
                type="password"
                name="password"
                placeholder="**********"
              />
            </div>
            <StyledLink text="Войти" button type="submit" />
          </Styled.FormContainer>
        )}
      </Formik>
    </Styled.Container>
  );
};
export default Login;
