export interface UserProfileI {
  name: string;
  second_name: string;
}

export enum AuthActionTypes {
  FETCH_USER_PROFILE = 'FETCH_USER_PROFILE',
  SET_AUTHORIZATION_TOKEN = 'SET_AUTHORIZATION_TOKEN',
  SET_IS_AUTHORIZATION = 'SET_IS_AUTHORIZATION',
  ERROR_AUTHORIZATION = 'ERROR_AUTHORIZATION',
}

interface FetchUserProfileAction {
  type: AuthActionTypes.FETCH_USER_PROFILE;
  payload: UserProfileI;
}

interface SetNotAuthorizationAction {
  type: AuthActionTypes.SET_IS_AUTHORIZATION;
  payload: boolean;
}
interface SetAuthorizationTokenAction {
  type: AuthActionTypes.SET_AUTHORIZATION_TOKEN;
  payload: string;
}
interface SetAuthorizationErrorAction {
  type: AuthActionTypes.ERROR_AUTHORIZATION;
  payload: {
    type: string;
    message: string;
  };
}

export interface IAuthorization {
  password: string;
  login: string;
}

export type AuthActions =
  | FetchUserProfileAction
  | SetNotAuthorizationAction
  | SetAuthorizationTokenAction
  | SetAuthorizationErrorAction;
