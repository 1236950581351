import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment, { Moment } from 'moment';
import 'moment-duration-format';

const StyledWrapper = styled.div`
  svg path:nth-child(2) {
    filter: drop-shadow(0 0 2px var(--main-accent-color))
      drop-shadow(0 0 5px var(--main-accent-color))
      drop-shadow(0 0 10px var(--main-accent-color));
  }
`;

const StyledTimeComponent = styled.div`
  border-radius: 50%;
  width: 158px;
  height: 158px;
  padding: 45px 25px;

  &.finish {
    border: 3px solid var(--error-text-color);
    box-shadow: 0px 0px 10px 3px var(--error-text-color);
  }

  .time-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: ':';
      position: absolute;
      left: 50%;
      top: 7px;
      var(--main-accent-color);
    }
  }

  .time-number {
    letter-spacing: 1.5px;
    margin: 0;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    color: var(--main-accent-color)
  }

  .time-desc {
    display: block;
    font-size: 8px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;  
  }

  &.test .time-number,
  &.test .time-wrap::before {
    color: var(--main-accent-color);
  }

  &.finish .time-number,
  &.finish .time-wrap::before {
    color: var(--error-text-color);
  }
`;

interface PropsInterface {
  time: number;
  additionalClass?: string;
  taskTime: number;
  stopTime: Moment;
}

const StyledTimer: React.FC<PropsInterface> = ({
  time,
  additionalClass,
  taskTime,
  stopTime,
}) => {
  const formatTime = moment.duration(time, 'seconds').format('hh:mm:ss');

  const currentTime = moment();

  const getTime = (formattedTime: string) => {
    if (formattedTime.length === 8) {
      return (
        <div className="time-wrap">
          <div>
            <p className="time-number">{formattedTime.slice(0, 2)}</p>
            <span className="time-desc">часы</span>
          </div>
          <div>
            <p className="time-number">{formattedTime.slice(3, 5)}</p>
            <span className="time-desc">минуты</span>
          </div>
        </div>
      );
    }
    if (formattedTime.length === 5) {
      return (
        <div className="time-wrap">
          <div>
            <p className="time-number">{formattedTime.slice(0, 2)}</p>
            <span className="time-desc">минуты</span>
          </div>
          <div>
            <p className="time-number">{formattedTime.slice(3, 5)}</p>
            <span className="time-desc">секунды</span>
          </div>
        </div>
      );
    }
    if (formattedTime.length === 2) {
      return (
        <div className="time-wrap">
          <div>
            <p className="time-number">00</p>
            <span className="time-desc">минуты</span>
          </div>
          <div>
            <p className="time-number">{formattedTime.slice(0, 2)}</p>
            <span className="time-desc">секунды</span>
          </div>
        </div>
      );
    }
    return undefined;
  };

  return (
    <StyledWrapper>
      <CountdownCircleTimer
        isPlaying
        duration={taskTime}
        updateInterval={0}
        initialRemainingTime={stopTime.diff(currentTime, 'seconds')}
        trailColor="rgba(0, 229, 210, 0.3)"
        colors="#00E5D2"
        rotation="counterclockwise"
        strokeWidth={3}
        size={158}
        onComplete={() => {
          return { shouldRepeat: false, delay: 0, newInitialRemainingTime: 0 };
        }}
      >
        {() => {
          return (
            <StyledTimeComponent className={additionalClass}>
              {getTime(formatTime)}
            </StyledTimeComponent>
          );
        }}
      </CountdownCircleTimer>
    </StyledWrapper>
  );
};

export default StyledTimer;
