import React from 'react';
import styled from 'styled-components';

const StyledTitleComponent = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;

  span {
    color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    font-size: 35px;
    line-height: 52px;
    margin-bottom: 30px;
  }

  @media (min-width: 1200px) {
    br {
      display: none;
    }
  }
`;

const StyledTitle: React.FC = ({ children }) => {
  return <StyledTitleComponent>{children}</StyledTitleComponent>;
};

export default StyledTitle;
