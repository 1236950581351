import React from 'react';
import StyledLink from '../../StyledComponents/StyledLink';
import { arrowDownIcon } from './icons';
import * as Styled from './StyledTestDescription';

interface TestDescriptionProps {
  title: string;
  testName: string;
  testId?: number | string;
  startTask: (id?: number | string) => void;
}

const TestDescription: React.FunctionComponent<TestDescriptionProps> = ({
  title,
  testName,
  testId,
  startTask,
  children,
}) => {
  const handleStartTask = () => {
    if (testId) {
      startTask(testId);
    } else {
      startTask();
    }
  };

  return (
    <>
      <Styled.TitleWrap>
        <h1 className="title">{title}</h1>
      </Styled.TitleWrap>
      <Styled.Wrapper>
        <Styled.TestItemBtn type="button">
          {testName}
          {arrowDownIcon}
        </Styled.TestItemBtn>
        <Styled.StartTest>
          <Styled.Title>{testName}</Styled.Title>
          {children}
          <Styled.BtnWrapper>
            <StyledLink
              button
              onClick={handleStartTask}
              text="Начать тестирование"
            ></StyledLink>
          </Styled.BtnWrapper>
        </Styled.StartTest>
      </Styled.Wrapper>
    </>
  );
};

export default TestDescription;
