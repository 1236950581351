import React from 'react';
import * as Styled from './StyledTestContent';

interface TestContentProps {}

const TestContent: React.FunctionComponent<TestContentProps> = ({
  children,
}) => {
  return <Styled.TestContent>{children}</Styled.TestContent>;
};

export default TestContent;
