import React from 'react';

export const telIcon = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M54.5965 47.2371L51.8167 44.4507C51.263 43.898 50.346 43.9148 49.7731 44.4893L48.3726 45.8926C48.2841 45.8437 48.1925 45.7927 48.0962 45.7385C47.2118 45.2474 46.0014 44.5742 44.7277 43.2967C43.4502 42.0164 42.7779 40.8014 42.2864 39.9144C42.2345 39.8204 42.1848 39.7298 42.1357 39.6438L43.0756 38.7031L43.5378 38.2394C44.1116 37.6641 44.1274 36.7454 43.575 36.191L40.7952 33.4043C40.2428 32.8508 39.3255 32.8675 38.7516 33.4429L37.9682 34.2326L37.9896 34.2539C37.7269 34.5899 37.5074 34.9773 37.344 35.3952C37.1934 35.7929 37.0997 36.1725 37.0568 36.5528C36.6897 39.6027 38.0803 42.39 41.8541 46.1724C47.0706 51.4004 51.2744 51.0055 51.4558 50.9862C51.8508 50.9389 52.2293 50.8443 52.614 50.6946C53.0273 50.5327 53.4136 50.313 53.7486 50.0503L53.7658 50.0655L54.5594 49.2866C55.1321 48.7114 55.1486 47.7923 54.5965 47.2371Z"
        fill="currentColor"
      />
      <circle cx="46" cy="42" r="24.5" stroke="currentColor" />
      <path
        d="M54.5965 47.2371L51.8167 44.4507C51.263 43.898 50.346 43.9148 49.7731 44.4893L48.3726 45.8926C48.2841 45.8437 48.1925 45.7927 48.0962 45.7385C47.2118 45.2474 46.0014 44.5742 44.7277 43.2967C43.4502 42.0164 42.7779 40.8014 42.2864 39.9144C42.2345 39.8204 42.1848 39.7298 42.1357 39.6438L43.0756 38.7031L43.5378 38.2394C44.1116 37.6641 44.1274 36.7454 43.575 36.191L40.7952 33.4043C40.2428 32.8508 39.3255 32.8675 38.7516 33.4429L37.9682 34.2326L37.9896 34.2539C37.7269 34.5899 37.5074 34.9773 37.344 35.3952C37.1934 35.7929 37.0997 36.1725 37.0568 36.5528C36.6897 39.6027 38.0803 42.39 41.8541 46.1724C47.0706 51.4004 51.2744 51.0055 51.4558 50.9862C51.8508 50.9389 52.2293 50.8443 52.614 50.6946C53.0273 50.5327 53.4136 50.313 53.7486 50.0503L53.7658 50.0655L54.5594 49.2866C55.1321 48.7114 55.1486 47.7923 54.5965 47.2371Z"
        fill="currentColor"
      />
      <circle cx="46" cy="42" r="24.5" stroke="currentColor" />
    </g>
  </svg>
);

export const mailIcon = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="46" cy="42" r="24.5" stroke="currentColor" />
      <circle cx="46" cy="42" r="24.5" stroke="currentColor" />
      <path
        d="M40.3437 39.3906C40.5208 39.5156 41.0547 39.8868 41.9453 40.5039C42.8359 41.1211 43.5182 41.5963 43.9921 41.9296C44.0442 41.9661 44.1549 42.0455 44.3241 42.1679C44.4934 42.2904 44.634 42.3894 44.7459 42.4649C44.8579 42.5403 44.9933 42.625 45.1523 42.7187C45.3111 42.8124 45.4609 42.8828 45.6015 42.9294C45.7422 42.9764 45.8724 42.9997 45.9921 42.9997H46.0079C46.1276 42.9997 46.2579 42.9764 46.3985 42.9294C46.5391 42.8828 46.689 42.8123 46.8477 42.7187C47.0066 42.6249 47.142 42.5403 47.254 42.4649C47.366 42.3894 47.5065 42.2904 47.6758 42.1679C47.845 42.0454 47.9558 41.9661 48.0079 41.9296C48.487 41.5963 49.7058 40.7499 51.6641 39.3905C52.0443 39.125 52.3619 38.8046 52.6171 38.4296C52.8724 38.0547 53 37.6615 53 37.25C53 36.9062 52.8762 36.6119 52.6288 36.3672C52.3814 36.1223 52.0884 36 51.7499 36H40.2499C39.8489 36 39.5403 36.1354 39.3242 36.4062C39.1081 36.677 39 37.0156 39 37.4218C39 37.7499 39.1433 38.1055 39.4297 38.4883C39.7161 38.8711 40.0209 39.1719 40.3437 39.3906Z"
        fill="currentColor"
      />
      <path
        d="M52.2186 40.2265C50.5105 41.3826 49.2134 42.2812 48.3281 42.9219C48.0313 43.1405 47.7904 43.3112 47.6055 43.4335C47.4205 43.5559 47.1746 43.6809 46.8672 43.8085C46.56 43.9362 46.2737 43.9999 46.008 43.9999H45.9922C45.7265 43.9999 45.44 43.9362 45.1328 43.8085C44.8256 43.6809 44.5794 43.5559 44.3945 43.4335C44.2097 43.3112 43.9688 43.1405 43.6719 42.9219C42.9688 42.4063 41.6745 41.5077 39.7891 40.2265C39.4922 40.0287 39.2292 39.802 39 39.5468V45.7498C39 46.0938 39.1223 46.3879 39.3672 46.6327C39.6119 46.8776 39.9063 47 40.25 47H51.7501C52.0937 47 52.388 46.8776 52.6328 46.6327C52.8777 46.3878 53 46.0938 53 45.7498V39.5468C52.776 39.7967 52.5157 40.0234 52.2186 40.2265Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const dateIcon = (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M27.712 27.4822C27.712 27.2442 27.519 27.0514 27.2808 27.0514H25.7758C25.5379 27.0514 25.3448 27.2442 25.3448 27.4822V28.9869C25.3448 29.2253 25.5379 29.4182 25.7758 29.4182H27.2808C27.519 29.4182 27.712 29.2253 27.712 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M31.4733 27.4822C31.4733 27.2442 31.2802 27.0514 31.0425 27.0514H29.5373C29.2994 27.0514 29.1063 27.2442 29.1063 27.4822V28.9869C29.1063 29.2253 29.2994 29.4182 29.5373 29.4182H31.0425C31.2802 29.4182 31.4733 29.2253 31.4733 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M35.2344 27.4822C35.2344 27.2442 35.0413 27.0514 34.8034 27.0514H33.2984C33.0602 27.0514 32.8672 27.2442 32.8672 27.4822V28.9869C32.8672 29.2253 33.0602 29.4182 33.2984 29.4182H34.8034C35.0413 29.4182 35.2344 29.2253 35.2344 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M27.712 31.2436C27.712 31.0052 27.519 30.8126 27.2808 30.8126H25.7758C25.5379 30.8126 25.3448 31.0052 25.3448 31.2436V32.7481C25.3448 32.9862 25.5379 33.1791 25.7758 33.1791H27.2808C27.519 33.1791 27.712 32.9862 27.712 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M31.4733 31.2436C31.4733 31.0052 31.2802 30.8126 31.0425 30.8126H29.5373C29.2994 30.8126 29.1063 31.0052 29.1063 31.2436V32.7481C29.1063 32.9862 29.2994 33.1791 29.5373 33.1791H31.0425C31.2802 33.1791 31.4733 32.9862 31.4733 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M35.2344 31.2436C35.2344 31.0052 35.0413 30.8126 34.8036 30.8126H33.2984C33.0602 30.8126 32.8672 31.0052 32.8672 31.2436V32.7481C32.8672 32.9862 33.0602 33.1791 33.2984 33.1791H34.8036C35.0413 33.1791 35.2344 32.9862 35.2344 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M37.9251 19.1156V21.4139C37.9251 22.4528 37.0823 23.2899 36.0437 23.2899H34.8568C33.818 23.2899 32.9641 22.4528 32.9641 21.4139V19.1073H27.6147V21.4139C27.6147 22.4528 26.7608 23.2899 25.7222 23.2899H24.5351C23.4964 23.2899 22.6537 22.4528 22.6537 21.4139V19.1156C21.7459 19.1429 21 19.8941 21 20.8174V34.2869C21 35.2275 21.7624 36 22.703 36H37.8757C38.815 36 39.5788 35.2259 39.5788 34.2869V20.8174C39.5788 19.8941 38.8329 19.1429 37.9251 19.1156ZM37.3738 33.4481C37.3738 33.8546 37.0442 34.1844 36.6376 34.1844H23.9088C23.5021 34.1844 23.1725 33.8546 23.1725 33.4481V26.4906C23.1725 26.084 23.5021 25.7542 23.9088 25.7542H36.6375C37.0442 25.7542 37.3738 26.084 37.3738 26.4906L37.3738 33.4481Z"
        fill="currentColor"
      />
      <path
        d="M24.531 22.0578H25.705C26.0613 22.0578 26.3502 21.7693 26.3502 21.4129V17.6451C26.3502 17.2887 26.0613 17 25.705 17H24.531C24.1746 17 23.8858 17.2887 23.8858 17.6451V21.4129C23.8858 21.7693 24.1746 22.0578 24.531 22.0578Z"
        fill="currentColor"
      />
      <path
        d="M34.8416 22.0578H36.0156C36.3717 22.0578 36.6606 21.7693 36.6606 21.4129V17.6451C36.6607 17.2887 36.3717 17 36.0156 17H34.8416C34.4853 17 34.1964 17.2887 34.1964 17.6451V21.4129C34.1964 21.7693 34.4853 22.0578 34.8416 22.0578Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const timeIcon = (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M30.4998 17C25.2533 17 21 21.2534 21 26.4998C21 31.7463 25.2533 36 30.4998 36C35.7464 36 40 31.7463 40 26.4998C40 21.2534 35.7464 17 30.4998 17ZM34.6069 28.3067H30.5793C30.5657 28.3067 30.553 28.3034 30.5396 28.3029C30.5261 28.3035 30.5135 28.3067 30.4998 28.3067C30.1369 28.3067 29.8427 28.0125 29.8427 27.6497V20.9425C29.8427 20.5797 30.1369 20.2855 30.4998 20.2855C30.8626 20.2855 31.1568 20.5797 31.1568 20.9425V26.9926H34.6066C34.9695 26.9926 35.2637 27.2868 35.2637 27.6497C35.2637 28.0125 34.9697 28.3067 34.6069 28.3067Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const geoIcon = (
  <svg
    width="58"
    height="64"
    viewBox="0 0 58 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M29.1368 17.0046C24.669 16.854 21 20.4309 21 24.8646C21 29.8964 25.8288 33.5482 28.5353 38.7998C28.6728 39.0665 29.0566 39.0668 29.1945 38.8002C31.6429 34.0748 35.8277 30.9061 36.6032 26.3537C37.4062 21.6428 33.9128 17.1656 29.1368 17.0046ZM28.8645 28.9841C26.5894 28.9841 24.745 27.1397 24.745 24.8646C24.745 22.5896 26.5895 20.7451 28.8645 20.7451C31.1396 20.7451 32.9841 22.5896 32.9841 24.8646C32.9841 27.1397 31.1396 28.9841 28.8645 28.9841Z"
        fill="currentColor"
      />
      <path
        d="M29.1368 17.0046C24.669 16.854 21 20.4309 21 24.8646C21 29.8964 25.8288 33.5482 28.5353 38.7998C28.6728 39.0665 29.0566 39.0668 29.1945 38.8002C31.6429 34.0748 35.8277 30.9061 36.6032 26.3537C37.4062 21.6428 33.9128 17.1656 29.1368 17.0046ZM28.8645 28.9841C26.5894 28.9841 24.745 27.1397 24.745 24.8646C24.745 22.5896 26.5895 20.7451 28.8645 20.7451C31.1396 20.7451 32.9841 22.5896 32.9841 24.8646C32.9841 27.1397 31.1396 28.9841 28.8645 28.9841Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
