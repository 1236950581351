import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-row-gap: 10px;
  grid-auto-rows: min-content;
`;

export const TextareaWrap = styled.div`
  max-width: 515px;
  textarea {
    height: 202px;
  }
`;
