import React from 'react';
import StyledCheckboxInput from '../../StyledComponents/StyledCheckboxInput';
import StyledRadioInput from '../../StyledComponents/StyledRadioInput';
import StyledTextarea from '../../StyledComponents/StyledTextarea';
import { QuestionSINGLEMULTIPLEAnswer } from '../../../types/test';
import * as Styled from './StyledTestTextAnswer';

interface TextTestAnswerProps {
  answers: QuestionSINGLEMULTIPLEAnswer[];
  text?: boolean;
  multiType?: boolean;
  checked: string | number | undefined;
  setChecked: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  checkedM: string[];
  setCheckedM: React.Dispatch<React.SetStateAction<string[]>>;
}

const TextTestAnswer: React.FunctionComponent<TextTestAnswerProps> = ({
  answers,
  text,
  multiType,
  checked,
  setChecked,
  checkedM,
  setCheckedM,
}) => {
  const handleSetChecked = (value: string): void => {
    setCheckedM(prev => {
      if (prev.length !== 0 && prev.find(n => n === value)) {
        return prev.filter(f => f !== value);
      }
      return [...prev, value];
    });
  };

  return (
    <>
      <Styled.FieldsWrapper>
        {!multiType &&
          answers.map((answer: QuestionSINGLEMULTIPLEAnswer, i: number) => (
            <StyledRadioInput
              name="question1"
              id={`testAnswer${i}`}
              value={`testAnswer${i}`}
              checked={answer.checked}
              handleСhange={e => setChecked(answer.id)}
              key={answer.id}
            >
              {answer.answer.replace(/&quot;/g, '"')}
            </StyledRadioInput>
          ))}
        {multiType &&
          answers.map((answer: QuestionSINGLEMULTIPLEAnswer, i: number) => (
            <StyledCheckboxInput
              name="question1"
              id={`testAnswer${i}`}
              value={answer.id}
              checked={answer.checked}
              handleСhange={e => handleSetChecked(e.target.value)}
              key={answer.id}
            >
              {answer.answer.replace(/&quot;/g, '"')}
            </StyledCheckboxInput>
          ))}
      </Styled.FieldsWrapper>
      <Styled.TextareaWrap>
        {text && (
          <StyledTextarea
            handleСhange={e => setChecked(e.target.value)}
            name=""
            id=""
            value={checked}
          ></StyledTextarea>
        )}
      </Styled.TextareaWrap>
    </>
  );
};

export default TextTestAnswer;
