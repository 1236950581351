import moment from 'moment';
import React, { useEffect, useState } from 'react';

import StyledTime from '../StyledComponents/StyledTime';
import StyledTimer from '../StyledComponents/StyledTimer';

import StyledTimeSquare from '../StyledComponents/StyledTimeSquare';

interface PropsTime {
  square?: boolean;
  finishTime: number;
  finishHandler: React.Dispatch<React.SetStateAction<boolean>>;
  taskTime?: number;
}

const Timer: React.FC<PropsTime> = ({
  finishTime,
  finishHandler,
  square,
  taskTime,
}) => {
  const [finish, setFinish] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const currentTime = moment();
    const stopTime = moment(finishTime);
    const timerLimit = stopTime.diff(currentTime, 'seconds');

    setTime(timerLimit);
    setFinish(false);
  }, [finishTime]);

  useEffect(() => {
    const timerFunc = setInterval(() => {
      setTime((prev: number) => {
        if (prev > 0) {
          return prev - 1;
        }
        if (prev === 0) {
          setFinish(true);
        }
        return 0;
      });
    }, 1000);

    return () => clearInterval(timerFunc);
  }, [time]);

  useEffect(() => {
    if (finish) {
      finishHandler(true);
    } else {
      finishHandler(false);
    }
  }, [finish]);

  return square ? (
    <StyledTimeSquare
      time={time}
      additionalClass={finish ? 'finish' : 'test'}
    />
  ) : (
    <StyledTimer
      time={time}
      additionalClass={finish ? 'finish' : 'test'}
      taskTime={taskTime !== undefined ? taskTime : 0}
      stopTime={moment(finishTime)}
    />
  );
};

export default React.memo(Timer);
