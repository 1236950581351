import styled from 'styled-components';

export const VideoWrapper = styled.div`
  position: relative;

  .video-container {
    position: relative;
    width: 240px;
    height: 140px;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 10px;
    overflow: hidden;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    border-radius: 15px;
  }

  .poster {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    object-fit: contain;
    z-index: 1;
    border-radius: 15px;
  }

  .shadow-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    opacity: 0.5;
    margin: 0;
  }

  .play-btn {
    padding: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-accent-color);
    border: none;
    width: 26px;
    height: 26px;
    left: auto;
    right: 20px;
    top: 20px;
    transform: translate(0);
    border-radius: 50%;
    box-shadow: 0px 0px 5px var(--main-accent-color-hover);
    transition: background-color 0.3s;
    z-index: 3;

    &:hover {
      background-color: var(--main-accent-color-hover);
    }

    &:focus-visible {
      background-color: var(--main-accent-color-hover);
      outline: 2px solid var(--main-accent-color);
    }
  }

  .play-btn-start {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 400px) {
    .video-container {
      width: 100%;
      height: 200px;
    }

    .video-container > div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media (min-width: 768px) {
    .video-container {
      width: 100%;
      height: 380px;
    }

    video {
      margin-bottom: 0;
      object-fit: cover;
    }

    .poster {
      object-fit: cover;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
    }

    .shadow-text {
      position: absolute;
      top: 20px;
      left: 10px;
      z-index: 2;
    }

    .play-btn {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (min-width: 1200px) {
    .play-btn {
      width: 60px;
      height: 60px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
