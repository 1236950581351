import React from 'react';
import useTypedSelector from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import StyledLink from '../StyledComponents/StyledLink';
import StageList from '../StageList/StageList';
import PresentationVacancy from './PresentationVacancy/PresentationVacancy';
import PresentationText from './PresentationText/PresentationText';
import PresentationVideo from './PresentationVideo/PresentationVideo';
import * as Styled from './StyledPresentation';
import PresentationInfo from './PresentationInfo/PresentationInfo';

interface PresentationInterface {
  vacancyID: string | null;
}

const Presentation: React.FC<PresentationInterface> = () => {
  const { vacancy, company, stages } = useTypedSelector(r => r.automatic);
  const { profile } = useTypedSelector(r => r.auth);
  const { setNextStage } = useActions();

  const handleLinkClick = (): void => {
    setNextStage(vacancy.id);
  };

  return (
    <>
      <Styled.Wrapper className="row align-items-stretch">
        <div className="col-lg-8 order-lg-1 mb-3 mb-lg-0">
          <Styled.TextBlock>
            <div className="wrapper">
              <PresentationText
                name={profile.name}
                lastName={profile.last_name}
                active
              />
              {company.video && <PresentationVideo src={company.video} />}
            </div>
          </Styled.TextBlock>
        </div>
        <div className="col-lg-4 order-lg-0 position-static">
          <PresentationVacancy vacancy={vacancy} />
          <div className="d-none d-lg-block mt-4">
            <PresentationInfo company={company} vacancy={vacancy} />
          </div>
        </div>
      </Styled.Wrapper>
      <div className="mb-4">
        <StageList stages={stages} />
      </div>
      <Styled.NextLinkWrapper>
        <StyledLink
          text="Пойдем дальше"
          button
          onClick={() => handleLinkClick()}
        ></StyledLink>
      </Styled.NextLinkWrapper>
      <div className="d-lg-none">
        <PresentationInfo company={company} vacancy={vacancy} />
      </div>
    </>
  );
};

export default Presentation;
