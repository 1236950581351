import React from 'react';

export const icon1 = (
  <svg
    width="91"
    height="91"
    viewBox="0 0 91 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="45.5"
      cy="41.5"
      r="23.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M40.6001 34.7625C41.8251 32.925 45.5001 30.965 47.9501 32.925C50.4001 34.885 50.1959 37.0083 49.7876 37.8249C49.5834 38.8458 48.1951 41.1324 44.2751 42.1124V45.7874"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="44.5" cy="51.5" r="1.5" fill="currentColor" />
  </svg>
);

export const icon2 = (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="46" cy="42" r="24" stroke="currentColor" strokeWidth="2" />
    <path
      d="M36.625 42.625L43.5 49.5L54.75 34.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const icon3 = (
  <svg
    width="91"
    height="91"
    viewBox="0 0 91 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="45.5"
      cy="41.5"
      r="23.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M40.6001 36.6H56.5251M40.6001 42.725H56.5251M40.6001 48.85H56.5251"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <ellipse cx="35.7001" cy="36.6" rx="1.225" ry="1.225" fill="currentColor" />
    <ellipse
      cx="35.7001"
      cy="42.725"
      rx="1.225"
      ry="1.225"
      fill="currentColor"
    />
    <ellipse
      cx="35.7001"
      cy="48.85"
      rx="1.225"
      ry="1.225"
      fill="currentColor"
    />
  </svg>
);

export const icon4 = (
  <svg
    width="91"
    height="91"
    viewBox="0 0 91 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="45.5"
      cy="41.5"
      r="23.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M38.6943 31.7H52.1693M38.6943 51.3H52.1693M50.4193 49.3V46.3265C50.4193 45.7269 50.1503 45.1589 49.6863 44.779L41.1774 37.8127C40.7134 37.4328 40.4443 36.8648 40.4443 36.2651V33.7C40.4443 32.5954 41.3398 31.7 42.4443 31.7H48.4193C49.5239 31.7 50.4193 32.5954 50.4193 33.7V36.2651C50.4193 36.8648 50.1503 37.4328 49.6863 37.8127L41.1774 44.779C40.7134 45.1589 40.4443 45.7269 40.4443 46.3265V49.3C40.4443 50.4046 41.3398 51.3 42.4443 51.3H48.4193C49.5239 51.3 50.4193 50.4046 50.4193 49.3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const icon5 = (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="100" cy="96" r="78" stroke="currentColor" strokeWidth="2" />
    <path
      d="M103.64 109.163H96.5888L95.2208 58.1233H105.008L103.64 109.163ZM100.062 132.42C98.5182 132.42 97.2202 131.893 96.1679 130.841C95.1155 129.789 94.5894 128.491 94.5894 126.947C94.5894 125.404 95.1155 124.106 96.1679 123.054C97.2202 122.001 98.5182 121.475 100.062 121.475C101.605 121.475 102.903 122.001 103.955 123.054C105.008 124.106 105.534 125.404 105.534 126.947C105.534 128.491 105.008 129.789 103.955 130.841C102.903 131.893 101.605 132.42 100.062 132.42Z"
      fill="currentColor"
    />
  </svg>
);
