export interface VacancyI {
  id: number;
  name: string;
  company_id: number;
  status: string;
  type_location: number;
  type_frequency: number;
  type_schedule: number;
  salary_from: number;
  salary_to: number;
  currency_code: string;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
  url: string;
}

export enum VacancyActionTypes {
  FETCH_VACANCY = 'FETCH_VACANCY',
  FETCH_STAGE_VACANCY = 'FETCH_STAGE_VACANCY',
  FETCH_DICTS = 'FETCH_DICTS',
}

interface FetchVacancyAction {
  type: VacancyActionTypes.FETCH_VACANCY;
  payload: VacancyI;
}

interface FetchStageVacancyAction {
  type: VacancyActionTypes.FETCH_STAGE_VACANCY;
  payload: string[];
}

export type VacancyActions = FetchVacancyAction | FetchStageVacancyAction;
