import styled from 'styled-components';
import dateIcon from '../../../assets/img/date-icon.svg';
import timeIcon from '../../../assets/img/time-icon.svg';
import geoIcon from '../../../assets/img/geo-icon.svg';
import telIcon from '../../../assets/img/tel-icon.svg';
import mailIcon from '../../../assets/img/mail-icon.svg';
import placemarkIcon from '../../../assets/img/placemark-bg.png';

export const Block = styled.section<{
  $offlineFlag: boolean | undefined;
}>`
  background-color: var(--main-block-bg);
  border-radius: 5.81152px;
  padding: ${props =>
    props.$offlineFlag ? '27px 0 0 0' : '27px 15px 24px 15px'};

  @media (min-width: 768px) {
    padding: ${props =>
      props.$offlineFlag ? '27px 0 0 0' : '27px 30px 24px 30px'};
  }

  @media (min-width: 992px) {
    display: grid;
    gap: 30px;
    grid-template-columns: ${props =>
      props.$offlineFlag ? '55% 45%' : '1fr 52%'};
    padding: ${props => (props.$offlineFlag ? '0' : '56px 60px 60px 60px')};
  }
`;

export const Wrapper = styled.div<{
  $offlineFlag: boolean | undefined;
}>`
  padding: ${props => (props.$offlineFlag ? '0 15px' : '0')};

  @media (min-width: 768px) {
    padding: ${props => (props.$offlineFlag ? '0 30px' : '0')};
  }

  @media (min-width: 992px) {
    padding: ${props => (props.$offlineFlag ? '56px 60px 60px 60px' : '0')};
  }
`;

export const UserName = styled.h3`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 8px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 50px;
  }
`;

export const InfoList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 34px;
  padding-left: 4px;

  @media (min-width: 768px) {
    padding-top: 10px;
    margin-bottom: 70px;
  }

  li {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 15px;
    justify-items: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.374px;
    text-transform: uppercase;

    &:last-of-type {
      margin-bottom: 0;
    }

    .text {
      margin: 0;
      position: relative;
      padding-left: 30px;
      height: 22px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        top: 0px;
        left: -3px;
        border-radius: 50%;
        box-shadow: 0px 0px 20px var(--main-accent-shadow);
      }
    }

    .text span {
      display: none;
    }

    .text svg {
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      top: -15px;
      left: -20px;
      color: var(--main-accent-color);
    }

    &:nth-of-type(3) .text svg {
      width: 50px;
      height: 57px;
    }

    .value {
      font-weight: 700;
      text-transform: none;
      color: var(--main-accent-color);
    }

    @media (min-width: 768px) {
      grid-template-columns: 250px 1fr;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 30px;
      gap: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .text {
        height: auto;
      }

      .text span {
        display: block;
      }

      .value {
        font-size: 15px;
        line-height: 22px;
        color: var(--main-text-color);
      }
    }
  }
`;

export const ContactLinks = styled.div<{
  $offlineFlag: boolean | undefined;
}>`
  margin-bottom: 35px;
  display: grid;
  grid-auto-rows: min-content;
  padding-left: 4px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 47px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    grid-template-columns: ${props =>
      props.$offlineFlag ? ' 1fr 1fr' : '1fr'};
  }
`;

export const ContactLink = styled.a`
  position: relative;
  font-size: 15px;
  line-height: 38px;
  letter-spacing: 0.6px;
  padding-left: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--main-text-color);

  svg {
    display: block;
    position: absolute;
    width: 90px;
    height: 90px;
    top: -10px;
    left: -25px;
    color: var(--main-accent-color);
  }

  &:hover,
  &:focus {
    color: var(--main-text-color);
    text-decoration: none;
  }

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 43px;
  }
`;

export const Map = styled.div`
  // * {
  //   color: #ffffff;
  //   white-space: nowrap;
  // }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
  > div {
    @media (min-width: 768px) {
      height: 400px !important;
    }

    @media (min-width: 992px) {
      height: 694px !important;
    }

    @media (min-width: 1200px) {
      height: 620px !important;
    }
  }
`;

export const AttentionBlock = styled.div`
  border: 1px solid var(--main-text-color);
  border-radius: 20px;
  padding: 22px 16px 30px 16px;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.374px;

  @media (min-width: 768px) {
    position: relative;
    font-size: 15px;
    line-height: 26px;
    padding: 33px;
  }

  @media (min-width: 768px) {
    align-self: flex-start;
  }

  .title {
    position: relative;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 35px;
    }
  }

  .text {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 28px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
  }
`;

export const LinkWrap = styled.div`
  max-width: 280px;
`;
