import styled from 'styled-components';

export const TitleWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .back-button {
    font: inherit;
    text-shadow: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: var(--main-text-color);
    outline: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      text-decoration: underline;
    }
  }
`;
