import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import building from '../../../assets/images/building.png';
import icon1 from '../../../assets/images/icon-1.svg';
import icon2 from '../../../assets/images/icon-2.svg';
import icon3 from '../../../assets/images/icon-3.svg';
import vk from '../../../assets/images/vk.svg';
import fb from '../../../assets/images/fb.svg';
import ok from '../../../assets/images/ok.svg';
import twitter from '../../../assets/images/twitter.svg';
import Vacancies from '../Vacancies/Vacancies';
import VacancyResponseSent from './VacancyResponseSent';
import VacancyResponse from './VacancyResponse';
import { config, url } from '../../../store/actions/actionsAssets';
import { CompanyI } from '../MainPage/MainPage';

interface Params {
  id: string;
}

interface Vacancy {
  id: number;
  status: 'Y' | 'N';
  name: string;
  url: string;
  currency_code: string;
  salary_from: number;
  salary_to: number;
  type_location: string;
  type_schedule: string;
  type_frequency: string;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
}

const SingleVacancy: React.FC<{ company: CompanyI }> = ({ company }) => {
  const [show, setShow] = useState(false);
  const params: Params = useParams();
  const [vacancy, setVacancy] = useState<Vacancy>({
    currency_code: '',
    id: Number(params.id),
    name: 'Название вакансии',
    salary_from: 0,
    salary_to: 0,
    status: 'Y',
    text_conditions: '',
    text_requirements: '',
    text_responsibilities: '',
    type_frequency: '',
    type_location: '',
    type_schedule: '',
    url: '',
  });
  const [responseSent, setResponseSent] = useState(false);

  useEffect(() => {
    axios.get(`${url}vacancy/${params.id}/`, config).then(r => {
      setVacancy(r.data);
    });
  }, [params.id]);
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className={responseSent ? 'modal-private modal-ty' : 'modal-private'}
        aria-labelledby="example-custom-modal-styling-title"
      >
        {!responseSent ? (
          <VacancyResponse
            setShow={setShow}
            setResponseSent={setResponseSent}
            vacancyId={Number(params.id)}
          />
        ) : (
          <VacancyResponseSent setShow={setShow} />
        )}
      </Modal>
      <section className="main main-vacancy">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="main-block">
                <div className="main-title">{company.name}</div>
                <div className="main-vacancy__desc">{vacancy.name}</div>
                <button
                  className="dflt-bttn"
                  onClick={() => setShow(true)}
                  type="button"
                >
                  Откликнуться на вакансию
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="main-pict">
                <img src={building} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vacancy-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="vacancy-details__block">
                <div className="vacancy-details__block-grafik">
                  График: {vacancy.type_schedule}
                </div>
                <div className="vacancy-details__block-type">
                  Тип вакансии: {vacancy.type_location}
                </div>
                <div className="vacancy-details__cost">
                  <div className="vacancy-details__cost-item">
                    Заработная плата:
                  </div>
                  <div className="vacancy-details__cost-price">
                    от <span>{vacancy.salary_from} </span>до{' '}
                    <span>{vacancy.salary_to} </span>тыс.
                  </div>
                </div>
              </div>
              <div className="vacancy-map">
                <div className="contacts-block__col">
                  <div className="contacts-block__map" id="map" />
                </div>
                <div className="contacts-block__col">
                  <div className="contacts-block__info">
                    <div className="contacts-block__info-title">
                      {company.name}
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="contacts-block__row" href="#">
                      <img src={icon1} alt="#" />
                      {company.address}
                    </a>
                    <a
                      className="contacts-block__row"
                      href={`tel:${company.phone}`}
                    >
                      <img src={icon2} alt="#" />
                      {company.phone}
                    </a>
                    <a
                      className="contacts-block__row contacts-block__link"
                      href={`mailto:${company.email}`}
                    >
                      <img src={icon3} alt="#" />
                      {company.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="share">
                <div className="share-title">Поделиться вакансией</div>
                <div className="share-block">
                  <a className="share-block__item vk" href="https://vk.com">
                    <img src={vk} alt="#" />
                  </a>
                  <a
                    className="share-block__item twitter"
                    href="https://twitter.com"
                  >
                    <img src={twitter} alt="#" />
                  </a>
                  <a
                    className="share-block__item fb"
                    href="https://facebook.com"
                  >
                    <img src={fb} alt="#" />
                  </a>
                  <a
                    className="share-block__item ok"
                    href="https://odnoklassniki.ru"
                  >
                    <img src={ok} alt="#" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="must-have__block">
                <div className="must-have__block-title">Обязанности</div>
                <div className="must-have__block-desc">
                  {vacancy.text_responsibilities}
                </div>
              </div>
              <div className="must-have__block">
                <div className="must-have__block-title">Требования</div>
                <div className="must-have__block-desc">
                  {vacancy.text_requirements}
                </div>
              </div>
              <div className="must-have__block">
                <div className="must-have__block-title">Условия</div>
                <div className="must-have__block-desc">
                  {vacancy.text_conditions}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work-proposition">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="work-proposition__block">
                <div className="work-proposition__block-text">
                  <div className="work-proposition__block-title">
                    Получи предложение работы за 24 часа
                  </div>
                  <div className="work-proposition__block-desc">
                    Отправь самую интересную информацию о себе и своих навыках,
                    чтобы наш специалист выделил тебя еще сильнее!
                  </div>
                  <button
                    className="dflt-bttn"
                    onClick={() => setShow(true)}
                    type="button"
                  >
                    Откликнуться на вакансию
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Vacancies main />
    </>
  );
};

export default SingleVacancy;
