import React, { useState } from 'react';
import TrialWorkDescription from './TrialWorkDescription/TrialWorkDescription';
import TrialWorkReport from './TrialWorkReport/TrialWorkReport';
import { IParams } from '../../types/test';

const TrialWork: React.FC<{ vacancyID: IParams }> = ({ vacancyID }) => {
  const [showReport, setShowReport] = useState(false);

  return (
    <>
      {showReport ? (
        <TrialWorkReport vacancyID={vacancyID} />
      ) : (
        <TrialWorkDescription
          vacancyID={vacancyID}
          setShowReport={setShowReport}
        />
      )}
    </>
  );
};

export default TrialWork;
