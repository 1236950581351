import React from 'react';
import { getNumOfStep, Vocabulary } from '../../../helpers/helpersFunc';
import useTypedSelector from '../../../hooks/useTypedSelector';
import StyledTitle from '../../StyledComponents/StyledTitle';
import * as Styled from './StyledPresentationText';

interface PresentationTextProps {
  name?: string;
  lastName?: string;
  active: boolean;
}

const PresentationText: React.FunctionComponent<PresentationTextProps> = ({
  name,
  lastName,
  active,
}) => {
  const { stages } = useTypedSelector(r => r.automatic);

  switch (active) {
    case true:
      return (
        <Styled.TextBlock>
          <StyledTitle>
            Поздравляем с&nbsp;прохождением <br />
            <span>
              {stages !== undefined
                ? Vocabulary[getNumOfStep(stages, 'RESUME')]
                : Vocabulary[1]}{' '}
              этапа
            </span>
          </StyledTitle>
          <p className="text">
            Здравствуйте,{' '}
            <span>
              {name} {lastName}.
            </span>
          </p>
          <p className="text text-bottom">
            Мы получили от вас отклик на вакансию. Посмотрите видео, чтобы
            ознакомиться с дальшейшими действиями:
          </p>
        </Styled.TextBlock>
      );
    case false:
      return (
        <Styled.TextBlock>
          <StyledTitle>
            Мы выбрали <span>ваше резюме</span> на нашу вакансию
          </StyledTitle>
          <p className="text">
            Здравствуйте! Нашей компании требуется новый сотрудник.
          </p>
          <p className="text text-bottom">
            Предлагаем вам ознакомиться с вакансией и посмотреть видео, чтобы
            ознакомиться с дальшейшими действиями:
          </p>
        </Styled.TextBlock>
      );

    default:
      return (
        <Styled.TextBlock>
          <StyledTitle>
            Поздравляем <br />с прохождением <br />
            <span>второго этапа</span>
          </StyledTitle>
        </Styled.TextBlock>
      );
  }
};

export default PresentationText;
