import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.374px;
  text-transform: uppercase;
  margin-bottom: 20px;

  span {
    color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    font-size: 35px;
    line-height: 52px;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const Text = styled.h2`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.374px;
  margin-bottom: 35px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 126.8%;
    margin-bottom: 37px;
    text-align: center;
  }
`;

export const TestList = styled.div`
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 20px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 30px;
    margin-bottom: 58px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const TestItem = styled.div`
  position: relative;
  padding: 17px;
  padding-top: 19px;
  padding-left: 80px;
  border-radius: 5px;
  background-color: var(--main-block-bg);
  border-radius: 20px;

  svg {
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    color: var(--main-accent-color);
  }

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(calc(-50% - 4px));
    left: 20px;
    box-shadow: 0px 2px 6px var(--main-accent-color-hover),
      inset 0px 2px 6px var(--main-accent-color-hover);
    z-index: 1;
  }

  @media (min-width: 992px) {
    padding-top: 145px;
    padding-bottom: 36px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border-radius: 30px;

    svg {
      width: 130px;
      height: 130px;
      top: 25px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &::before {
      top: 48px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 70px;
      height: 70px;
    }
  }
`;

export const ItemTitle = styled.h3`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.374px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 6px;

  @media (min-width: 992px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

export const ItemText = styled.p`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.374px;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const ApplicationWrapper = styled.div`
  margin-bottom: 24px;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const ApplicationBlock = styled.div`
  padding: 24px 14px 17px 14px;
  border-radius: 5px;
  background: var(--main-block-bg);
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 208px;
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 30px 22px;
  }

  @media (min-width: 992px) {
    border-radius: 30px;
  }

  @media (min-width: 1200px) {
    min-height: 331px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 59px;
    margin: 0 auto 15px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);

    @media (min-width: 768px) {
      width: 80px;
      height: 80px;
    }

    @media (min-width: 1200px) {
      width: 113px;
      height: 113px;
    }
  }

  img {
    max-width: 40px;

    @media (min-width: 1200px) {
      width: 42px;
      height: 56px;
    }
  }

  .title {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 15px;
    }
  }

  .link-wrapper {
    width: 100px;
    margin: 0 auto;
    margin-top: auto;

    @media (min-width: 576px) {
      width: 122px;
    }
  }
`;

export const Row = styled.div`
  height: 100%;
`;
