import React from 'react';
import { declOfNum } from '../../../helpers/helpersFunc';
import { arrowDashed, test1, test2 } from './icons';
import { TestListItemI } from '../../../types/test';
import TestDescription from '../../Test/TestDescription/TestDescription';

interface TestTextDescriptionProps {
  test: TestListItemI;
  startTask: () => void;
}

const TestTextDescription: React.FunctionComponent<TestTextDescriptionProps> = ({
  test,
  startTask,
}) => {
  return (
    <TestDescription title="Тесты" testName={test.name} startTask={startTask}>
      <div className="box">
        <div className="icon-wrap">{test1}</div>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: test.description }}
        ></div>
      </div>
      <div className="box">
        <div className="icon-wrap">
          <div className="circle">
            <span className="test-number">{test.count_questions}</span>
            <span className="test-text">
              {declOfNum(test.count_questions, [
                'вопрос',
                'вопроса',
                'вопросов',
              ])}
            </span>
            {arrowDashed}
          </div>
          <div className="circle">
            <span className="test-number">
              {test.time_limit ? Math.ceil(test.time_limit / 60) : 0}
            </span>
            <span className="test-text">
              {declOfNum(
                test.time_limit ? Math.ceil(test.time_limit / 60) : 0,
                ['минута', 'минуты', 'минут'],
              )}
            </span>
          </div>
        </div>
        <p className="text">
          {`На прохождение теста есть ${
            test.time_limit ? Math.ceil(test.time_limit / 60) : ''
          } мин., всего вас ждёт ${
            test.count_questions
          } ${declOfNum(test.count_questions, [
            'вопрос',
            'вопроса',
            'вопросов',
          ])}.`}
        </p>
      </div>
      <div className="box">
        <div className="icon-wrap">{test2}</div>
        <p className="text">
          Из предложенных вариантов выберите один из наиболее подходящих
          ответов.
        </p>
      </div>
      <div className="box">
        <div></div>
        <p className="text">
          Теперь, когда вы все знаете, <br /> поехали :)
        </p>
      </div>
    </TestDescription>
  );
};

export default TestTextDescription;
