export enum PageStateEnum {
  RESUME = 'resume',
  TRIALWORK = 'trialwork',
  TESTING = 'testing',
  AVTESTING = 'avtesting',
  INTERVIEW = 'interview',
}

export const getPageType = (page: string): { page: string | null } => {
  let value;
  switch (page) {
    case 'RESUME':
      value = { page: PageStateEnum.RESUME };
      break;
    case 'TRIALWORK':
      value = { page: PageStateEnum.TRIALWORK };
      break;
    case 'TESTING':
      value = { page: PageStateEnum.TESTING };
      break;
    case 'AVTESTING':
      value = { page: PageStateEnum.AVTESTING };
      break;
    case 'INTERVIEW':
      value = { page: PageStateEnum.INTERVIEW };
      break;
    default:
      value = { page: null };
      break;
  }
  return value;
};
