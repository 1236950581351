import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactMediaRecorder } from 'react-media-recorder';
import { ID, IQuestion } from '../../../types/recordingTest';
import { IParams } from '../../../types/test';
import StyledLink from '../../StyledComponents/StyledLink';
import * as Styled from './StyledTestAudioQuestion';
import {
  fetchRecordingTestQuestion,
  sendMediaFile,
} from '../../../store/actions/recordingTest';
import StyledModal from '../../StyledComponents/StyledModal';
import { errorIcon, infoIcon, timeIcon } from './icons';
import AudioTrack from '../AudioTrack/AudioTrack';
import TestAudioQuestionTime from './TestAudioQuestionTime';

interface TestAudioQuestionProps {
  question: IQuestion;
  testId: ID;
  vacancyID: IParams;
  setIsLoading: React.Dispatch<React.SetStateAction<string>>;
  finish: boolean;
  setFinish: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestAudioQuestion: React.FunctionComponent<TestAudioQuestionProps> = ({
  question,
  testId,
  vacancyID,
  setIsLoading,
  finish,
  setFinish,
}) => {
  const [recordStatus, setRecordStatus] = useState<boolean | undefined>();
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showTimeError, setShowTimeError] = useState(false);
  const [recordIsStarted, setRecordIsStarted] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const dispatch = useDispatch();

  const nextBtnClick = () => {
    setShowTimeError(false);
    dispatch(fetchRecordingTestQuestion(vacancyID as string, testId));
  };

  useEffect(() => {
    if (showLoadingMessage) {
      setIsLoading('audio');
    }
  }, [showLoadingMessage]);

  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    clearBlobUrl,
    error,
    previewAudioStream,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    blobPropertyBag: {
      type: 'audio/mp3',
    },
  });

  useEffect(() => {
    if (!recordStatus) {
      stopRecording();
    }
  }, [recordStatus]);

  useEffect(() => {
    if (mediaBlobUrl) {
      setShowLoadingMessage(true);
      dispatch(
        sendMediaFile(
          mediaBlobUrl,
          'audio',
          vacancyID as string,
          testId,
          setShowLoadingMessage,
        ),
      );
      clearBlobUrl();
      setRecordStatus(false);
    }
  }, [mediaBlobUrl]);

  const handleStartRecord = () => {
    setRecordStatus(true);
    startRecording();
    setRecordIsStarted(true);
  };

  const handleStopRecord = () => {
    setRecordStatus(false);
    stopRecording();
  };

  useEffect(() => {
    if (finish) {
      if (status === 'recording') {
        handleStopRecord();
      } else if (!recordIsStarted) {
        setShowTimeError(true);
      }
    } else {
      setShowTimeError(false);
    }
  }, [finish, status, recordIsStarted]);

  useEffect(() => {
    if (error === 'permission_denied') {
      setPermissionError(true);
    } else if (!error) {
      setPermissionError(false);
    }
  }, [error]);

  return (
    <>
      {permissionError && (
        <StyledModal
          icon={errorIcon}
          isErrorModal
          setOpened={setPermissionError}
        >
          <div className="text-wrap">
            <h2 className="title">Ошибка</h2>
            <p className="text">
              Разрешите доступ к камере в настройках браузера и перезапустите
              браузер
            </p>
          </div>
        </StyledModal>
      )}
      {showTimeError && (
        <StyledModal icon={timeIcon} isErrorModal>
          <div className="text-wrap">
            <h2 className="title">Время вышло</h2>
            <p className="text">
              Если вы начали запись, она была отправлена работодателю
            </p>
          </div>
          <Styled.NextLinkWrapper>
            <StyledLink
              onClick={nextBtnClick}
              text="Далее"
              button
              type="button"
              transparentBackground
              border
            ></StyledLink>
          </Styled.NextLinkWrapper>
        </StyledModal>
      )}
      {showLoadingMessage && (
        <StyledModal icon={infoIcon}>
          <div className="text-wrap">
            <h2 className="title">Подождите</h2>
            <p className="text">
              Запись отправляется. Это займет некоторое время...
            </p>
          </div>
        </StyledModal>
      )}

      <Styled.AudioDemo>
        <span
          className={status === 'recording' ? 'status active' : 'status'}
        ></span>
        <div className="bar">
          <div className="bar-line"></div>
          <AudioTrack stream={previewAudioStream} />
        </div>
        {status === 'recording' ? (
          <TestAudioQuestionTime />
        ) : (
          <Styled.Time>00:00</Styled.Time>
        )}
      </Styled.AudioDemo>
      <Styled.AttentionBlock>
        <p className="attention-title">Внимание!</p>
        <p className="attention-text">
          Время на выполнение тестового задания ограничено. После истечения
          времени — отправить запись будет невозможно!
        </p>
      </Styled.AttentionBlock>
      <Styled.BtnsWrapper>
        {status === 'recording' ? (
          <StyledLink
            text="Отправить запись"
            button
            onClick={() => handleStopRecord()}
          />
        ) : (
          <StyledLink
            text="Запись"
            button
            onClick={() => handleStartRecord()}
          />
        )}
      </Styled.BtnsWrapper>
    </>
  );
};

export default React.memo(TestAudioQuestion);
