export interface CompanyI {
  id: number;
  name: string;
  color: number;
  image: number;
  address: string;
  email: string;
  city: string;
  phone: string;
  time_zone: number;
  description: string;
  video: string | number;
}

export enum CompanyActionTypes {
  FETCH_COMPANY = 'FETCH_COMPANY',
}

interface FetchCompanyAction {
  type: CompanyActionTypes.FETCH_COMPANY;
  payload: CompanyI;
}

export type CompanyActions = FetchCompanyAction;

export interface IStage {
  code: string;
  sort: number;
  vacancy_id?: number;
}
