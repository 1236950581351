import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { setAnswersId, stopTests } from '../../../store/actions/test';
import * as Styled from './StyledTestTextQuestion';
import TestTextAnswer from '../TestTextAnswer/TestTextAnswer';
import StyledLink from '../../StyledComponents/StyledLink';
import Timer from '../../Timer/Timer';
import { TestListItemI } from '../../../types/test';
import TestResult from '../../Test/TestResult/TestResult';
import StyledModal from '../../StyledComponents/StyledModal';
import { icon } from './icons';

interface ITaskProps {
  text?: boolean;
  multiType?: boolean;
}

const TaskSingle: React.FC<ITaskProps> = ({ text, multiType }) => {
  const params: { id: string } = useParams();
  const vacancyID = params ? params.id : null;
  const { singleQuestion, list } = useTypedSelector(r => r.test.tests);
  const dispatch = useDispatch();

  const currentTest = list.filter(
    (test: TestListItemI) => test.status === 'B',
  )[0];

  const stopTime = moment(currentTest.date_start, 'DD.MM.YYYY HH:mm:ss')
    .add(currentTest.time_limit, 's')
    .toDate()
    .getTime();

  const [finish, setFinish] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [checked, setChecked] = useState<string | number | undefined>();
  const [checkedM, setCheckedM] = useState<Array<string>>([]);
  const [showError, setShowError] = useState(false);

  const stateChecked = multiType ? checkedM : checked;

  useEffect(() => {
    if (finish) {
      window.scrollTo(0, 170);
      setShowResult(true);
      dispatch(stopTests(vacancyID as string));
    }
  }, [finish]);

  useEffect(() => {
    if (showResult) {
      setShowError(false);
    }
  }, [showResult]);

  const handleClick = () => {
    if (Array.isArray(stateChecked) ? stateChecked.length : stateChecked) {
      setShowError(false);
      if (singleQuestion.count_questions === singleQuestion.question_number) {
        setShowResult(true);
        window.scrollTo(0, 170);
      }
      if (stateChecked)
        dispatch(
          setAnswersId(
            vacancyID as string,
            stateChecked,
            singleQuestion.count_questions === singleQuestion.question_number,
          ),
        );
      setChecked(undefined);
      setCheckedM([]);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <Styled.TaskWrapper>
        <Styled.TitleWrapper>
          <Styled.MobileTitle>Тесты</Styled.MobileTitle>
          <Styled.Title>{currentTest.name}</Styled.Title>
          {!showResult && (
            <Timer finishTime={stopTime} finishHandler={setFinish} square />
          )}
        </Styled.TitleWrapper>
        <Styled.ContentWrapper>
          <Styled.MobileTestTitle>{currentTest.name}</Styled.MobileTestTitle>

          {showResult ? (
            <TestResult
              vacancyID={vacancyID as string}
              status={finish ? 'fail' : 'success'}
            />
          ) : (
            <>
              <Styled.CurrentNumber>
                <span className="current">
                  {singleQuestion.question_number}{' '}
                </span>
                <span>/ </span>
                <span>{singleQuestion.count_questions}</span>
              </Styled.CurrentNumber>
              <Styled.Question>
                <Styled.QuestionTitle>
                  {singleQuestion.name}
                </Styled.QuestionTitle>
                {singleQuestion.description && (
                  <Styled.QuestionDescription>
                    {singleQuestion.description}
                  </Styled.QuestionDescription>
                )}
                {singleQuestion.image && (
                  <img
                    className="image"
                    src={`${process.env.REACT_APP_API_URL}${singleQuestion.image}`}
                    alt="#"
                    width="222"
                    height="150"
                  />
                )}
                <form action="#" method="POST">
                  <TestTextAnswer
                    answers={singleQuestion.answers}
                    text={text}
                    multiType={multiType}
                    checked={checked}
                    setChecked={setChecked}
                    checkedM={checkedM}
                    setCheckedM={setCheckedM}
                  />
                  <Styled.LinkWrapper>
                    <StyledLink
                      onClick={() => handleClick()}
                      type="button"
                      text="Далее"
                      button
                    />
                  </Styled.LinkWrapper>
                </form>
              </Styled.Question>
            </>
          )}
        </Styled.ContentWrapper>
      </Styled.TaskWrapper>
      {showError && (
        <StyledModal setOpened={setShowError} icon={icon} isErrorModal>
          <div className="text-wrap">
            <h2 className="title">Ошибка</h2>
            <p className="text">Вы не выбрали ни одного варианта ответа!</p>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default TaskSingle;
