import styled from 'styled-components';

export const TestContent = styled.div`
  margin-bottom: 2px;

  @media (min-width: 768px) {
    height: 620px;
    overflow-y: auto;
    margin-bottom: 0;
    order: 1;
  }

  @media (min-width: 992px) {
    height: 810px;
  }
`;

export const TestBlock = styled.section`
  display: grid;
`;
