import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import StyledLink from '../../StyledComponents/StyledLink';
import StyledModal from '../../StyledComponents/StyledModal';
import docIcon1 from '../../../assets/img/doc-icon-1.svg';
import docIcon2 from '../../../assets/img/doc-icon-2.svg';
import {
  getNumOfStep,
  getTime,
  VocabularyNum,
} from '../../../helpers/helpersFunc';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { IParams } from '../../../types/test';
import { useActions } from '../../../hooks/useActions';
import * as Styled from './StyledTrailWorkDescription';
import TrialWorkProgress from '../TrialWorkProgress/TrialWorkProgress';
import { IFileProps } from '../../../types/trialWork';
import { icon1, icon2, icon3, icon4, icon5 } from './icons';
import { config } from '../../../store/actions/actionsAssets';

interface IProblemProps {
  setShowReport: (n: boolean) => void;
  vacancyID: IParams;
}

const TrialWorkDescription: React.FC<IProblemProps> = ({
  setShowReport,
  vacancyID,
}) => {
  const [opened, setOpened] = useState(false);
  const { data } = useTypedSelector(r => r.testing.trialWork);
  const { profile } = useTypedSelector(r => r.auth);
  const { currentStage, stages } = useTypedSelector(r => r.automatic);
  const { fetchTrialWorkData, trialWorkStart } = useActions();

  useEffect(() => {
    fetchTrialWorkData(vacancyID as string);
  }, []);

  const handleSubmit = (close?: boolean) => {
    if (currentStage.action === 'START') {
      trialWorkStart(vacancyID as string);
      if (close) {
        setOpened(false);
      }
    }
  };

  const downFunk = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    setOpened(true);
  };

  const handleClickLink = async (
    e: React.SyntheticEvent<EventTarget>,
    url: string,
  ) => {
    if (currentStage.action === 'GET') {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url.slice(1, url.length)}`,
        config,
      );
      setTimeout(() => {
        window.open(
          `${process.env.REACT_APP_API_URL}${response.data}`,
          '_blank',
        );
      });
    } else {
      downFunk(e);
    }
  };

  return (
    <>
      <section>
        <Styled.Title>
          {profile.last_name} {profile.name}, начнем{' '}
          <span>
            {stages !== undefined
              ? VocabularyNum[getNumOfStep(stages, 'TRIALWORK')]
              : 'Следующий'}{' '}
            этап
          </span>
        </Styled.Title>
        <Styled.Text>
          {' '}
          {getNumOfStep(stages, 'TRIALWORK')} этап включает в себя тестовое
          задание
        </Styled.Text>
        <Styled.TestList>
          <Styled.TestItem>
            {icon1}
            <Styled.ItemTitle>Тестовое задание</Styled.ItemTitle>
            <Styled.ItemText>{data ? data.name : '...'}</Styled.ItemText>
          </Styled.TestItem>
          <Styled.TestItem>
            {icon2}
            <Styled.ItemTitle>Цель</Styled.ItemTitle>
            <Styled.ItemText>{data ? data.target : '...'}</Styled.ItemText>
          </Styled.TestItem>
          <Styled.TestItem>
            {icon3}
            <Styled.ItemTitle>Задача</Styled.ItemTitle>
            <Styled.ItemText>{data ? data.description : '...'}</Styled.ItemText>
          </Styled.TestItem>
          <Styled.TestItem>
            {icon4}
            <Styled.ItemTitle>Время выполнения</Styled.ItemTitle>
            <Styled.ItemText>
              {data ? getTime(+data.time_limit) : '...'}
            </Styled.ItemText>
          </Styled.TestItem>
        </Styled.TestList>
        <div className="row align-items-stretch flex-column-reverse flex-xl-row">
          <div className="col-xl-8">
            <TrialWorkProgress
              stage={currentStage.action}
              handleSubmit={handleSubmit}
              setShowReport={setShowReport}
            />
          </div>
          <div className="col-xl-4">
            <h3 className="visually-hidden">Приложения</h3>
            <Styled.Row className="row">
              {data &&
                data.files.map((item: IFileProps) => {
                  return (
                    <Styled.ApplicationWrapper
                      className="col-6"
                      key={item.title}
                    >
                      <Styled.ApplicationBlock>
                        <div className="icon">
                          <img src={docIcon2} alt="#" width="40" height="40" />
                        </div>
                        <h6 className="title">
                          {item.title ? item.title : 'Файл'}
                        </h6>
                        <div className="link-wrapper">
                          <StyledLink
                            text="Скачать"
                            transparentBackground
                            border
                            button
                            onClick={e => handleClickLink(e, item.url)}
                            inlineStyles={{
                              padding: '11px',
                              fontSize: '17px',
                              textTransform: 'none',
                            }}
                          ></StyledLink>
                        </div>
                      </Styled.ApplicationBlock>
                    </Styled.ApplicationWrapper>
                  );
                })}

              {/* МОДАЛЬНОЕ ОКНО + OVERLAY */}
              {opened && (
                <StyledModal setOpened={setOpened} icon={icon5}>
                  <div className="text-wrap">
                    <h2 className="title">Подождите</h2>
                    <p className="text">
                      Перед тем как ознакомиться более подробно, нажми кнопку
                      «Приступить к выполнению»
                    </p>
                  </div>
                  <StyledLink
                    onClick={() => handleSubmit(true)}
                    text="Приступить&nbsp;к&nbsp;выполнению"
                    button
                  ></StyledLink>
                </StyledModal>
              )}
            </Styled.Row>
          </div>
        </div>
      </section>
    </>
  );
};
export default TrialWorkDescription;
