import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactMediaRecorder } from 'react-media-recorder';
import Loader from 'react-loader-spinner';
import { ID, IQuestion } from '../../../types/recordingTest';
import { IParams } from '../../../types/test';
import StyledLink from '../../StyledComponents/StyledLink';
import * as Styled from './StyledTestVideoQuestion';
import {
  fetchRecordingTestQuestion,
  sendMediaFile,
} from '../../../store/actions/recordingTest';
import Timer from '../../Timer/Timer';
import { errorIcon, icon, infoIcon, timeIcon } from './icons';
import StyledModal from '../../StyledComponents/StyledModal';

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video ref={videoRef as any} width={400} height={300} autoPlay />;
};

interface TestVideoQuestionProps {
  question: IQuestion;
  testId: ID;
  vacancyID: IParams;
  stopTime: number;
  setIsLoading: React.Dispatch<React.SetStateAction<string>>;
  finish: boolean;
  setFinish: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestVideoQuestion: React.FunctionComponent<TestVideoQuestionProps> = ({
  question,
  testId,
  vacancyID,
  stopTime,
  setIsLoading,
  finish,
  setFinish,
}) => {
  const [recordStatus, setRecordStatus] = useState<boolean | undefined>();
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showTimeError, setShowTimeError] = useState(false);
  const [recordIsStarted, setRecordIsStarted] = useState(false);
  const dispatch = useDispatch();

  const nextBtnClick = () => {
    setShowTimeError(false);
    dispatch(fetchRecordingTestQuestion(vacancyID as string, testId));
  };

  useEffect(() => {
    setRecordIsStarted(false);
  }, []);

  useEffect(() => {
    if (showLoadingMessage) {
      setIsLoading('video');
    }
  }, [showLoadingMessage]);

  const {
    previewStream,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    clearBlobUrl,
    error,
  } = useReactMediaRecorder({
    video: true,
    audio: false,
    blobPropertyBag: {
      type: 'video/mp4',
    },
  });

  const handleStartRecord = () => {
    setRecordStatus(true);
    startRecording();
    setRecordIsStarted(true);
  };
  const handleStopRecord = () => {
    setRecordStatus(false);
    stopRecording();
  };

  useEffect(() => {
    if (mediaBlobUrl) {
      setShowLoadingMessage(true);
      dispatch(
        sendMediaFile(
          mediaBlobUrl,
          'video',
          vacancyID as string,
          testId,
          setShowLoadingMessage,
        ),
      );
      clearBlobUrl();
      setRecordStatus(false);
    }
  }, [mediaBlobUrl]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (finish) {
      if (status === 'recording') {
        handleStopRecord();
      } else if (!recordIsStarted) {
        setShowTimeError(true);
      }
    }
  }, [finish, status, recordIsStarted]);

  return (
    <>
      {showTimeError && (
        <StyledModal icon={timeIcon} isErrorModal>
          <div className="text-wrap">
            <h2 className="title">Время вышло</h2>
            <p className="text">
              Если вы начали запись, она была отправлена работодателю
            </p>
          </div>
          <Styled.NextLinkWrapper>
            <StyledLink
              onClick={nextBtnClick}
              text="Далее"
              button
              type="button"
              transparentBackground
              border
            ></StyledLink>
          </Styled.NextLinkWrapper>
        </StyledModal>
      )}
      {showLoadingMessage && (
        <StyledModal icon={infoIcon}>
          <div className="text-wrap">
            <h2 className="title">Подождите</h2>
            <p className="text">
              Запись отправляется. Это займет некоторое время...
            </p>
          </div>
        </StyledModal>
      )}
      <Styled.StartVideo>
        {error === 'permission_denied' && (
          <>
            {errorIcon}
            <p>
              Разрешите доступ к камере в настройках браузера и перезапустите
              браузер
            </p>
          </>
        )}
        {recordStatus && !previewStream && !error && (
          <Loader
            type="Rings"
            color="var(--main-accent-color)"
            height="50px"
            width="50px"
          />
        )}
        {recordStatus && previewStream && (
          <Styled.ModalContainer>
            <div className="wrapper">
              <Styled.TimerContainer>
                <Timer finishTime={stopTime} finishHandler={setFinish} square />
              </Styled.TimerContainer>
              <VideoPreview stream={previewStream} />
              <Styled.LinkWrapper className="stop-link">
                {recordStatus && (
                  <StyledLink
                    text="Отправить запись"
                    button
                    onClick={() => handleStopRecord()}
                    inlineStyles={{
                      textTransform: 'none',
                    }}
                  />
                )}
              </Styled.LinkWrapper>
            </div>
          </Styled.ModalContainer>
        )}
        {!recordStatus && (
          <Styled.LinkWrapper>
            <StyledLink
              additionalClass={recordStatus ? 'disabled' : ''}
              text="Начать запись"
              button
              onClick={() => handleStartRecord()}
            >
              {icon}
            </StyledLink>
          </Styled.LinkWrapper>
        )}
      </Styled.StartVideo>
    </>
  );
};

export default TestVideoQuestion;
