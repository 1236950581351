import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchTestsList } from '../../../store/actions/test';
import StyledLink from '../../StyledComponents/StyledLink';
import { failIcon, successIcon } from './icons';
import * as Styled from './StyledTestResult';

interface TestResultProps {
  vacancyID: string;
  status: 'success' | 'fail';
}

const TestResult: React.FunctionComponent<TestResultProps> = ({
  vacancyID,
  status,
}) => {
  const dispatch = useDispatch();

  const handleNextLinkClick = () => {
    dispatch(fetchTestsList(vacancyID));
  };

  return (
    <>
      {status === 'success' ? (
        <Styled.Wrapper>
          {successIcon}
          <div>
            <p className="subtitle">Спасибо!</p>
            <p className="text">
              Ваш ответ успешно отправлен. Перейдем к следующему этапу.
            </p>
          </div>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          {failIcon}
          <div>
            <p className="subtitle">Время вышло!</p>
            <p className="text">
              Мы приняли результат работы, на который вы успели дать ответ.
            </p>
          </div>
        </Styled.Wrapper>
      )}
      <Styled.LinkWrap>
        <StyledLink
          text="далее"
          onClick={handleNextLinkClick}
          button
          type="button"
        />
      </Styled.LinkWrap>
    </>
  );
};

export default TestResult;
