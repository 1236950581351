import React from 'react';

export const icon = (
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3.07143C20 1.65329 18.8787 0.5 17.5 0.5H2.5C1.12125 0.5 0 1.65329 0 3.07143V15.9286C0 17.3467 1.12125 18.5 2.5 18.5H17.5C18.8787 18.5 20 17.3467 20 15.9286V11.6433L25 15.9286V3.07143L20 7.35671V3.07143Z"
      fill="currentColor"
    />
  </svg>
);

export const errorIcon = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="100"
      cy="96"
      r="78"
      stroke="var(--error-text-color)"
      strokeWidth="2"
    />
    <path
      d="M103.64 109.163H96.5888L95.2208 58.1233H105.008L103.64 109.163ZM100.062 132.42C98.5182 132.42 97.2202 131.893 96.1679 130.841C95.1155 129.789 94.5894 128.491 94.5894 126.947C94.5894 125.404 95.1155 124.106 96.1679 123.054C97.2202 122.001 98.5182 121.475 100.062 121.475C101.605 121.475 102.903 122.001 103.955 123.054C105.008 124.106 105.534 125.404 105.534 126.947C105.534 128.491 105.008 129.789 103.955 130.841C102.903 131.893 101.605 132.42 100.062 132.42Z"
      fill="var(--error-text-color)"
    />
  </svg>
);

export const infoIcon = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="100" cy="96" r="78" stroke="currentColor" strokeWidth="2" />
    <path
      d="M103.64 109.163H96.5888L95.2208 58.1233H105.008L103.64 109.163ZM100.062 132.42C98.5182 132.42 97.2202 131.893 96.1679 130.841C95.1155 129.789 94.5894 128.491 94.5894 126.947C94.5894 125.404 95.1155 124.106 96.1679 123.054C97.2202 122.001 98.5182 121.475 100.062 121.475C101.605 121.475 102.903 122.001 103.955 123.054C105.008 124.106 105.534 125.404 105.534 126.947C105.534 128.491 105.008 129.789 103.955 130.841C102.903 131.893 101.605 132.42 100.062 132.42Z"
      fill="currentColor"
    />
  </svg>
);

export const timeIcon = (
  <svg
    width="155"
    height="155"
    viewBox="0 0 155 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M52 41H102.116M52 114.086H102.116M95.6073 108.433V94.6806C95.6073 92.9885 94.8492 91.3854 93.5413 90.3118L60.5746 63.2512C59.2667 62.1776 58.5086 60.5745 58.5086 58.8824V46.6521C58.5086 43.5306 61.0391 41 64.1607 41H89.9552C93.0768 41 95.6073 43.5305 95.6073 46.6521V58.8824C95.6073 60.5745 94.8492 62.1776 93.5413 63.2512L60.5746 90.3118C59.2667 91.3854 58.5086 92.9885 58.5086 94.6806V108.433C58.5086 111.555 61.0391 114.086 64.1607 114.086H89.9552C93.0768 114.086 95.6073 111.555 95.6073 108.433Z"
        stroke="var(--error-text-color)"
        strokeWidth="3.42589"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="crispEdges"
      />
    </g>
    <circle
      opacity="0.4"
      cx="78"
      cy="78"
      r="64.5"
      stroke="var(--main-text-color)"
    />
    <circle
      opacity="0.1"
      cx="77.5"
      cy="77.5"
      r="77"
      stroke="var(--main-text-color)"
    />
  </svg>
);
