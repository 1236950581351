import axios from 'axios';
import { Dispatch } from 'redux';
import { MainPageActions, MainPageActionTypes } from '../../types/mainPage';
import { config, url } from './actionsAssets';

export const fetchDicts = () => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const dicts = await axios.get(`${url}dictionary/list/`, config);
      dispatch({
        type: MainPageActionTypes.FETCH_DICTS,
        payload: dicts.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
