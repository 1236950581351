import styled from 'styled-components';

export const Loader = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TaskWrapper = styled.section`
  background-color: var(--main-block-bg);
  border-radius: 20px 20px 0 0;

  @media (min-width: 768px) {
    padding-top: 30px;
    padding-left: 0;
    padding-bottom: 40px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    padding-top: 40px;
    padding-right: 48px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  background-color: var(--secondary-block-bg);
  border-radius: 20px 20px 0px 0px;

  @media (min-width: 768px) {
    background-color: transparent;
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--main-text-color);
    margin-bottom: 40px;
  }
`;

export const MobileTitle = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.374px;
  text-transform: uppercase;
  margin: 0;
  margin-right: 15px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h2`
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: none;
    margin: 0;
    margin-right: 15px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 24px 30px 40px 24px;

  .desc {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const AttentionBlock = styled.div`
  max-width: 500px;

  .attention-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }

  .attention-text {
    margin: 0;
    font-size: 10px;
    line-height: 135%;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const MobileTestTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 10px;
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--main-text-color);

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CurrentNumber = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;

  .current {
    font-size: 20px;
    line-height: 25px;
    color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;

    .current {
      font-size: 20px;
      line-height: 25px;
    }
  }
`;

export const QuestionTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
  }
`;
