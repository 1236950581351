export const url = process.env.REACT_APP_API_URL;
const token1 = localStorage.getItem('token');
export const config = {
  headers: {
    Authorization: `Bearer ${token1}`,
    withCredentials: true,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
};

export enum DateTime {
  API_DATE_FORMAT = 'DD.MM.YYYY HH:mm',
  API_DATE = 'DD.MM.YYYY',
  API_DATE_FORMAT_RESPONSE = 'DD.MM.YYYY hh:mm:ss',
}
