import React, { useState } from 'react';
import { Slider, withStyles } from '@material-ui/core';
import StyledLink from '../../StyledComponents/StyledLink';
import StyledModal from '../../StyledComponents/StyledModal';

import { IParams } from '../../../types/test';
import { useActions } from '../../../hooks/useActions';

import { checkStringIsURL } from '../../../helpers/helpersFunc';
import * as Styled from './StyledTrialWorkReport';

const CustomSlider = withStyles({
  root: {
    color: 'var(--main-text-color)',
    padding: '10px 0',
  },
  rail: {
    backgroundColor: 'var(--main-text-color)',
    height: 5,
    opacity: 1,
    borderRadius: 5,
  },
  track: {
    backgroundColor: 'var(--main-accent-color)',
    boxShadow: '0px 4px 21px var(--main-accent-shadow)',
    height: 5,
    borderRadius: 5,
  },
  thumb: {
    position: 'relative',
    zIndex: 2,
    boxShadow: '0px 4px 21px var(--main-accent-shadow)',
    border: '3px solid var(--main-text-color)',
    backgroundColor: 'var(--main-accent-color)',
    height: 21,
    width: 21,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  valueLabel: {
    left: 'calc(-50% - 3px)',
    '& *': {
      background: 'transparent',
      color: 'var(--main-text-color)',
      fontSize: 15,
    },
  },
  mark: {
    backgroundColor: 'var(--main-text-color)',
    height: 8,
    width: 1,
    marginTop: 20,
  },
})(Slider);

type StateType = number | number[] | undefined;
type StateType3 = string[];

const TrialWorkReport: React.FC<{ vacancyID: IParams }> = ({ vacancyID }) => {
  const { submitTrialWorkData, fetchCurrentStage } = useActions();
  const [inputEl, setInputEl] = useState('');
  const [inputElRef, setInputElRef] = useState<StateType3>([]);
  const [inputReward, setInputReward] = useState<StateType>(1);
  const [inputTime, setInputTime] = useState<StateType>(1);
  const [error, setError] = useState<string>('');

  const handleSubmit = () => {
    if (inputEl) {
      if (checkStringIsURL(inputEl)) {
        const newArray = inputElRef;
        newArray.push(inputEl);
        setInputElRef(newArray);
        setInputEl('');
        setError('');
      } else {
        setError('Вставьте ссылку http(s).example.com');
      }
    } else {
      setError('Прикрепите ссылку');
    }
  };
  const removeItemInputElRef = (index: number) => {
    setInputElRef(inputElRef.filter((el, i) => i !== index));
  };
  const setInputTimeFunc = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => setInputTime(value);

  const setInputRewardFunc = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => setInputReward(value);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (inputElRef.length !== 0 && inputReward && inputTime) {
      submitTrialWorkData(
        {
          results: inputElRef,
          time_performance: +inputTime,
          complexity: inputReward,
        },
        vacancyID as string,
      );
    } else if (inputElRef.length === 0) {
      setError('Прикрепите ссылку');
    }
  };
  return (
    <>
      <Styled.ReportBlock>
        <Styled.TitleWrap>
          <Styled.Text>Если вы закончили с тестовым заданием</Styled.Text>
          <Styled.Title>Прикрепите ссылку на вашу работу</Styled.Title>
        </Styled.TitleWrap>
        <Styled.Form action="#" method="POST">
          <Styled.AddLinkBlock>
            <Styled.Label htmlFor="addLinkField">
              Ссылка на документ (Google Docs, Яндекс Диск)
            </Styled.Label>
            <Styled.LinkInputWrap>
              <Styled.LinkInput
                placeholder="Ваша ссылка"
                value={inputEl}
                onChange={e => setInputEl(e.target.value)}
                type="text"
                id="addLinkField"
              />
              {error && <span className="error">{error}</span>}
            </Styled.LinkInputWrap>
            <Styled.AttentionText>
              Перед отправкой задания, откройте доступ к документу для просмотра
            </Styled.AttentionText>
            <Styled.LinkWrap>
              <StyledLink
                text="Добавить ссылку"
                onClick={() => handleSubmit()}
                button
                type="button"
              ></StyledLink>
            </Styled.LinkWrap>
            <Styled.LinksOutput>
              {inputElRef.map((itemLink, indexLink) => (
                <div className="wrapper" key={itemLink}>
                  <a
                    className="link"
                    href={itemLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {itemLink}
                  </a>
                  <div>
                    <button
                      onClick={() => removeItemInputElRef(indexLink)}
                      type="button"
                      className="delete-button"
                    >
                      удалить ссылку
                    </button>
                  </div>
                </div>
              ))}
            </Styled.LinksOutput>
          </Styled.AddLinkBlock>
          <Styled.RangesWrapper>
            <Styled.RangeWrap>
              <Styled.RangeLabel htmlFor="ratingValue1">
                Сложность задания
              </Styled.RangeLabel>
              <Styled.RangeWrapper>
                <CustomSlider
                  id="ratingValue1"
                  onChange={setInputRewardFunc}
                  min={1}
                  max={10}
                  value={inputReward}
                  step={1}
                  aria-label="сложность задания"
                  valueLabelDisplay="on"
                  defaultValue={1}
                  marks
                />
              </Styled.RangeWrapper>
            </Styled.RangeWrap>
            <Styled.RangeWrap>
              <Styled.RangeLabel htmlFor="ratingValue2">
                Время на реализацию (в часах)
              </Styled.RangeLabel>
              <Styled.RangeWrapper>
                <CustomSlider
                  id="ratingValue2"
                  onChange={setInputTimeFunc}
                  value={inputTime}
                  min={1}
                  max={20}
                  step={1}
                  aria-label="количество часов"
                  valueLabelDisplay="on"
                  defaultValue={1}
                  marks
                />
              </Styled.RangeWrapper>
            </Styled.RangeWrap>
          </Styled.RangesWrapper>
          <Styled.SubmitLink>
            <StyledLink
              text="Отправить результат"
              button
              onClick={e => handleFormSubmit(e)}
              type="submit"
            ></StyledLink>
          </Styled.SubmitLink>
        </Styled.Form>
      </Styled.ReportBlock>
      {/* МОДАЛЬНОЕ ОКНО + OVERLAY */}
      {/* <StyledModal icon={tickIcon}>
        <div className="text-wrap">
          <h2 className="title">отправлено</h2>
          <p className="text">Вы отправили нам комментарий</p>
        </div>
      </StyledModal> */}

      {/* <StyledModal icon={tickIcon}>
        <div className="text-wrap">
          <h2 className="title">Ваша дата учтена</h2>
          <p className="text">Ждем ваше тестовое задание!</p> }

*/}
    </>
  );
};

export default TrialWorkReport;
