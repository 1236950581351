import styled, { keyframes } from 'styled-components';
import bar from '../../../assets/img/bar.svg';
import progress from '../../../assets/img/progress.svg';

const play = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const BtnsWrapper = styled.div`
  max-width: 265px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const Audio = styled.div`
  &.disabled button[aria-label='Play'] {
    pointer-events: none;
    opacity: 0.7;
  }
  .rhap_container {
    background-color: var(--secondary-block-bg);
  }

  .rhap_time {
    color: var(--main-text-color);
  }

  button[aria-label='Play'] svg path,
  button[aria-label='Pause'] svg path {
    fill: var(--main-accent-color);
  }

  .rhap_progress-indicator {
    background-color: var(--main-accent-color);
  }

  .rhap_progress-bar {
    background-color: ar(--ghost-text-color);
  }

  .rhap_progress-bar-show-download {
    background-color: var(--main-text-color);
  }

  .rhap_progress-filled {
    background-color: var(--gray-text-color);
  }

  @media (min-width: 576px) {
    .rhap_container {
      width: 400px !important;
    }
  }
`;

export const Player = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 260px;

  audio {
    margin-right: 20px;
  }

  @media (min-width: 576px) {
    width: 400px;
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;

  @media (min-width: 576px) {
    right: 17px;
  }
`;

export const AudioDemo = styled.div`
  width: 100%;
  background-color: var(--secondary-block-bg);
  height: 65px;
  display: flex;
  border-radius: 20px;
  padding: 24px 20px;
  padding-right: 80px;
  margin-bottom: 40px;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    padding: 24px 30px;
    padding-right: 100px;
  }

  .status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--main-accent-color);
    margin-right: 25px;

    &.active {
      background-color: var(--error-text-color);
    }
  }

  .bar {
    flex-grow: 1;
    position: relative;
  }

  .bar-line {
    height: 2px;
    background-image: url(${bar});
    background-repeat: space;
    background-size: 4px 2px;
  }

  .bar-line ~ div {
    position: absolute;
    right: 0;
    top: -50%;
    transform: translateY(calc(-50% + 2px));
    z-index: 1;
    width: 100%;
  }
`;

export const NextLinkWrapper = styled.div`
  width: 100%;
  margin-top: 42px;
  max-width: 220px;

  @media (max-width: 767px) {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AttentionBlock = styled.div`
  margin-bottom: 50px;
  max-width: 500px;

  .attention-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }

  .attention-text {
    margin: 0;
    line-height: 135%;
    font-size: 10px;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const Time = styled.p`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  letter-spacing: 1px;

  @media (min-width: 768px) {
    right: 30px;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
  }
`;
