import React, { useEffect, useState } from 'react';
import moment from 'moment';
import StyledLink from '../../StyledComponents/StyledLink';
import * as Styled from './StyledTrialWorkProgress';
import Timer from '../../Timer/Timer';
import { icon } from './icons';
import useTypedSelector from '../../../hooks/useTypedSelector';

interface TrialWorkProgressProps {
  stage: string;
  handleSubmit: (close?: boolean) => void;
  setShowReport: (n: boolean) => void;
}

const TrialWorkProgress: React.FunctionComponent<TrialWorkProgressProps> = ({
  stage,
  handleSubmit,
  setShowReport,
}) => {
  const { data } = useTypedSelector(r => r.testing.trialWork);
  const { currentStage } = useTypedSelector(r => r.automatic);
  const [finish, setFinish] = useState(false);

  const stopTime = moment(currentStage.date, 'DD.MM.YYYY HH:mm:ss')
    .add(data.time_limit, 'm')
    .toDate()
    .getTime();

  const handleShowReport = () => {
    setShowReport(true);
    window.scrollTo(0, 0);
  };

  const taskTimeInSeconds = data.time_limit * 60;

  return (
    <>
      {stage === 'START' && (
        <Styled.AttentionBlock>
          <span className="icon">{icon}</span>
          <div className="text-wrapper">
            {icon}
            <h3 className="title">Внимание</h3>
            <p className="text">
              После выполнения задания с вами свяжется ваш будущий руководитель
              и разберет ваше задание
            </p>
          </div>
          <Styled.LinkWrapper>
            <StyledLink
              onClick={() => handleSubmit()}
              text="Приступить к выполнению"
              button
            ></StyledLink>
          </Styled.LinkWrapper>
        </Styled.AttentionBlock>
      )}
      {stage === 'GET' && (
        <Styled.AttentionStartBlock className={finish ? 'error' : ''}>
          <div className="text-wrapper">
            <h3 className="title">Внимание</h3>
            <p className="text">
              {finish
                ? 'Время вышло. Если вы все же выполнили задание, мы готовы рассмотреть ваш результат'
                : 'Время на выполнение тестового задания ограничено. Постарайтесь прикрепить отчет раньше истечения времени.'}
            </p>
          </div>
          <div className="time-block-wrap">
            <Timer
              finishTime={stopTime}
              finishHandler={setFinish}
              taskTime={taskTimeInSeconds}
            />
          </div>
          <Styled.ReportLinkWrapper>
            <StyledLink
              onClick={() => handleShowReport()}
              text="Прикрепить отчет"
              button
            ></StyledLink>
          </Styled.ReportLinkWrapper>
        </Styled.AttentionStartBlock>
      )}
    </>
  );
};

export default TrialWorkProgress;
