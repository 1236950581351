import * as React from 'react';
import { formatTime } from './functions';
import * as Styled from './StyledTestAudioQuestion';

interface TestAudioQuestionTimeProps {}

const TestAudioQuestionTime: React.FunctionComponent<TestAudioQuestionTimeProps> = () => {
  const [recordTime, setRecordTime] = React.useState(0);

  React.useEffect(() => {
    const timerFunc = setInterval(() => {
      setRecordTime((prev: number) => {
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timerFunc);
  }, [recordTime]);

  return <Styled.Time>{formatTime(recordTime)}</Styled.Time>;
};

export default React.memo(TestAudioQuestionTime);
