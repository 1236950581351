import produce from 'immer';
import { MainPageActionTypes } from '../../types/mainPage';
import { TestActionTypes } from '../../types/test';

const mainDataReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case TestActionTypes.FETCH_NEXT_STAGE: {
        draft.changeStage = new Date();
        return draft;
      }
      case MainPageActionTypes.FETCH_DICTS: {
        draft.dictionaries = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    initialState: false,
    changeStage: null,
    dictionaries: {},
  },
);
export default mainDataReducer;
