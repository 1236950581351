import React from 'react';

export const arrowDownIcon = (
  <svg
    width="17"
    height="8"
    viewBox="0 0 17 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="down"
  >
    <path
      d="M16 7L8.5 0.999999L1 7"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const arrowUpIcon = (
  <svg
    width="17"
    height="8"
    viewBox="0 0 17 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7L8.5 0.999999L1 7"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="check"
  >
    <path
      d="M2 13L6.5 17.5L18 2"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
