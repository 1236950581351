import React from 'react';
import styled from 'styled-components';
import useTypedSelector from '../../hooks/useTypedSelector';
import { IStage } from '../../types/company';

const StyledProgressComponent = styled.div`
  position: relative;
  max-width: 550px;
  width: 100%;
  padding-bottom: 50px;
`;

const StyledLinesList = styled.ul<{
  lineWidth: number | string;
}>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;

  li {
    position: relative;
    height: 1px;
    background-color: var(--main-text-color);
    width: ${props => (props.lineWidth ? `${props.lineWidth}%` : '25%')};
    flex-shrink: 0;
  }

  &.level-0 ~ ul li {
    &:nth-child(1)::before {
      display: block;
    }

    &:nth-child(1)::after {
      font-weight: 700;
    }
  }

  &.level-1 li {
    &:nth-child(1) {
      background-image: linear-gradient(
        90deg,
        var(--main-accent-color) 0%,
        var(--main-text-color) 100%
      );
    }
  }

  &.level-1 ~ ul li {
    &:nth-child(1) {
      border-color: var(--main-accent-color);
      box-shadow: 0px 4px 15px var(--main-accent-color-hover);
      background-color: var(--main-accent-color);
    }

    &:nth-child(2)::after {
      font-weight: 700;
    }

    &:nth-child(2)::before {
      display: block;
    }
  }

  &.level-2 li {
    &:nth-child(1) {
      background-color: var(--main-accent-color);
    }

    &:nth-child(2) {
      background-image: linear-gradient(
        90deg,
        var(--main-accent-color) 0%,
        var(--main-text-color) 100%
      );
    }
  }

  &.level-2 ~ ul li {
    &:nth-child(1),
    &:nth-child(2) {
      border-color: var(--main-accent-color);
      box-shadow: 0px 4px 15px var(--main-accent-color-hover);
      background-color: var(--main-accent-color);
    }

    &:nth-child(3)::after {
      font-weight: 700;
    }

    &:nth-child(3)::before {
      display: block;
    }
  }

  &.level-3 li {
    &:nth-child(1),
    &:nth-child(2) {
      background-color: var(--main-accent-color);
    }

    &:nth-child(3) {
      background-image: linear-gradient(
        90deg,
        var(--main-accent-color) 0%,
        var(--main-text-color) 100%
      );
    }
  }

  &.level-3 ~ ul li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-color: var(--main-accent-color);
      box-shadow: 0px 4px 15px var(--main-accent-color-hover);
      background-color: var(--main-accent-color);
    }

    &:nth-child(4)::before {
      display: block;
    }

    &:nth-child(4)::after {
      font-weight: 700;
    }
  }

  &.level-4 li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      background-color: var(--main-accent-color);
    }

    &:nth-child(4) {
      background-image: linear-gradient(
        90deg,
        var(--main-accent-color) 0%,
        var(--main-text-color) 100%
      );
    }
  }

  &.level-4 ~ ul li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-color: var(--main-accent-color);
      box-shadow: 0px 4px 15px var(--main-accent-color-hover);
      background-color: var(--main-accent-color);
    }

    &:nth-child(5)::before {
      display: block;
    }

    &:nth-child(5)::after {
      font-weight: 700;
    }
  }

  &.level-5 li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      background-color: var(--main-accent-color);
    }

    &:nth-child(5) {
      background-image: linear-gradient(
        90deg,
        var(--main-accent-color) 0%,
        var(--main-text-color) 100%
      );
    }
  }

  &.level-5 ~ ul li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-color: var(--main-accent-color);
      box-shadow: 0px 4px 15px var(--main-accent-color-hover);
      background-color: var(--main-accent-color);
    }

    &:nth-child(6)::before {
      display: block;
    }

    &:nth-child(6)::after {
      font-weight: 700;
    }
  }

  &.level-6 ~ ul li {
    border-color: var(--main-accent-color);
    box-shadow: 0px 4px 15px var(--main-accent-color-hover);
    background-color: var(--main-accent-color);

    &:last-child::after {
      font-weight: 700;
    }
  }

  &.level-6 li {
    background-image: none;
    background-color: var(--main-accent-color);
  }
`;

const StyledDotsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  top: 1px;
  width: 100%;

  li {
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    width: 13px;
    height: 13px;
    border: 1px solid var(--main-text-color);
    border-radius: 50%;
    background-color: var(--main-bg);

    &::before {
      content: '';
      position: absolute;
      display: none;
      width: 4px;
      height: 4px;
      background-color: var(--main-accent-color);
      border-radius: 50%;
      top: 3.5px;
      left: 3.5px;
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      left: 50%;
      top: 25px;
      transform: translateX(-50%);
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &.list-3 li {
    &:nth-child(2) {
      left: 50%;
    }

    &:nth-child(3) {
      left: 100%;
    }
  }

  &.list-4 li {
    &:nth-child(2) {
      left: 33%;
    }

    &:nth-child(3) {
      left: 67%;
    }

    &:nth-child(4) {
      left: 100%;
    }
  }

  &.list-5 li {
    &:nth-child(2) {
      left: 25%;
    }

    &:nth-child(3) {
      left: 50%;
    }

    &:nth-child(4) {
      left: 75%;
    }

    &:nth-child(5) {
      left: 100%;
    }
  }

  &.list-6 li {
    &:nth-child(2) {
      left: 20%;
    }

    &:nth-child(3) {
      left: 40%;
    }

    &:nth-child(4) {
      left: 60%;
    }

    &:nth-child(5) {
      left: 80%;
    }

    &:nth-child(6) {
      left: 100%;
    }
  }
`;

interface PropsInterface {
  stages: IStage[];
  active_stage?: string;
}

const StyledProgress: React.FC<PropsInterface> = ({ stages, active_stage }) => {
  let index;

  if (active_stage === null) {
    index = 2;
  } else {
    index = stages.findIndex((item: IStage) => {
      return item.code === active_stage;
    });
  }

  const { dictionaries } = useTypedSelector(r => r.mainData);

  return (
    <StyledProgressComponent>
      <StyledLinesList
        className={`level-${index}`}
        lineWidth={100 / (stages.length - 1)}
      >
        {stages.map((stage: IStage, i: number) => {
          if (i !== stages.length - 1) {
            return <li key={stage.code} />;
          }
          return null;
        })}
      </StyledLinesList>
      <StyledDotsList className={`list-${stages.length}`}>
        {stages.map((stage: IStage) => {
          const title = dictionaries.automaticStages
            ?.map((el: { code: string; title: string }) => {
              let output = '';
              if (el.code === stage.code) {
                output = el.title;
              }
              return output;
            })
            .join('');

          return (
            <li data-title={title} aria-label={title} key={Math.random()} />
          );
        })}
      </StyledDotsList>
    </StyledProgressComponent>
  );
};

export default StyledProgress;
