import React, { useEffect, useState } from 'react';
import { CompanyI } from '../../../types/company';
import { VacancyI } from '../../../types/vacancy';
import { arrow } from './icons';
import * as Styled from './StyledPresentationInfo';

interface PresentationInfoProps {
  company: CompanyI;
  vacancy: VacancyI;
}

const PresentationInfo: React.FunctionComponent<PresentationInfoProps> = ({
  company,
  vacancy,
}) => {
  const infoData = [
    { name: 'О компании', value: company.description },
    { name: 'Обязанности', value: vacancy.text_responsibilities },
    { name: 'Условия работы', value: vacancy.text_conditions },
    { name: 'Требования', value: vacancy.text_requirements },
  ];

  const [showDropdown, setShowDropdown] = useState<string | undefined>();

  const handleBtnClick = (item: string) => {
    setShowDropdown(prev => (prev === item ? undefined : item));
  };

  const handleHomeBtnClick = () => {
    setShowDropdown(undefined);
  };

  useEffect(() => {
    if (window.innerWidth > 992 && showDropdown) {
      window.scrollTo(0, 150);
    }
  }, [window.innerWidth, showDropdown]);

  return (
    <Styled.InfoBlock>
      <h2 className="visually-hidden">Дополнительная информация</h2>
      <ul className="info-list">
        <li className="info-item">
          <button
            className={
              showDropdown === undefined ? 'info-btn active' : 'info-btn'
            }
            type="button"
            onClick={handleHomeBtnClick}
          >
            Приветствие
            {arrow}
          </button>
        </li>
        {infoData.map((data: { name: string; value: string }) => (
          <li className="info-item" key={data.name}>
            <button
              className={
                showDropdown === data.name ? 'info-btn active' : 'info-btn'
              }
              type="button"
              onClick={() => handleBtnClick(data.name)}
            >
              {data.name}
              {arrow}
            </button>
            {showDropdown === data.name && (
              <div className="info-content">
                <div className="info-content-wrapper">
                  <h2 className="info-title">{data.name}</h2>
                  <pre className="info-text">{data.value}</pre>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </Styled.InfoBlock>
  );
};

export default PresentationInfo;
