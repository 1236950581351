import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTypedSelector from '../../hooks/useTypedSelector';
import { fetchTestsList } from '../../store/actions/test';
import Test from '../Test/Test';
import { getTestList } from './functions';
import TestTextContainer from './TestTextContainer/TestTextContainer';

interface TestTextProps {
  vacancyID: string | null;
}

const TestText: React.FunctionComponent<TestTextProps> = ({ vacancyID }) => {
  const dispatch = useDispatch();
  const { list, refresh } = useTypedSelector(r => r.test.tests);
  const [selectedTest, setSelectedTest] = useState(0);

  useEffect(() => {
    dispatch(fetchTestsList(vacancyID));
    // setTimeout(() => dispatch(fetchTestsList(vacancyID)), 500);
  }, []);

  useEffect(() => {}, [list]);

  return (
    <Test
      title="Тесты"
      list={getTestList(list)}
      selectedTest={selectedTest}
      setSelectedTest={setSelectedTest}
    >
      <TestTextContainer test={list[selectedTest]} />
    </Test>
  );
};

export default TestText;
