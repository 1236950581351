import React, { useEffect } from 'react';
import useTypedSelector from '../../hooks/useTypedSelector';
import { ListItemI, TaskStatuses } from '../../types/test';
import * as Styled from './StyledTest';
import TestContent from './TestСontent/TestContent';
import TestList from './TestList/TestList';

interface TestProps {
  title: string;
  list: ListItemI[];
  selectedTest: number;
  setSelectedTest: (val: number) => void;
}

const Test: React.FunctionComponent<TestProps> = ({
  title,
  list,
  selectedTest,
  setSelectedTest,
  children,
}) => {
  const { singleQuestion } = useTypedSelector(r => r.test.tests);

  useEffect(() => {
    if (list.length > 0) {
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        if (item.status === TaskStatuses.DONE) {
          setSelectedTest(i + 1);
        }
      }
    }
  }, [list, singleQuestion]);

  return (
    <Styled.Wrapper>
      <TestContent>{children}</TestContent>
      <TestList title={title} items={list} selectedTest={selectedTest} />
    </Styled.Wrapper>
  );
};

export default Test;
