import styled from 'styled-components';
import { Field, Form } from 'formik';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled(Form)`
  width: 100%;
  max-width: 320px;
  background-color: var(--main-block-bg);
  padding: 25px 15px;
  border-radius: 12px;
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 20px;

  @media (min-width: 768px) {
    padding: 40px 30px;
    max-width: 380px;
    box-shadow: 0px 4px 33px var(--main-accent-color-hover);
  }
`;

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.374px;

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Input = styled(Field)`
  display: block;
  font: inherit;
  padding: 11px 17px;
  border: 1px solid var(--main-text-color);
  text-shadow: none;
  border-radius: 3px;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.374px;
  background-color: transparent;
  width: 100%;
  color: var(--main-text-color);
  transition: border-color 0.5s;
  outline: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--main-block-bg) inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: var(--main-text-color) !important;
  }

  &::placeholder {
    font: inherit;
    color: var(--ghost-text-color);
    opacity: 0.5;
  }

  &:hover {
    border-color: var(--main-accent-color);
  }

  &:focus-visible {
    border-color: var(--main-accent-color);
  }

  @media (min-width: 1200px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
