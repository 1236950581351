export enum AutomaticActionTypes {
  FETCH_CURRENT_STAGE = 'FETCH_CURRENT_STAGE',
}

interface FetchCurrentStage {
  type: AutomaticActionTypes.FETCH_CURRENT_STAGE;
  payload: string;
}

export type AutomaticActions = FetchCurrentStage;
