import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const getBgColor = (args: any) => {
  let color;

  switch (!!args) {
    case args.$transparentflag:
      color = 'transparent';
      break;
    default:
      color = 'var(--main-accent-color)';
      break;
  }

  return color;
};

const getShadowColor = (args: any) => {
  let shadow;

  switch (!!args) {
    case args.$transparentflag:
      shadow = 'none;';
      break;
    default:
      shadow = '0px 4px 33px var(--main-accent-shadow);';
      break;
  }

  return shadow;
};

const getHoverShadowColor = (args: any) => {
  let shadow;

  switch (!!args) {
    case args.$transparentflag:
      shadow = 'none';
      break;
    default:
      shadow = '0px 4px 33px var(--main-accent-color-hover);';
      break;
  }

  return shadow;
};

const StyledLinkComponent = styled(Link)<{
  $transparentflag: boolean | undefined;
  $borderflag: boolean | undefined;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 14px 15px;
  border-radius: 30px;
  font-weight: ${props => (props.$transparentflag ? '400' : '700')};
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.374px;
  color: ${props =>
    props.$transparentflag
      ? 'var(--main-text-color)'
      : 'var(--dark-text-color)'};
  background-color: ${props => getBgColor(props)};
  border: ${props =>
    props.$borderflag ? '1px solid var(--main-text-color)' : 'none'};
  transition: box-shadow 0.3s ease, border 0.3s, color 0.3s;
  cursor: pointer;
  box-shadow: ${props => getShadowColor(props)};
  text-transform: uppercase;
  outline: none;

  svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
    box-shadow: ${props => getHoverShadowColor(props)};
    border-color: ${props =>
      props.$borderflag ? 'var(--main-accent-color)' : ''};
    font-weight: ${props => (props.$transparentflag ? '700' : '')};
    color: ${props =>
      props.$transparentflag
        ? 'var(--main-accent-color)'
        : 'var(--dark-text-color)'};
  }

  &:focus-visible {
    background-color: var(--main-accent-color-hover);
    outline: 2px solid var(--main-accent-color);
    outline-offset: -0;
    text-decoration: none;
    box-shadow: ${props => getHoverShadowColor(props)};
    border-color: ${props =>
      props.$borderflag ? 'var(--main-accent-color)' : ''};
    font-weight: ${props => (props.$transparentflag ? '700' : '')};
    color: ${props =>
      props.$transparentflag
        ? 'var(--main-accent-color)'
        : 'var(--dark-text-color)'};
  }

  &:active {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  @media (min-width: 992px) {
    padding: 19px 15px 17px 15px;
    font-size: 15px;
  }
`;

const StyledAnchorComponent = styled.a<{
  $transparentflag: boolean | undefined;
  $borderflag: boolean | undefined;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 14px 15px;
  border-radius: 30px;
  font-weight: ${props => (props.$transparentflag ? '400' : '700')};
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.374px;
  color: ${props =>
    props.$transparentflag
      ? 'var(--main-text-color)'
      : 'var(--dark-text-color)'};
  background-color: ${props => getBgColor(props)};
  border: ${props =>
    props.$borderflag ? '1px solid var(--main-text-color)' : 'none'};
  transition: box-shadow 0.3s ease, border 0.3s, color 0.3s;
  cursor: pointer;
  box-shadow: ${props => getShadowColor(props)};
  text-transform: uppercase;
  outline: none;

  svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
    box-shadow: ${props => getHoverShadowColor(props)};
    border-color: ${props =>
      props.$borderflag ? 'var(--main-accent-color)' : ''};
    font-weight: ${props => (props.$transparentflag ? '700' : '')};
    color: ${props =>
      props.$transparentflag
        ? 'var(--main-accent-color)'
        : 'var(--dark-text-color)'};
  }

  &:focus-visible {
    background-color: var(--main-accent-color-hover);
    outline: 2px solid var(--main-accent-color);
    outline-offset: -0;
    text-decoration: none;
    box-shadow: ${props => getHoverShadowColor(props)};
    border-color: ${props =>
      props.$borderflag ? 'var(--main-accent-color)' : ''};
    font-weight: ${props => (props.$transparentflag ? '700' : '')};
    color: ${props =>
      props.$transparentflag
        ? 'var(--main-accent-color)'
        : 'var(--dark-text-color)'};
  }

  &:active {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  @media (min-width: 992px) {
    padding: 19px 15px 17px 15px;
    font-size: 15px;
  }
`;

interface CustomStyles {
  height?: string | number;
  padding?: string | number;
  fontSize?: string | number;
  fontWeight?: number;
  textTransform?: any;
}

interface PropsInterface {
  text: string;
  path?: string | { pathname: string };
  additionalClass?: string;
  transparentBackground?: boolean;
  border?: boolean;
  button?: boolean;
  inlineStyles?: CustomStyles;
  handleСlick?: () => void;
  disabled?: boolean;
  type?: any;
  onClick?: (val: any) => void;
  target?: string;
  externalLink?: boolean;
  externalLinkPath?: string;
}

const StyledLink: React.FC<PropsInterface> = ({
  text,
  path,
  additionalClass,
  children,
  transparentBackground,
  border,
  button,
  inlineStyles,
  handleСlick,
  disabled,
  type,
  onClick,
  target,
  externalLink,
  externalLinkPath,
}) => {
  if (button) {
    return (
      <StyledLinkComponent
        className={additionalClass}
        $transparentflag={transparentBackground}
        $borderflag={border}
        style={inlineStyles}
        onClick={onClick}
        disabled={disabled}
        type={type}
        as="button"
      >
        {children}
        {text}
      </StyledLinkComponent>
    );
  }
  if (externalLink) {
    return (
      <StyledAnchorComponent
        className={additionalClass}
        href={externalLinkPath}
        $transparentflag={transparentBackground}
        $borderflag={border}
        style={inlineStyles}
        onClick={handleСlick}
        target="_blank"
        rel="noreferrer"
      >
        {children}
        {text}
      </StyledAnchorComponent>
    );
  }
  return (
    <StyledLinkComponent
      className={additionalClass}
      to={path}
      $transparentflag={transparentBackground}
      $borderflag={border}
      style={inlineStyles}
      onClick={handleСlick}
      target={target}
    >
      {children}
      {text}
    </StyledLinkComponent>
  );
};

export default StyledLink;
