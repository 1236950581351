import React from 'react';
import { declOfNum } from '../../helpers/helpersFunc';
import useTypedSelector from '../../hooks/useTypedSelector';
import { IStage } from '../../types/company';
import { switchIcon } from './functions';

import * as Styled from './StyledStageList';

interface StageListProps {
  stages: IStage[];
}

const StageList: React.FunctionComponent<StageListProps> = ({ stages }) => {
  const { dictionaries } = useTypedSelector(r => r.mainData);

  return (
    <section>
      <h2 className="visually-hidden">Этапы</h2>
      <Styled.StepsTitle>
        Всего нужно пройти {stages.length}{' '}
        {declOfNum(stages.length, ['этап', 'этапа', 'этапов'])} отбора
      </Styled.StepsTitle>
      <Styled.StepsBlock>
        {stages.map((stage: IStage, i: number) => {
          return (
            <Styled.StepsItem
              className={i === 0 ? 'done' : ''}
              data-number={`0${i + 1}`}
              key={stage.code}
            >
              <span className="status-text">Выполнено</span>
              <div className="icon">
                <div className="inner">{switchIcon(stage.code)}</div>
              </div>
              <h5 className="title">
                {dictionaries &&
                  dictionaries.automaticStages?.map(
                    (el: { code: string; title: string }) => {
                      return el.code === stage.code ? el.title : '';
                    },
                  )}
              </h5>
            </Styled.StepsItem>
          );
        })}
      </Styled.StepsBlock>
    </section>
  );
};

export default StageList;
