import { useState } from 'react';
import axios from 'axios';
import { AppDispatch } from '../index';
import { config, url } from './actionsAssets';
import { IParams, TestActionTypes, TestsI } from '../../types/test';
import { ID } from '../../types/recordingTest';
import { fetchCurrentStage } from './automatic';

export const setQuestionData = (data: TestsI) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: TestActionTypes.SET_TESTING_DATA, payload: data });
  };
};

export const fetchTestsList = (id: IParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}testing/${id}/`, config);
      dispatch({
        type: TestActionTypes.FETCH_TESTS_LIST,
        payload: res.data,
      });
      dispatch(fetchCurrentStage(id));
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchTestsStage = (id: string | number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}automatic/${id}/stage/`, config);
      dispatch({
        type: TestActionTypes.FETCH_TESTS_STAGE,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchTestsStageList = (id: string | number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}vacancy/${id}/autostage/`, config);
      dispatch({
        type: TestActionTypes.FETCH_TESTS_STAGE_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchNextStageHandle = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: TestActionTypes.FETCH_NEXT_STAGE,
    });
  };
};
export const fetchTestQuestion = (id: IParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}testing/${id}/question/`, config);
      dispatch({
        type: TestActionTypes.FETCH_TESTS_QUESTION,
        payload: res.data,
      });
    } catch (e) {
      dispatch(fetchTestsList(id));
      console.log(e);
    }
  };
};

export const setAnswersId = (
  id: IParams,
  checked: string | number | string[],
  testIsFinish: boolean,
) => {
  return async (dispatch: AppDispatch) => {
    const res = await axios
      .post(`${url}testing/${id}/answer/`, { response: checked }, config)
      .then(r => {
        if (!testIsFinish) {
          dispatch(fetchTestQuestion(id));
        }
      });
  };
};
export const stopTests = (id: string | number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(`${url}testing/${id}/stop/`, {}, config);
    } catch (e) {
      console.log(e);
    }
  };
};
export const setTestsStart = (id: IParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(`${url}testing/${id}/start/`, {}, config);
      if (res.status === 200) {
        dispatch(setQuestionData(res.data));
        dispatch(fetchTestQuestion(id));
        dispatch(fetchTestsList(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};
export const setAuthorization = (data: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(`${url}authorize/get_jwt/`, data, config);
      localStorage.setItem('token', res.data.jwt);
      // dispatch({
      //   type: MainPageActionTypes.SET_AUTHORIZATION_TOKEN,
      //   payload: res.data,
      // });
    } catch (e) {
      // dispatch({
      //   type: MainPageActionTypes.SET_NOT_AUTHORIZATION,
      //   payload: false,
      // });
    }
  };
};
export const fetchVacancy = (id: ID) => {
  return axios.get(`${url}vacancy/${id}/`, config);
};
