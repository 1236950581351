import React from 'react';
import { useHistory } from 'react-router';
import * as Styled from './StyledError';

interface ErrorProps {
  text?: string;
}

const Error: React.FC<ErrorProps> = ({ text }) => {
  const history = useHistory();

  const goBack = () => {
    history.go(-1);
  };

  return (
    <div className="container">
      <Styled.TitleWrapper>
        <h2>Страница не найдена!</h2>
        <button className="back-button" type="button" onClick={goBack}>
          Вернуться назад
        </button>
      </Styled.TitleWrapper>
    </div>
  );
};

export default Error;
