import styled from 'styled-components';

export const VacancyInfo = styled.section`
  padding: 21px 24px 31px 24px;
  background-color: var(--main-block-bg);
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 25px 25px 30px;
    border-radius: 30px;
  }

  .wrap {
    margin-bottom: 20px;
  }

  .name {
    margin-bottom: 2px;
    font-size: 13px;
    line-height: 21px;
  }

  .title {
    font-weight: 700;
    font-size: 13px;
    line-height: 21px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 15px;
    }
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
`;
