import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import geo from '../../../assets/images/geo.svg';
import { config, url } from '../../../store/actions/actionsAssets';

interface Vacancy {
  id: number;
  name: string;
  city: string;
  salary_from: string;
  salary_to: string;
  type_location: string;
}

const Vacancies: React.FC<{ main?: boolean }> = ({ main }) => {
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const selector = main ? 'limit=4' : '';
    axios
      .get(`${url}vacancy/list/?status=Y&${selector}`, config)
      .then(r => {
        setVacancies(r.data.result);
      })
      .then(() => {
        setLoaded(true);
      });
  }, []);

  return (
    <section className="vacancies">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="vacancies-title">Вакансии</div>
          </div>

          {loaded ? (
            <div className="col-lg-12">
              {vacancies.map.length < 1 ? (
                <div>Вакансий нет</div>
              ) : (
                vacancies.map((vacancy: Vacancy) => (
                  <NavLink
                    className="vacancies-block"
                    to={`/vacancies/${vacancy.id}`}
                    key={vacancy.id}
                  >
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="vacancies-block__details">
                          <div className="vacancies-block__name">
                            {vacancy.name}
                          </div>
                          <div className="vacancies-block__type">
                            Тип вакансии:<span>{vacancy.type_location}</span>
                          </div>

                          <div className="vacancies-block__place">
                            <div className="vacancies-block__link">
                              <img src={geo} alt="#" />
                              {vacancy.city}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="vacancies-amount">
                          от{' '}
                          <span className="number-bottom">
                            {vacancy.salary_from}{' '}
                          </span>
                          до{' '}
                          <span className="number-top">
                            {vacancy.salary_to}{' '}
                          </span>
                          тыс.
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))
              )}
            </div>
          ) : (
            <div className="col-lg-12">Загрузка</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Vacancies;
