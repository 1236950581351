import * as RecordingTestCreators from './recordingTest';
import * as TestCreators from './test';
import * as TrialWorkCreators from './trialWork';
import * as AuthActionCreators from './auth';
import * as InterviewCreators from './interview';
import * as AutomaticCreators from './automatic';
import * as MainPageCreators from './mainPage';

export default {
  ...MainPageCreators,
  ...RecordingTestCreators,
  ...TestCreators,
  ...TrialWorkCreators,
  ...AuthActionCreators,
  ...InterviewCreators,
  ...AutomaticCreators,
};
