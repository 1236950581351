import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 30px;

  svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .subtitle {
    font-weight: 700;
    margin: 0;
    margin-bottom: 15px;
  }

  .text {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    display: grid;
    align-items: center;
    grid-template-columns: 155px 1fr;
    gap: 35px;

    svg {
      margin: 0;
    }
  }
`;

export const LinkWrap = styled.div`
  margin: 0 auto;
  max-width: 260px;

  @media (min-width: 992px) {
    margin: 0;
    margin-left: calc(155px + 35px);
  }
`;
