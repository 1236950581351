import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';
import { fetchRecordingTests } from '../../store/actions/recordingTest';
import Test from '../Test/Test';
import { getTestList } from './functions';
import TestAudioVideoContainer from './TestAudioVideoContainer/TestAudioVideoContainer';

interface TestAudioVideoProps {
  vacancyID: string | null;
}

const TestAudioVideo: React.FunctionComponent<TestAudioVideoProps> = ({
  vacancyID,
}) => {
  const dispatch = useDispatch();
  const { tests } = useTypedSelector(r => r.recording.recording);
  const [selectedTest, setSelectedTest] = useState(0);
  const { fetchCurrentStage } = useActions();

  useEffect(() => {
    fetchCurrentStage(vacancyID as string);
  }, [tests]);

  useEffect(() => {
    dispatch(fetchRecordingTests(vacancyID as string));
  }, []);

  return (
    <Test
      title="Медиа"
      list={getTestList(tests)}
      selectedTest={selectedTest}
      setSelectedTest={setSelectedTest}
    >
      <TestAudioVideoContainer test={tests[selectedTest]} />
    </Test>
  );
};

export default TestAudioVideo;
