import styled from 'styled-components';

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    position: relative;
    display: grid;
    grid-template-columns: 35% 1fr;
    background-color: var(--main-block-bg);
    border-radius: 20px;
    overflow: hidden;

    & > div:nth-of-type(1) {
      padding-left: 7%;
    }
  }

  @media (min-width: 992px) {
    border-radius: 30px;
  }
`;

export const StyledBtnsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  max-width: 380px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
    grid-column-gap: 36px;
  }
`;
