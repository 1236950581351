import axios from 'axios';
import { config, url } from './actionsAssets';
import {
  ID,
  IString,
  RecordingTestActionTypes,
} from '../../types/recordingTest';

import { AppDispatch } from '../index';
import { getBase64 } from '../../helpers/helpersFunc';

export const fetchRecordingTests = (id: ID) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}avtesting/${id}/`, config);
      dispatch({
        type: RecordingTestActionTypes.FETCH_RECORDING_TESTS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchRecordingTestQuestion = (id: ID, avId: ID) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(
        `${url}avtesting/${id}/question/${avId}/`,
        config,
      );
      if (res.data === null) {
        await axios.get(`${url}avtesting/cron/`, config);
      }
      dispatch({
        type: RecordingTestActionTypes.FETCH_RECORDING_QUESTION,
        payload: res.data,
      });
    } catch (e) {
      dispatch(fetchRecordingTests(id));
      console.log(e);
    }
  };
};
export const setRecordingTestsStart = (id: ID, avId: ID) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(
        `${url}avtesting/${id}/start/${avId}/`,
        {},
        config,
      );
      dispatch(fetchRecordingTestQuestion(id, avId));
      dispatch(fetchRecordingTests(id));
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendMediaFile = (
  blobUrl: string,
  type: 'audio' | 'video',
  vacancyId: ID,
  testId: ID,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const fileName = `${Math.random()
        .toString(36)
        .substring(6)}${type === 'audio' ? '_name.mp3' : '_name.mp4'}`;
      const file = await fetch(blobUrl).then(r => r.blob());
      const fileInBase64 = await getBase64(file);
      const resSend = await axios.post(
        `${url}avtesting/${vacancyId}/answer/${testId}/`,
        {
          answer: {
            name: fileName,
            body: fileInBase64,
          },
        },
        config,
      );
      if (setLoading) setLoading(false);
      dispatch(fetchRecordingTestQuestion(vacancyId, testId));
    } catch (e) {
      dispatch(fetchRecordingTests(vacancyId));
    }
  };
};
