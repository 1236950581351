import React from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledModalComponent = styled.section<{
  $error: boolean | undefined;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--main-text-color);
  width: calc(100% - 32px);
  max-width: 400px;
  background-color: var(--main-block-bg);
  padding: 23px 16px 28px 16px;
  z-index: 2;
  border-radius: 30px;
  box-shadow: ${props =>
    props.$error
      ? '0px 0px 30px var(--error-text-color)'
      : '0px 0px 30px var(--main-accent-shadow)'};

  @media (min-width: 375px) {
    padding: 23px 30px 28px 30px;
  }

  @media (min-width: 768px) {
    min-height: 220px;
    padding-left: 154px;
    padding-top: 50px;
    padding-bottom: 52px;
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 500px;
  }

  .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.374px;
    margin-bottom: 6px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 155.8%;
      margin-bottom: 10px;
    }
  }

  .text {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.374px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 155.8%;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  .close {
    display: block;
    width: 25px;
    height: 25px;
    top: 17px;
    right: 17px;
    position: absolute;
    font: inherit;
    background-color: transparent;
    text-shadow: none;
    padding: 0;
    border: 0;
    color: var(--main-text-color);

    @media (min-width: 768px) {
      top: 23px;
      right: 23px;
    }
  }

  .text-wrap {
    position: relative;
    padding-left: 60px;
    padding-left: 25%;

    @media (min-width: 768px) {
      padding-left: 0;
    }
  }

  & > svg {
    display: block;
    position: absolute;
    width: 49px;
    height: 49px;
    top: 30px;
    left: 16px;
    border-radius: 50%;
    color: ${props =>
      props.$error ? 'var(--error-text-color)' : 'var(--main-accent-color)'};

    @media (min-width: 375px) {
      left: 30px;
    }

    @media (min-width: 576px) {
      width: 70px;
      height: 70px;
      top: 20px;
    }

    @media (min-width: 768px) {
      top: 50px;
      left: 20px;
      width: 120px;
      height: 120px;
    }
  }
`;

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L13 13M13 1L1 13"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface CustomStyles {
  maxWidth?: string | number;
}

interface PropsInterface {
  icon?: any;
  inlineStyles?: CustomStyles;
  setOpened?: (e: boolean) => void;
  isErrorModal?: boolean;
}

const StyledModal: React.FC<PropsInterface> = ({
  icon,
  inlineStyles,
  children,
  setOpened,
  isErrorModal,
}) => {
  const handleOverlayClick = () => {
    if (setOpened) {
      setOpened(false);
    }
  };

  return (
    <>
      <StyledModalComponent style={inlineStyles} $error={isErrorModal}>
        {icon && icon}
        {children}
        {setOpened && (
          <button
            className="close"
            type="button"
            onClick={() => setOpened(false)}
          >
            {closeIcon}
            <span className="visually-hidden">Закрыть</span>
          </button>
        )}
      </StyledModalComponent>
      <StyledOverlay onClick={handleOverlayClick} />
    </>
  );
};

export default StyledModal;
