import { IParams, TaskStatuses } from './test';

export type ID = number | string;

export interface IQuestion {
  answer: string;
  avtesting_id: ID;
  description: string;
  id: ID;
  name: string;
  question_count: ID;
  question_number: ID;
  sort: ID;
  time_record: number;
  type: string;
  date_start: string;
}
export interface IVideoT {
  avtesting_id: number | string;
  avtesting_name: string;
  count_questions: ID;
  status: TaskStatuses;
  vacancy_id: ID;
  vacancy_name: string;
}
export interface IRecProps {
  testId: ID;
  recordStatus: boolean | undefined;
  setRecordStatus: (val: boolean) => void;
  vacancyID: IParams;
}
export interface IString {
  [key: string]: string;
}

export enum RecordingTestActionTypes {
  FETCH_RECORDING_TESTS = 'FETCH_RECORDING_TESTS',
  FETCH_RECORDING_QUESTION = 'FETCH_RECORDING_QUESTION',
}
