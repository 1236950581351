import styled from 'styled-components';

// const growDown = keyframes`
//   0% {
//     transform: scaleY(0)
//   }

//   80% {
//     transform: scaleY(1.1)
//   }

//   100% {
//     transform: scaleY(1)
//   }
// `;

export const InfoBlock = styled.section`
  .info-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-rows: min-content;
    gap: 10px;
  }

  .info-item {
    background-color: var(--main-block-bg);
    border-radius: 20px;
    padding: 15px 25px;

    &:first-of-type {
      display: none;
    }
  }

  .info-content {
    margin-top: 6px;
    padding-top: 15px;
    border-top: 1px solid var(--main-text-color);
  }

  .info-content p {
    font-size: 12px;
    line-height: 17px;

    &:last-of-type {
      margin: 0;
    }
  }

  .info-title {
    display: none;
    margin: 0;
    margin-bottom: 30px;
    font-weight: 550;
    font-size: 35px;
    line-height: 52px;
  }

  .info-text {
    color: white;
    white-space: pre-line;
    font-family: 'Geometria', 'Arial', sans-serif;
  }

  .info-btn {
    position: relative;
    width: 100%;
    font: inherit;
    font-size: 17px;
    line-height: 28px;
    color: var(--main-text-color);
    display: block;
    padding: 0;
    border: none;
    background-color: transparent;
    text-align: left;
    outline: none;
    transition: color 0.2s;

    svg {
      position: absolute;
      right: 0;
      top: 3px;
      transition: transform 0.3s;
    }

    &:hover,
    &.active {
      color: var(--main-accent-color);

      svg {
        transform: rotate(90deg);
      }
    }

    &:focus-visible {
      color: var(--main-accent-color);

      svg {
        transform: rotate(90deg);
      }
    }
  }

  @media (min-width: 992px) {
    .info-item {
      &:first-of-type {
        display: block;
      }
    }

    .info-content {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      position: absolute;
      padding-top: 0;
      margin: 0;
      border-top: none;
      right: 0;
      top: 0;
      z-index: 4;
    }

    .info-content-wrapper {
      height: 750px;
      overflow-y: auto;
      padding: 30px 24px;
      background-color: var(--main-block-bg);
      border-radius: 30px;
    }

    .info-title {
      display: block;
    }

    .info-text {
      font-weight: 500;
      font-size: 17px;
      line-height: 155%;
    }

    .info-content p {
      font-weight: 500;
      font-size: 17px;
      line-height: 155%;
    }

    .info-btn {
      &:hover,
      &.active {
        svg {
          transform: rotate(0);
        }
        
      &:focus-visible {
        svg {
          transform: rotate(0);
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .info-content-wrapper {
      height: 740px;
      padding: 45px 35px;
    }
  }
`;
