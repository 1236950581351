import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useActions } from './hooks/useActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/base.scss';
import { CompanyI } from './components/Additional/MainPage/MainPage';
import Vacancies from './components/Additional/Vacancies/Vacancies';
import SingleVacancy from './components/Additional/SingleVacancy/SingleVacancy';
// import Interview from './components/Interview/Interview/Interview';
import Login from './pages/Login/Login';
import Error from './pages/Error/Error';
import Passing from './pages/Passing/Passing';

const App = () => {
  const { pathname } = useLocation();
  const { fetchDicts } = useActions();
  const [company, setCompany] = useState<CompanyI>({
    address: '',
    city: '',
    color: 0,
    description: '',
    email: '',
    id: 0,
    image: 0,
    name: '',
    phone: '',
    timezone: '',
    type: '1',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    fetchDicts();
  }, []);

  return (
    <>
      <Switch>
        <Route path="/passing/:id">
          <Passing />
        </Route>
        <Route path="/login/" exact>
          <Login />
        </Route>
        <Route path="/vacancies/:id" exact>
          <SingleVacancy company={company} />
        </Route>
        <Route path="/vacancies/" exact>
          <Vacancies />
        </Route>
        <Route path="/" exact>
          <Error />
        </Route>
        <Route>
          <Error />
        </Route>
      </Switch>
    </>
  );
};

export default App;
