import styled from 'styled-components';

export const FinishBlock = styled.section`
  background-color: var(--main-block-bg);
  padding: 27px 15px 44px 15px;
  border-radius: 30px;

  @media (min-width: 768px) {
    padding: 56px 63px 75px 63px;
  }
`;

export const Col1 = styled.div`
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 0;
    max-width: 476px;
  }
`;

export const Name = styled.h2`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 18px;
  }
`;

export const Text = styled.p`
  max-width: 412px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 28px;
  }
`;

export const AttentionBlock = styled.div<{
  $offline?: boolean | undefined;
}>`
  position: relative;
  border: 1px solid var(--main-text-color);
  border-radius: 20px;
  padding: 18px;
  padding-bottom: 27px;
  padding-left: 85px;

  svg {
    position: absolute;
    display: block;
    width: 49px;
    height: 49px;
    top: 32px;
    left: 19px;
    border-radius: 50%;
    color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    padding: 33px 36px 40px 154px;

    svg {
      width: 78px;
      height: 78px;
      top: 33px;
      left: 36px;
    }
  }

  .title {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.374px;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 155.8%;
    }
  }

  .text {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.374px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 155.8%;
    }
  }
`;

export const FormTitle = styled.h3`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;
  font-weight: 400;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Fieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: 0;
  margin-bottom: 30px;

  legend {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: 0.374px;
    margin: 0;
    margin-bottom: 13px;
    text-transform: uppercase;
    padding-left: 25px;

    svg {
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      top: -10px;
      left: -10px;
      color: var(--main-accent-color);
    }

    @media (min-width: 768px) {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      margin-bottom: 26px;
    }
  }
`;

export const TextLink = styled.a`
  display: block;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.374px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: var(--main-accent-color);
  margin-bottom: 24px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--main-accent-color);
  }

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const RadioWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 14px;
  justify-items: center;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 23px;
  }
`;

export const Radio = styled.div`
  width: 100%;

  label {
    width: 100%;
    margin: 0;
    dosplay: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.374px;
    color: var(--main-text-color);
    padding: 9px 15px 5px 15px;
    border: 1px solid var(--main-text-color);
    border-radius: 3px;
    transition: background-color 0.5s;
    cursor: pointer;
  }

  input:checked ~ label {
    background-color: var(--main-text-color);
    color: var(--dark-text-color);
  }

  @media (min-width: 768px) {
    label {
      font-size: 15px;
      line-height: 22px;
      padding-bottom: 9px;
    }
  }
`;

export const LinkWrap = styled.div`
  max-width: 265px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
`;
