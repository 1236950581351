import produce from 'immer';
import { AuthActionTypes } from '../../types/auth';

const authReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case AuthActionTypes.FETCH_USER_PROFILE: {
        draft.profile = action.payload;
        return draft;
      }
      case AuthActionTypes.SET_AUTHORIZATION_TOKEN: {
        draft.auth.token = action.payload;
        if (action.payload !== '') {
          draft.auth.token = action.payload;
          draft.auth.isAuth = true;
        } else {
          draft.auth.token = '';
          draft.auth.isAuth = false;
        }
        return draft;
      }
      case AuthActionTypes.SET_IS_AUTHORIZATION: {
        draft.auth.isAuth = action.payload;
        return draft;
      }
      case AuthActionTypes.ERROR_AUTHORIZATION: {
        draft.authError = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    auth: {
      error: '',
      token: null,
      isAuth: null,
    },
    authError: {
      type: null,
      message: null,
    },
    profile: {
      id: null,
      name: '',
      last_name: '',
    },
  },
);

export default authReducer;
