import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import * as Styled from './StyledPresentationVideo';
import { playIcon, restartIcon } from './icons';
import poster from '../../../assets/images/poster.png';

interface PresentationVideoProps {
  src: string;
}

const PresentationVideo: React.FunctionComponent<PresentationVideoProps> = ({
  src,
}) => {
  const [start, setStart] = useState(false);
  const [restart, setRestart] = useState(false);
  const videoRef = useRef<any>(null);

  const btn = (
    <button
      className="play-btn play-btn-start"
      type="button"
      aria-label="Включить"
    >
      {restart ? restartIcon : playIcon}
    </button>
  );

  const startVideo = () => {
    setStart(true);
  };

  const stopVideo = () => {
    setStart(false);
    setRestart(true);
    if (videoRef.current) {
      videoRef.current.showPreview();
    }
  };

  return (
    <Styled.VideoWrapper>
      <div className="video-container">
        <ReactPlayer
          url={`${process.env.REACT_APP_API_URL}${src}`}
          controls
          light={poster}
          playIcon={btn}
          playing={start}
          onClickPreview={startVideo}
          onEnded={stopVideo}
          ref={videoRef}
          width={240}
          height={140}
        />
      </div>

      <p className={start ? 'shadow-text d-md-none' : 'shadow-text'}>
        Обращение генерального директора Atlassys Technology
      </p>
    </Styled.VideoWrapper>
  );
};

export default PresentationVideo;
