import styled from 'styled-components';

export const QuestionTitle = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.374px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const QuestionDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.374px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Time = styled.div`
  color: #ffe500;

  .rest-time {
    display: none;

    @media (min-width: 768px) {
      display: block;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.374px;
      margin-bottom: 9px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .inner {
    font-size: 20px;
    line-height: 29px;

    @media (min-width: 768px) {
      width: 168px;
      padding: 8px 20px;
      box-shadow: 0px 4px 21px rgba(255, 229, 0, 0.5);
      border-radius: 3px;
      font-size: 35px;
      line-height: 45px;
      text-align: center;
      letter-spacing: 2.37px;
      border-radius: 3px;
    }
  }

  .labels {
    display: none;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 8px;
      line-height: 20px;
      letter-spacing: 0.374px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
    }
  }
`;

export const Question = styled.div`
  .image {
    display: block;
    width: 100%;
    height: 114px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      width: 100%;
      height: 245px;
      margin-bottom: 30px;
    }
  }
`;

export const FieldsWrapper = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-row-gap: 10px;
  grid-auto-rows: min-content;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  @media (min-width: 1200px) {
    grid-column-gap: 44px;
  }
`;

export const TextareaWrap = styled.div`
  max-width: 515px;
  textarea {
    height: 202px;
  }
`;

export const TaskWrapper = styled.section`
  background-color: var(--main-block-bg);
  border-radius: 20px 20px 0 0;

  @media (min-width: 768px) {
    padding-top: 30px;
    padding-left: 0;
    padding-bottom: 40px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    padding-top: 40px;
    padding-right: 48px;
  }
`;

export const MobileTitle = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.374px;
  text-transform: uppercase;
  margin: 0;
  margin-right: 15px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileTestTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 10px;
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--main-text-color);

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h2`
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: none;
    margin: 0;
    margin-right: 15px;
  }
`;

export const CurrentNumber = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;

  .current {
    font-size: 20px;
    line-height: 25px;
    color: var(--main-accent-color);
    font-weight: 700;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;

    .current {
      font-size: 22px;
      line-height: 25px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  background-color: var(--secondary-block-bg);
  border-radius: 20px 20px 0px 0px;

  @media (min-width: 768px) {
    background-color: transparent;
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--main-text-color);
    margin-bottom: 40px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 24px 30px 40px 24px;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const LinkWrapper = styled.div`
  max-width: 262px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
`;
