import axios from 'axios';
import { config, url } from './actionsAssets';
import { TRialWorkActionTypes } from '../../types/trialWork';
import { AppDispatch } from '../index';
import { fetchTestsStage } from './test';
import { fetchCurrentStage } from './automatic';

export const trialWorkStart = (id: string | null) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(`${url}trialwork/${id}/start/`, {}, config);
      dispatch({
        type: TRialWorkActionTypes.TRIAL_WORK_START,
        payload: true,
      });
      dispatch(fetchCurrentStage(id));
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchTrialWorkData = (id: string | number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get(`${url}trialwork/${id}/`, config);
      dispatch({
        type: TRialWorkActionTypes.FETCH_TRIAL_WORK_DATE,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const submitTrialWorkData = (results: any, id: string | null) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(
        `${url}trialwork/${id}/report/`,
        results,
        config,
      );
      dispatch(fetchCurrentStage(id));
    } catch (e) {
      console.log(e);
    }
  };
};
