import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 39px;

  @media (min-width: 768px) {
    margin-bottom: 77px;
  }

  @media (min-width: 992px) {
    position: relative;
  }
`;

export const NextLinkWrapper = styled.div`
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

export const TextBlock = styled.section`
  padding: 30px 24px;
  background-color: var(--main-block-bg);
  border-radius: 20px;

  @media (min-width: 768px) {
    border-radius: 30px;
  }

  @media (min-width: 992px) {
    padding: 30px 24px;

    .wrapper {
      height: 690px;
      overflow-y: auto;
    }
  }

  @media (min-width: 1200px) {
    padding: 45px 35px;

    .wrapper {
      height: 650px;
    }
  }
`;
