import axios from 'axios';
import moment from 'moment';
import { DateTime } from '../../assets/vars';
import { AppDispatch } from '../index';
import { config, url } from './actionsAssets';
import { ID, InterviewActionTypes } from '../../types/interview';
import { IParams } from '../../types/test';
import { fetchCurrentStage } from './automatic';

export const sendInterviewReg = (id: ID, vacancyID: IParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await axios.post(
        `${url}interview/${id}/registration/add/`,
        {},
        config,
      );
      dispatch(fetchCurrentStage(vacancyID));
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
};
export const fetchInterviewSlots = (vacancyID: IParams) => {
  const filterDates = {
    date_to: moment()
      .add(10, 'days')
      .format(DateTime.API_DATE),
    date_from: moment().format(DateTime.API_DATE),
  };
  return async (dispatch: AppDispatch) => {
    try {
      const result = await axios.get(
        `${url}interview/list/?vacancy_id=${vacancyID}&sortBy=date&available=Y&date_from=${filterDates.date_from}&date_to=${filterDates.date_to}`,
        config,
      );
      dispatch({
        type: InterviewActionTypes.FETCH_INTERVIEWS_SLOTS,
        payload: result.data.result,
      });
    } catch (e) {
      dispatch({
        type: InterviewActionTypes.FETCH_INTERVIEWS_SLOTS_ERROR,
        payload: { error: true },
      });
    }
  };
};
export const fetchInterviewVacancyUser = (vacancyID: IParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const result = await axios.get(
        `${url}interview/user/?vacancy_id=${vacancyID}`,
        config,
      );
      dispatch({
        type: InterviewActionTypes.FETCH_INTERVIEWS_REGISTRATION,
        payload: result.data[0],
      });
      console.log(result.data[0]);
    } catch (e) {
      console.log(e);
    }
  };
};
export const clearInterviewsSlot = () => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: InterviewActionTypes.CLEAR_INTERVIEWS_SLOTS });
  };
};
