export interface DictionariesI {
  companyColors: {
    id: number;
    color: string;
  }[];
  companyImages: number[];
  ruleList: {
    id: number;
    title: string;
  }[];
  ruleAction: {
    id: number;
    title: string;
  }[];
  ruleModule: {
    code: string;
    title: string;
  }[];
  vacancyLocation: {
    id: number;
    title: string;
  }[];
  vacancySchedule: {
    id: number;
    title: string;
  }[];
  vacancyFrequency: {
    id: number;
    title: string;
  }[];
}

export enum MainPageActionTypes {
  FETCH_DICTS = 'FETCH_DICTS',
}

interface FetchDictionaries {
  type: MainPageActionTypes.FETCH_DICTS;
  payload: {
    result: DictionariesI;
  };
}

export type MainPageActions = FetchDictionaries;
