import React from 'react';
import { Modal } from 'react-bootstrap';
import { VacancyResponseI } from './VacancyResponse';
import tv1 from '../../../assets/images/tv1.png';

const VacancyResponseSent: React.FC<VacancyResponseI> = ({ setShow }) => {
  return (
    <>
      <Modal.Header>
        <div className="modal-header__img">
          <img src={tv1} alt="#" />
        </div>
        <button className="close" type="button" onClick={() => setShow(false)}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.03127 20.0943L20.094 8.03154M20.0939 20.0942L8.03111 8.03139"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-popup__title">Отправлено</div>
        <div className="modal-popup__desc">
          Спасибо за вашу заявку! Мы свяжемся с вами по почте в ближайшее время
          и обсудим все детали.
        </div>
        <div className="modal-popup__btn">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button
            type="button"
            className="dflt-bttn"
            onClick={() => setShow(false)}
          >
            Хорошо, спасибо!
          </button>
        </div>
      </Modal.Body>
    </>
  );
};

export default VacancyResponseSent;
