import styled from 'styled-components';

export const Header = styled.header`
  padding-top: 18px;
  padding-bottom: 18px;

  @media (min-width: 576px) {
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  @media (min-width: 768px) {
    padding-top: 42px;
    margin-bottom: 50px;
  }

  @media (min-width: 992px) {
    margin-bottom: 74px;
  }

  .logo {
    border-radius: 5px;
    color: var(--main-text-color);

    &:focus-visible {
      outline: 2px solid var(--main-accent-color);
      outline-offset: 5px;
    }
  }
`;

export const Column = styled.div`
  display: none;

  @media (min-width: 576px) {
    display: block;
    margin-top: 30px;
  }

  @media (min-width: 992px) {
    margin-top: 10px;
    padding-right: 60px;
  }
`;
