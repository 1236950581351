import * as React from 'react';
import { IVideoT } from '../../../types/recordingTest';
import TestDescription from '../../Test/TestDescription/TestDescription';
import { test1, test2, test3 } from './icons';

interface TestAudioVideoDescriptionProps {
  test: IVideoT;
  startTask: (id?: number | string) => void;
}

const TestAudioVideoDescription: React.FunctionComponent<TestAudioVideoDescriptionProps> = ({
  test,
  startTask,
}) => {
  return (
    <TestDescription
      title="Медиа"
      testName={test.avtesting_name}
      testId={test.avtesting_id}
      startTask={startTask}
    >
      <div className="box">
        <div className="icon-wrap">{test1}</div>
        <p className="text">
          Внимательно ознакомьтесь с вопросом, обдумайте ответ, и приступайте к
          записи. После завершения записи — отправьте нам свой ответ
        </p>
      </div>
      <div className="box">
        <div className="icon-wrap">{test2}</div>
        <p className="text">
          После нажатия кнопки вам станет доступна запись аудио/видео. Время
          отведенное на ознакомление с вопросом и отправку ответа — ограничено
        </p>
      </div>
      <div className="box">
        <div className="icon-wrap">{test3}</div>
        <p className="text">
          Для успешного выполнения задания, обеспечьте себе удобное, тихое
          место. Проверьте, работает ли ваша камера и микрофон
        </p>
      </div>
      <div className="box">
        <div></div>
        <p className="text">
          Теперь, когда вы все знаете, <br />
          поехали :)
        </p>
      </div>
    </TestDescription>
  );
};

export default TestAudioVideoDescription;
