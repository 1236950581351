import styled from 'styled-components';

export const Title = styled.h1`
  display: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.374px;
  text-transform: uppercase;
  margin-bottom: 19px;

  @media (min-width: 768px) {
    display: block;
    font-size: 35px;
    line-height: 52px;
    padding-left: 20px;
  }
`;

export const TestNav = styled.div`
  @media (min-width: 768px) {
    background-color: var(--secondary-block-bg);
    padding-left: 16px;
    padding-top: 30px;
    padding-bottom: 16px;
  }

  @media (min-width: 992px) {
    padding-top: 40px;
  }
`;

export const TestListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-row-gap: 16px;

  @media (min-width: 768px) {
    grid-row-gap: 0;
    height: 520px;
    overflow-y: auto;
    grid-auto-rows: min-content;
  }

  @media (min-width: 992px) {
    height: 685px;
  }
`;

export const TestItem = styled.li`
  background-color: var(--main-block-bg);
  border-radius: 5.81152px;

  @media (min-width: 768px) {
    border-radius: 0;
    background-color: var(--secondary-block-bg);
  }

  .button {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 20px 18px 45px;
    background-color: transparent;
    border: 0;
    font: inherit;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.374px;
    text-transform: uppercase;
    text-align: left;
    color: var(--main-text-color);
    border-radius: 5px;
    outline: none;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(calc(-50% - 2px));
      display: block;
      width: 15px;
      height: 15px;
      border: 2px solid var(--main-text-color);
      border-radius: 2px;
    }

    .check {
      position: absolute;
      display: none;
      left: 18px;
      top: 50%;
      transform: translateY(-75%);
      width: 16px;
      height: 20px;
      color: var(--main-accent-color);
    }

    @media (min-width: 768px) {
      border-radius: 0;
    }

    @media (min-width: 992px) {
      padding-left: 65px;
      font-size: 15px;
      line-height: 22px;
      padding-top: 25px;
      padding-bottom: 25px;

      &::before {
        width: 18px;
        height: 18px;
        left: 24px;
      }

      .check {
        height: 25px;
        left: 26px;
      }
    }

    svg {
      position: absolute;
      display: block;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      width: 15px;
      height: 15px;

      &.down {
        transform: translateY(-50%) rotate(180deg);
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  &.done .check {
    display: block;
  }

  &.active .button {
    @media (min-width: 768px) {
      background-color: var(--main-block-bg);
    }
  }
`;
