import styled from 'styled-components';
import closeIcon from '../../../assets/img/close.svg';

export const ReportBlock = styled.section`
  padding: 20px 15px 27px 15px;

  @media (min-width: 768px) {
    padding: 40px;
    background-color: var(--main-block-bg);
    border-radius: 20px;
  }

  @media (min-width: 992px) {
    padding: 72px 79px 50px 48px;
    border-radius: 30px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.374px;

  @media (min-width: 768px) {
    margin-bottom: 6px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.374px;
  margin-bottom: 14px;
  text-transform: uppercase;
  text-align: left;

  @media (min-width: 768px) {
    text-align: center;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 35px;
    line-height: 52px;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.374px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const LinkInputWrap = styled.div`
  position: relative;
  margin-bottom: 3px;
  padding-bottom: 20px;

  .error {
    font-size: 12px;
    line-height: 14px;
    color: var(--error-text-color);
    position: absolute;
    left: 0px;
    bottom: 3px;
  }
`;

export const LinkInput = styled.input`
  display: block;
  font: inherit;
  padding: 11px 0;
  border: none;
  border-bottom: 1px solid var(--main-text-color);
  text-shadow: none;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.374px;
  background-color: transparent;
  width: 100%;
  color: #ffffff;
  outline: none;

  &::placeholder {
    font: inherit;
    color: var(--gray-text-color);
  }

  &:hover {
    border-color: var(--main-accent-color);
  }

  &:focus-visible {
    border-color: var(--main-accent-color);
  }

  @media (min-width: 1200px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const LinkWrap = styled.div`
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 10px;

  button {
    padding: 15px 30px;
  }
`;

export const LinksOutput = styled.div`
  max-height: 170px;
  overflow-y: auto;

  @media (min-width: 1200px) {
    max-height: 170px;
  }

  .wrapper {
    margin-bottom: 10px;
    transition: all 0.3s ease;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .delete-button {
    font-size: 10px;
    line-height: 14px;
    display: block;
    font: inherit;
    background-color: transparent;
    text-shadow: none;
    padding: 0;
    border: 0;
    color: var(--main-accent-color);
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: var(--main-accent-color-hover);
    }
  }

  .link {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.374px;
    color: var(--main-text-color);
    display: -moz-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 44px;
    margin-bottom: 8px;
    text-decoration: none;
    word-break: break-all;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--main-accent-color);
    }

    &:focus-visble {
      text-decoration: underline;
      text-decoration-color: var(--main-accent-color);
    }

    @media (min-width: 1200px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  .button {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
    text-shadow: none;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--main-text-color);
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--main-accent-color);
    }
  }
`;

export const RangeWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;
  padding-bottom: 10px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const RangeLabel = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.374px;

  @media (min-width: 1200px) {
    font-size: 17px;
    line-height: 28px;
  }
`;

export const RangeWrapper = styled.div`
  @media (min-width: 768px) {
    margin-top: auto;
  }
`;

export const RangesWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;

  @media (min-width: 1200px) {
    margin-bottom: 50px;
  }
`;

export const AddLinkBlock = styled.div`
  margin-bottom: 40px;

  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const SubmitLink = styled.div`
  max-width: 320px;
  margin: 0 auto;

  button {
    padding: 15px 30px;
  }
`;

export const Form = styled.form`
  max-width: 552px;
  margin: 0 auto;
`;

export const AttentionText = styled.p`
  margin: 0;
  margin-bottom: 15px;
  opacity: 0.6;
`;
