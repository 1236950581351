import React from 'react';

export const playIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-play-fill"
    viewBox="0 0 16 16"
  >
    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
  </svg>
);

export const restartIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 28 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 6.50001C21.3639 6.50001 27.3334 12.4695 27.3334 19.8333C27.3334 27.1971 21.3639 33.1667 14.0001 33.1667C6.63628 33.1667 0.666748 27.1971 0.666748 19.8333C0.666748 18.9129 1.41294 18.1667 2.33341 18.1667C3.25389 18.1667 4.00008 18.9129 4.00008 19.8333C4.00008 25.3562 8.47723 29.8333 14.0001 29.8333C19.5229 29.8333 24.0001 25.3562 24.0001 19.8333C24.0001 14.3105 19.5229 9.83334 14.0001 9.83334V14.993C14.0001 15.1588 13.9383 15.3187 13.8267 15.4414C13.579 15.7139 13.1574 15.7339 12.885 15.4863L5.73779 8.98883C5.7117 8.96511 5.68676 8.94017 5.66304 8.91409C5.25026 8.46002 5.28372 7.7573 5.73779 7.34452L12.885 0.847079C13.0077 0.735521 13.1676 0.673706 13.3334 0.673706C13.7016 0.673706 14.0001 0.972183 14.0001 1.34037V6.50001Z"
      fill="currentColor"
    />
  </svg>
);

export const stopIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-stop-fill"
    viewBox="0 0 16 16"
  >
    <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
  </svg>
);
