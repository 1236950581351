interface InterviewSlotsI {
  max_places: number;
}
export interface PageStateI {
  page: PageStateEnum;
  count: number;
}

export enum PageStateEnum {
  WAIT = 'wait',
  REP = 'rep',
  MAIN = 'main',
  INT = 'int',
  RES = 'res',
  REC_INT = 'REC_INT',
}

export interface TestsI {
  ATTEMPT_ID: number;
  TEST_NAME: string;
  TIME_LIMIT: number;
}

export interface TestListItemI {
  vacancy_id: string | number;
  test_id: number | string;
  name: string;
  description: string;
  status: TaskStatuses;
  avtesting_name?: string;
  time: string;
  date_start: string;
  date_end: string;
  count_questions?: number;
  time_limit?: number;
}
export interface TestsStage {
  user_id: string | number;
  vacancy_id: string | number;
  action_group: string;
  action: string;
  order: number;
  data: string;
  date_insert: string;
}

export enum QuestionTypesI {
  SINGLE = 'S',
  MULTIPLE = 'M',
  SORTING = 'R',
  TEXT = 'T',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export interface QuestionSINGLEMULTIPLE {
  answers: QuestionSINGLEMULTIPLEAnswer[];
  description: string;
  image: string;
  name: string;
  question_type: QuestionTypesI;
}

export interface QuestionSINGLEMULTIPLEAnswer {
  answer: string;
  id: number;
  checked: false;
}

export enum TaskStatuses {
  DONE = 'F',
  STARTED = 'S',
  NOT_STARTED = 'N',
  PROGRESS = 'B',
}
export type IParams = string | null;

export enum TestActionTypes {
  FETCH_TESTS_LIST = 'FETCH_TESTS_LIST',
  FETCH_TESTS_QUESTION = 'FETCH_TESTS_QUESTION',
  SET_TESTING_DATA = 'SET_TESTING_DATA',
  FETCH_TESTS_STAGE = 'FETCH_TESTS_STAGE',
  FETCH_TESTS_STAGE_LIST = 'FETCH_TESTS_STAGE_LIST',
  FETCH_NEXT_STAGE = 'FETCH_NEXT_STAGE',
  FETCH_REFRESH_TEST = 'FETCH_REFRESH_TEST',
}

interface FetchStageList {
  type: TestActionTypes.FETCH_TESTS_STAGE_LIST;
  payload: string[];
}

interface FetchTestQuestion {
  type: TestActionTypes.FETCH_TESTS_QUESTION;
  payload: {
    result: InterviewSlotsI;
  };
}

interface FetchTestsList {
  type: TestActionTypes.FETCH_TESTS_LIST;
}
interface SetTestingData {
  type: TestActionTypes.SET_TESTING_DATA;
  payload: TestsI;
}
interface FetchTestsStage {
  type: TestActionTypes.FETCH_TESTS_STAGE;
  payload: TestsStage;
}
interface FetchRefreshTestsAction {
  type: TestActionTypes.FETCH_REFRESH_TEST;
}

export interface ListItemI {
  id: number | string;
  status: TaskStatuses;
  name: string;
}
