import produce from 'immer';
import { TestActionTypes, TestListItemI } from '../../types/test';

const testsList: TestListItemI[] = [];

const testReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case TestActionTypes.FETCH_TESTS_LIST: {
        draft.tests.list = action.payload;
        return draft;
      }
      case TestActionTypes.FETCH_TESTS_QUESTION: {
        draft.tests.singleQuestion = action.payload;
        return draft;
      }
      case TestActionTypes.SET_TESTING_DATA: {
        draft.tests.data = action.payload;
        return draft;
      }
      case TestActionTypes.FETCH_TESTS_STAGE: {
        draft.tests.stage = action.payload;
        return draft;
      }
      case TestActionTypes.FETCH_TESTS_STAGE_LIST: {
        draft.tests.stageList = action.payload;
        return draft;
      }
      case TestActionTypes.FETCH_REFRESH_TEST: {
        draft.tests.refresh = new Date();
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    tests: {
      list: testsList,
      stageList: [],
      singleQuestion: {},
      data: null,
      stage: null,
      refresh: null,
    },
  },
);
export default testReducer;
