import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useTypedSelector from '../../../hooks/useTypedSelector';
import {
  fetchRecordingTestQuestion,
  setRecordingTestsStart,
} from '../../../store/actions/recordingTest';
import { ID, IVideoT } from '../../../types/recordingTest';
import { IParams, QuestionTypesI, TaskStatuses } from '../../../types/test';
import Timer from '../../Timer/Timer';
import TestAudioQuestion from '../TestAudioQuestion/TestAudioQuestion';
import TestAudioVideoDescription from '../TestAudioVideoDescription/TestAudioVideoDescription';
import TestVideoQuestion from '../TestVideoQuestion/TestVideoQuestion';
import * as Styled from './StyledTestAudioVideoContainer';

interface ITaskQuestion {
  testId: ID;
  vacancyID: IParams;
  testName: string;
}
const TaskQuestion: React.FC<ITaskQuestion> = ({
  testId,
  vacancyID,
  testName,
}) => {
  const { question, singleQuestion } = useTypedSelector(
    r => r.recording.recording,
  );
  const [finish, setFinish] = useState(false);
  const [isLoading, setIsLoading] = useState('');

  const stopTime = moment(singleQuestion.date_start, 'DD.MM.YYYY HH:mm:ss')
    .add(singleQuestion.time_record, 's')
    .toDate()
    .getTime();

  useEffect(() => {
    setFinish(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 170);
  }, [singleQuestion]);

  const QuestionType = useMemo(() => {
    switch (question.question_type) {
      case QuestionTypesI.AUDIO: {
        return (
          <Styled.TaskWrapper>
            <Styled.TitleWrapper>
              <Styled.MobileTitle>Медиа</Styled.MobileTitle>
              <Styled.Title>{testName}</Styled.Title>
              {isLoading === 'audio' ? (
                <></>
              ) : (
                <Timer finishTime={stopTime} finishHandler={setFinish} square />
              )}
            </Styled.TitleWrapper>
            <Styled.ContentWrapper>
              <Styled.MobileTestTitle>{testName}</Styled.MobileTestTitle>
              <Styled.CurrentNumber>
                <span className="current">
                  {singleQuestion.question_number}{' '}
                </span>
                <span>/ </span>
                <span>{singleQuestion.question_count}</span>
              </Styled.CurrentNumber>
              <Styled.QuestionTitle>{singleQuestion.name}</Styled.QuestionTitle>
              {singleQuestion.description && (
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: singleQuestion.description,
                  }}
                ></div>
              )}
              <TestAudioQuestion
                vacancyID={vacancyID}
                testId={testId}
                question={singleQuestion}
                setIsLoading={setIsLoading}
                finish={finish}
                setFinish={setFinish}
              />
            </Styled.ContentWrapper>
          </Styled.TaskWrapper>
        );
      }
      case QuestionTypesI.VIDEO: {
        return (
          <Styled.TaskWrapper>
            <Styled.TitleWrapper>
              <Styled.MobileTitle>Медиа</Styled.MobileTitle>
              <Styled.Title>{testName}</Styled.Title>
              {isLoading === 'video' ? (
                <></>
              ) : (
                <Timer finishTime={stopTime} finishHandler={setFinish} square />
              )}
            </Styled.TitleWrapper>
            <Styled.ContentWrapper>
              <Styled.CurrentNumber>
                <span className="current">
                  {singleQuestion.question_number}{' '}
                </span>
                <span>/ </span>
                <span>{singleQuestion.question_count}</span>
              </Styled.CurrentNumber>
              <Styled.MobileTestTitle>{testName}</Styled.MobileTestTitle>
              <Styled.QuestionTitle>{singleQuestion.name}</Styled.QuestionTitle>
              {singleQuestion.description && (
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: singleQuestion.description,
                  }}
                ></div>
              )}
              <TestVideoQuestion
                vacancyID={vacancyID}
                testId={testId}
                question={singleQuestion}
                stopTime={stopTime}
                setIsLoading={setIsLoading}
                finish={finish}
                setFinish={setFinish}
              />
              <Styled.AttentionBlock>
                <p className="attention-title">Внимание!</p>
                <p className=".attention-text">
                  Время на выполнение тестового задания ограничено. После
                  истечения времени — отправить запись будет невозможно!
                </p>
              </Styled.AttentionBlock>
            </Styled.ContentWrapper>
          </Styled.TaskWrapper>
        );
      }
      default: {
        return (
          <Styled.Loader>
            <Loader
              type="Watch"
              color="var(--main-accent-color)"
              height="30px"
              width="30px"
            />
          </Styled.Loader>
        );
      }
    }
  }, [question, singleQuestion, isLoading, finish]);
  return QuestionType;
};

interface TestAudioVideoContainerProps {
  test: IVideoT;
}

const TestAudioVideoContainer: React.FunctionComponent<TestAudioVideoContainerProps> = ({
  test,
}) => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const vacancyID = params ? params.id : null;

  const startTask = (id?: ID) => {
    if (id) {
      dispatch(setRecordingTestsStart(vacancyID as string, id));
    }
  };
  const TestStatus = useMemo(() => {
    if (test) {
      switch (test.status) {
        case TaskStatuses.PROGRESS: {
          dispatch(
            fetchRecordingTestQuestion(vacancyID as string, test.avtesting_id),
          );
          return (
            <TaskQuestion
              vacancyID={vacancyID}
              testId={test.avtesting_id}
              testName={test.avtesting_name}
            />
          );
        }
        default: {
          return (
            <TestAudioVideoDescription test={test} startTask={startTask} />
          );
        }
      }
    }
    return <></>;
  }, [test]);

  return <>{TestStatus}</>;
};

export default TestAudioVideoContainer;
