import React from 'react';
import styled from 'styled-components';

const StyledCheckboxWrap = styled.div`
  margin-bottom: 12px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    position: relative;
    display: block;
    margin: 0;
    padding-left: 36px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.374px;
    color: var(--main-text-color);
  }

  input ~ label::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--main-text-color);
    border-radius: 2px;
    transition: opacity 0.5s;
    cursor: pointer;
  }

  input ~ label svg {
    display: none;
  }

  input:checked ~ label svg {
    display: block;
    position: absolute;
    top: 0;
    left: 3px;
    width: 13px;
    height: 13px;
    cursor: pointer;
    color: var(--main-accent-color);
    z-index: 1;
  }

  label:hover,
  label:focus {
    &::before {
      opacity: 0.7;
    }
  }

  @media (min-width: 768px) {
    input ~ label::before {
      width: 18px;
      height: 18px;
      border: 2px solid var(--main-text-color);
      top: 0;
    }

    input:checked ~ label svg {
      width: 18px;
      height: 23px;
      top: -7px;
    }
  }
`;

export const checkIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="check"
  >
    <path
      d="M2 13L6.5 17.5L18 2"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface PropsInterface {
  name?: string;
  id?: string;
  value?: string | number;
  checked?: boolean;
  handleСhange?: (val: any) => void;
  keyFlag?: string | number;
}

const StyledCheckboxInput: React.FC<PropsInterface> = ({
  name,
  id,
  value,
  checked,
  children,
  handleСhange,
  keyFlag,
}) => {
  return (
    <StyledCheckboxWrap key={keyFlag}>
      <input
        className="visually-hidden"
        type="checkbox"
        id={id}
        name={name}
        value={value}
        defaultChecked={checked}
        onChange={handleСhange}
      />

      <label htmlFor={id}>
        {checkIcon}
        {children}
      </label>
    </StyledCheckboxWrap>
  );
};

export default StyledCheckboxInput;
