import produce from 'immer';
import { InterviewI, InterviewActionTypes } from '../../types/interview';

const timeSlots: InterviewI[] = [];

const interviewReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case InterviewActionTypes.FETCH_INTERVIEWS_REGISTRATION: {
        draft.registration = action.payload;
        return draft;
      }
      case InterviewActionTypes.FETCH_INTERVIEWS_SLOTS: {
        draft.timeSlots.slots = action.payload;
        draft.timeSlots.loaded = true;
        draft.timeSlots.error = false;
        return draft;
      }
      case InterviewActionTypes.FETCH_INTERVIEWS_SLOTS_ERROR: {
        draft.timeSlots.error = action.payload.error;
        return draft;
      }
      case InterviewActionTypes.CLEAR_INTERVIEWS_SLOTS: {
        draft.timeSlots.error = false;
        draft.timeSlots.loaded = false;
        draft.timeSlots.slots = [];
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    timeSlots: {
      slots: timeSlots,
      loaded: false,
      error: false,
    },
    registration: [],
  },
);

export default interviewReducer;
