import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import {
  QuestionTypesI,
  TaskStatuses,
  TestListItemI,
} from '../../../types/test';
import { fetchTestQuestion, setTestsStart } from '../../../store/actions/test';
import * as Styled from './StyledTextTestContainer';
import useTypedSelector from '../../../hooks/useTypedSelector';
import TestTextQuestion from '../TestTextQuestion/TestTextQuestion';
import TestTextDescription from '../TestTextDescription/TestTextDescription';

const TaskQuestion: React.FC = () => {
  const { singleQuestion } = useTypedSelector(r => r.test.tests);

  useEffect(() => {
    window.scrollTo(0, 170);
  }, [singleQuestion]);

  const QuestionType = useMemo(() => {
    switch (singleQuestion.question_type) {
      case QuestionTypesI.SORTING: {
        return <>sorting question</>;
      }
      case QuestionTypesI.TEXT: {
        return <TestTextQuestion text />;
      }
      case QuestionTypesI.MULTIPLE: {
        return <TestTextQuestion multiType />;
      }
      case QuestionTypesI.SINGLE: {
        return <TestTextQuestion />;
      }
      default: {
        return (
          <Styled.Loader>
            <Loader
              type="Watch"
              color="var(--main-accent-color)"
              height="16px"
              width="16px"
            />
          </Styled.Loader>
        );
      }
    }
  }, [singleQuestion]);
  return <>{QuestionType}</>;
};
interface TestTextContainerProps {
  test: TestListItemI;
}

const TestTextContainer: React.FC<TestTextContainerProps> = ({ test }) => {
  const params: { id: string } = useParams();
  const vacancyID = params ? params.id : null;
  const dispatch = useDispatch();
  const startTask = () => {
    dispatch(setTestsStart(vacancyID as string));
  };

  const TestStatus = useMemo(() => {
    if (test) {
      switch (test.status) {
        case TaskStatuses.PROGRESS: {
          dispatch(fetchTestQuestion(vacancyID as string));
          return <TaskQuestion />;
        }
        case TaskStatuses.NOT_STARTED: {
          return <TestTextDescription startTask={startTask} test={test} />;
        }
        default: {
          return 'default case';
        }
      }
    }
    return <></>;
  }, [test]);

  return <>{TestStatus}</>;
};

export default TestTextContainer;
