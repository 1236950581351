import styled from 'styled-components';

export const LinkWrapper = styled.div`
  margin-top: 42px;
  max-width: 320px;

  @media (max-width: 767px) {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ReportLinkWrapper = styled.div`
  margin-top: 42px;
  max-width: 320px;

  @media (max-width: 767px) {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    grid-area: btn;
  }
`;

export const AttentionBlock = styled.section`
  padding: 22px 16px 33px 16px;
  border-radius: 20px;
  background-color: var(--main-block-bg);

  svg {
    color: var(--main-accent-color);
  }

  .icon {
    display: none;
    position: absolute;
    width: 158px;
    height: 158px;
    top: 52px;
    left: 57px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px var(--main-accent-color-hover),
      inset 0px 2px 6px var(--main-accent-color-hover);
  }

  @media (min-width: 768px) {
    padding: 50px;
    padding-left: 250px;

    svg {
      transform: translate(-21px, -18px);
    }

    .icon {
      top: 60px;
      display: block;
    }
  }

  @media (min-width: 992px) {
    border-radius: 30px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.374px;
    margin-bottom: 6px;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 10px;
    }
  }

  .text {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.374px;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 28px;
      margin-bottom: 41px;
    }
  }

  .text-wrapper {
    position: relative;
    padding-left: 60px;
    padding-left: 25%;

    svg {
      display: block;
      position: absolute;
      width: 49px;
      height: 49px;
      top: 0;
      left: 0;
    }

    @media (min-width: 375px) {
      svg {
        left: 4%;
      }
    }

    @media (min-width: 576px) {
      svg {
        width: 80px;
        height: 80px;
      }
    }

    @media (min-width: 576px) {
      &::before {
        width: 70px;
        height: 70px;
      }
    }

    @media (min-width: 768px) {
      padding-left: 0;

      &::before {
        display: none;
      }

      svg {
        display: none;
      }
    }
  }
`;

export const AttentionStartBlock = styled.section`
  padding: 24px;
  border-radius: 20px;
  background-color: var(--main-block-bg);
  box-shadow: 0px 0px 5px var(--main-accent-shadow),
    0px 0px 30px var(--main-accent-shadow);

  &.error {
    box-shadow: 0px 0px 5px var(--error-text-color),
      0px 0px 30px var(--error-text-color);
  }

  @media (min-width: 768px) {
    padding: 50px;
    display: grid;
    grid-template-columns: 158px 1fr;
    column-gap: 30px;
    grid-template-areas:
      'timer text'
      'timer btn';
  }

  @media (min-width: 992px) {
    column-gap: 72px;
  }

  @media (min-width: 992px) {
    border-radius: 30px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.374px;
    margin-bottom: 6px;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 10px;
    }
  }

  .text {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.374px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 28px;
    }
  }

  .text-wrapper {
    position: relative;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      grid-area: text;
      margin-bottom: 0;
    }
  }

  .time-block-wrap {
    width: 158px;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: 0;
      grid-area: timer;
    }
  }
`;
