import { icon1, icon2, icon3, icon4, icon5 } from './icons';

export const switchIcon = (code: string) => {
  switch (code) {
    case 'RESUME':
      return icon1;
      break;
    case 'TRIALWORK':
      return icon2;
      break;
    case 'TESTING':
      return icon3;
      break;
    case 'AVTESTING':
      return icon5;
      break;
    case 'INTERVIEW':
      return icon4;
      break;
    default:
      // Нужно установить дефолтную картинку
      return icon1;
  }
};
