export type ID = number | string;

export interface InterviewI {
  id: number;
  vacancy_id: number;
  responsible_user: number;
  max_place: number;
  date: string;
  url: string;
}

interface InterviewSlotsI {
  max_places: number;
}

export enum InterviewActionTypes {
  FETCH_INTERVIEWS_REGISTRATION = 'FETCH_INTERVIEWS_REGISTRATION',
  FETCH_INTERVIEWS_SLOTS = 'FETCH_INTERVIEWS_SLOTS',
  FETCH_INTERVIEWS_SLOTS_ERROR = 'FETCH_INTERVIEWS_SLOTS_ERROR',
  CLEAR_INTERVIEWS_SLOTS = 'CLEAR_INTERVIEWS_SLOTS',
}

interface FetchInterviewsRegistration {
  type: InterviewActionTypes.FETCH_INTERVIEWS_REGISTRATION;
  payload: {
    result: InterviewI;
  };
}

interface FetchInterviewsSlots {
  type: InterviewActionTypes.FETCH_INTERVIEWS_SLOTS;
  payload: {
    result: InterviewSlotsI;
  };
}

interface FetchInterviewsSlotsError {
  type: InterviewActionTypes.FETCH_INTERVIEWS_SLOTS_ERROR;
  payload: { error: true };
}

interface ClearInterviewsSlot {
  type: InterviewActionTypes.CLEAR_INTERVIEWS_SLOTS;
}

export type InterviewActions =
  | FetchInterviewsSlots
  | FetchInterviewsSlotsError
  | ClearInterviewsSlot
  | FetchInterviewsRegistration;
