import styled from 'styled-components';

export const StartVideo = styled.div`
  width: 100%;
  max-width: 600px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;

  & > svg {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;

    svg {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
`;

export const ModalContainer = styled.section`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--overlay-bg);

  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (min-width: 768px) {
    .wrapper {
      max-width: 720px;
      width: calc(100% - 30px);
      height: 80vh;
      min-height: 450px;
    }
  }

  @media (min-width: 992px) {
    .wrapper {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .wrapper {
      max-width: 1140px;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LinkWrapper = styled.div`
  width: 240px;

  &.stop-link {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

  @media (min-width: 768px) {
    width: 265px;
  }
`;

export const TimerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  padding: 20px 10px 10px 10px;
  background-color: var(--main-block-bg);
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;

  @media (min-width: 768px) {
    width: 168px;
    transform: translateX(0);
    left: auto;
    right: 40px;
  }
`;

export const NextLinkWrapper = styled.div`
  width: 100%;
  margin-top: 42px;
  max-width: 220px;

  @media (max-width: 767px) {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
`;
