import styled from 'styled-components';
import tick from '../../assets/img/check-icon-white.svg';

export const StepsBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  justify-content: center;

  @media (min-width: 768px) {
    grid-column-gap: 25px;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

export const StepsTitle = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 400;
  margin-bottom: 24px;
  font-size: 25px;
  line-height: 35px;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const StepsItem = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  position: relative;
  padding: 55px 5px 45px;
  border-radius: 15px;
  text-align: center;
  background-color: var(--main-block-bg);

  @media (max-width: 767px) {
    padding: 20px 10px;
    max-width: 320px;
  }

  &.done {
    box-shadow: 0px 4px 16px var(--main-accent-shadow);
  }

  &::before {
    content: attr(data-number);
    position: absolute;
    left: 25px;
    top: 25px;
    font-size: 15px;
    line-height: 22px;
    color: var(--gray-text-color);

    @media (max-width: 991px) {
      font-size: 11px;
      line-height: 14px;
      left: 10px;
      top: 15px;
    }
  }

  .status-text {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    display: none;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--main-accent-color);
  }

  .title {
    margin: 0;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.374px;
    }
  }

  .icon {
    position: relative;
    width: 105px;
    height: 105px;
    margin: 0 auto 13px;
    padding: 5px;
    border: 1px dashed var(--main-text-color);
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      display: none;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: var(--main-accent-color);
      background-image: url(${tick});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 7px auto;
    }

    @media (max-width: 767px) {
      width: 49px;
      height: 49px;

      &::before {
        left: 0px;
        top: 0px;
        width: 11px;
        height: 11px;
      }
    }
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid var(--main-text-color);
    border-radius: 50%;
  }

  svg {
    max-width: 70px;
    max-height: 70px;
    width: auto;
    height: auto;
    margin-top: 8px;

    @media (max-width: 767px) {
      max-width: 27px;
      max-height: 27px;
      margin-top: 0;
    }
  }

  &.done .status-text {
    @media (min-width: 768px) {
      display: block;
    }
  }

  &.done .icon {
    border-color: var(--main-accent-color);
    color: var(--main-accent-color);

    &::before {
      display: block;
      box-shadow: 0px 2px 10px var(--main-accent-shadow);
    }
  }

  &.done .inner {
    border-color: var(--main-accent-color);
  }
`;
