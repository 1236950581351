import styled from 'styled-components';

export const TextBlock = styled.div`
  .text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .text-bottom {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    .text {
      font-size: 17px;
      line-height: 28px;
    }

    .text-bottom {
      margin-bottom: 30px;
    }
  }
`;
