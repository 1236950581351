import { ListItemI, TestListItemI } from '../../types/test';

export const getTestList = (array: TestListItemI[]): ListItemI[] => {
  return array.map((item: TestListItemI) => {
    return {
      id: item.test_id,
      status: item.status,
      name: item.name,
    };
  });
};
