import produce from 'immer';
import { TestListItemI } from '../../types/test';
import { TRialWorkActionTypes } from '../../types/trialWork';

const testsList: TestListItemI[] = [];

const testingReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case TRialWorkActionTypes.TRIAL_WORK_START: {
        draft.trialWork.start = action.payload;
        return draft;
      }
      case TRialWorkActionTypes.FETCH_TRIAL_WORK_DATE: {
        draft.trialWork.data = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    trialWork: {
      start: false,
      data: {
        files: [],
        time_limit: 10,
      },
    },
  },
);
export default testingReducer;
