import styled from 'styled-components';

export const StartTest = styled.section`
  padding: 43px 17px 44px 17px;

  @media (min-width: 768px) {
    padding-top: 30px;
    padding-left: 0;
    padding-bottom: 40px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    padding-top: 40px;
    padding-right: 48px;
  }

  .box {
    margin-bottom: 40px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 992px) {
      margin: 0;
      margin-bottom: 40px;
      max-width: 100%;
      display: grid;
      grid-template-columns: 230px 1fr;
      column-gap: 50px;
      align-items: center;
    }
  }

  .icon-wrap {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .stroke-white {
      stroke: var(--main-text-color);
    }

    .fill-white {
      fill: var(--main-text-color);
    }

    .stroke-accent {
      stroke: var(--main-accent-color);
    }

    .fill-accent {
      fill: var(--main-accent-color);
    }

    @media (min-width: 992px) {
      min-height: 121px;
      align-items: flex-end;
      margin-bottom: 0;
    }
  }

  .circle {
    position: relative;
    padding: 5px;
    width: 92px;
    height: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main-text-color-opacity);
    border-radius: 50%;

    &:first-of-type {
      margin-right: 37px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      top: 7px;
      left: 7px;
      border: 1px solid var(--main-text-color);
      border-radius: 50%;
      opacity: 0.6;
    }

    .arrow-dashed {
      display: block;
      position: absolute;
      width: 36px;
      height: 10px;
      right: -37px;
      top: 50%;
    }
  }

  .test-number {
    font-size: 35px;
    line-height: 43px;
    text-align: center;
    color: var(--main-accent-color);
    text-shadow: 0px 4px 21px var(--main-accent-shadow);
    font-weight: 500;
  }

  .test-text {
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
  }

  .image {
    display: block;
    width: auto;

    @media (min-width: 768px) {
      &--1 {
        width: 170px;
        height: 120px;
      }

      &--2 {
        width: 220px;
        height: 90px;
      }

      &--3 {
        width: 200px;
        height: 85px;
      }

      &--4 {
        width: 84px;
        height: 84px;
      }
    }
  }

  .text {
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.374px;
    color: var(--gray-text-color);
    text-align: center;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 19px;
      text-align: left;
    }

    @media (min-width: 992px) {
      margin: 0;
    }
  }
`;

export const Title = styled.h2`
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-size: 25px;
    line-height: 31px;
    padding-bottom: 33px;
    border-bottom: 1px solid var(--main-text-color-opacity);
    margin-bottom: 46px;
    grid-column: 1/-1;
  }
`;

export const BtnWrapper = styled.div`
  max-width: 260px;
  margin: 0 auto;

  @media (min-width: 992px) {
    align-self: center;
    margin-left: 250px;
    margin-right: 0;
  }

  @media (min-width: 1200px) {
    margin-left: 280px;
  }
`;

export const Loader = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TestItemBtn = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 20px 18px 45px;
  background-color: transparent;
  border: 0;
  font: inherit;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.374px;
  text-transform: uppercase;
  text-align: left;
  color: var(--main-text-color);
  border-radius: 5px;
  border-bottom: 2px solid var(--main-bg);

  &::before {
    content: '';
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid var(--main-text-color);
    border-radius: 2px;
  }

  svg {
    position: absolute;
    display: block;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;

    &.down {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  background-color: var(--main-block-bg);
`;

export const TitleWrap = styled.div`
  padding: 24px;
  padding-bottom: 22px;
  border-radius: 20px 20px 0px 0px;
  background-color: var(--secondary-block-bg);

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.374px;
    text-transform: uppercase;
    color: var(--main-text-color);
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
