import { IStage } from '../types/company';

export const getStages = (value: string, index: number): any => {
  switch (value) {
    case 'TESTING': {
      return { name: 'Тестирование', value: index };
    }
    case 'TRIALWORK': {
      return { name: 'Тестовое', value: index };
    }
    case 'INTERVIEW': {
      return { name: 'Собеседование', value: index };
    }
    case 'AVTESTING': {
      return { name: 'Видео', value: index };
    }
    default: {
      return '';
    }
  }
};

export const checkStringIsURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const declOfNum = (number: number | undefined, words: string[]) => {
  if (!number) {
    return words[2];
  }
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};

export const getTime = (timestamp: number) => {
  const hours = Math.floor(timestamp / 60);
  const minutes = Math.floor(timestamp) - hours * 60;
  return hours === 0
    ? `${minutes} ${declOfNum(minutes, ['минута', 'минуты', 'минут'])}`
    : `${hours} ${declOfNum(hours, ['час', 'часа', 'часов'])} ${
        minutes !== 0
          ? `${minutes} ${declOfNum(minutes, ['минута', 'минуты', 'минут'])}`
          : ''
      }`;
};

export enum VocabularyNum {
  'первый' = 1,
  'второй' = 2,
  'третий' = 3,
  'четвертый' = 4,
  'пятый' = 5,
  'шестой' = 6,
}

export enum Vocabulary {
  'первого' = 1,
  'второго' = 2,
  'третьего' = 3,
  'четвертого' = 4,
  'пятого' = 5,
  'шестого' = 6,
}

export const getNumOfStep = (
  stages: IStage[],
  currentStage: string,
): number => {
  if (
    stages.filter(
      (stage: IStage, i: number) => stage.code === currentStage,
    )[0] !== undefined
  ) {
    return (
      stages.filter(
        (stage: IStage, i: number) => stage.code === currentStage,
      )[0].sort + 1
    );
  }
  return 1;
};

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }).then(data => data);
};
