import React from 'react';

export const dateIcon = (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M27.712 27.4822C27.712 27.2442 27.519 27.0514 27.2808 27.0514H25.7758C25.5379 27.0514 25.3448 27.2442 25.3448 27.4822V28.9869C25.3448 29.2253 25.5379 29.4182 25.7758 29.4182H27.2808C27.519 29.4182 27.712 29.2253 27.712 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M31.4733 27.4822C31.4733 27.2442 31.2802 27.0514 31.0425 27.0514H29.5373C29.2994 27.0514 29.1063 27.2442 29.1063 27.4822V28.9869C29.1063 29.2253 29.2994 29.4182 29.5373 29.4182H31.0425C31.2802 29.4182 31.4733 29.2253 31.4733 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M35.2344 27.4822C35.2344 27.2442 35.0413 27.0514 34.8034 27.0514H33.2984C33.0602 27.0514 32.8672 27.2442 32.8672 27.4822V28.9869C32.8672 29.2253 33.0602 29.4182 33.2984 29.4182H34.8034C35.0413 29.4182 35.2344 29.2253 35.2344 28.9869V27.4822Z"
        fill="currentColor"
      />
      <path
        d="M27.712 31.2436C27.712 31.0052 27.519 30.8126 27.2808 30.8126H25.7758C25.5379 30.8126 25.3448 31.0052 25.3448 31.2436V32.7481C25.3448 32.9862 25.5379 33.1791 25.7758 33.1791H27.2808C27.519 33.1791 27.712 32.9862 27.712 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M31.4733 31.2436C31.4733 31.0052 31.2802 30.8126 31.0425 30.8126H29.5373C29.2994 30.8126 29.1063 31.0052 29.1063 31.2436V32.7481C29.1063 32.9862 29.2994 33.1791 29.5373 33.1791H31.0425C31.2802 33.1791 31.4733 32.9862 31.4733 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M35.2344 31.2436C35.2344 31.0052 35.0413 30.8126 34.8036 30.8126H33.2984C33.0602 30.8126 32.8672 31.0052 32.8672 31.2436V32.7481C32.8672 32.9862 33.0602 33.1791 33.2984 33.1791H34.8036C35.0413 33.1791 35.2344 32.9862 35.2344 32.7481V31.2436Z"
        fill="currentColor"
      />
      <path
        d="M37.9251 19.1156V21.4139C37.9251 22.4528 37.0823 23.2899 36.0437 23.2899H34.8568C33.818 23.2899 32.9641 22.4528 32.9641 21.4139V19.1073H27.6147V21.4139C27.6147 22.4528 26.7608 23.2899 25.7222 23.2899H24.5351C23.4964 23.2899 22.6537 22.4528 22.6537 21.4139V19.1156C21.7459 19.1429 21 19.8941 21 20.8174V34.2869C21 35.2275 21.7624 36 22.703 36H37.8757C38.815 36 39.5788 35.2259 39.5788 34.2869V20.8174C39.5788 19.8941 38.8329 19.1429 37.9251 19.1156ZM37.3738 33.4481C37.3738 33.8546 37.0442 34.1844 36.6376 34.1844H23.9088C23.5021 34.1844 23.1725 33.8546 23.1725 33.4481V26.4906C23.1725 26.084 23.5021 25.7542 23.9088 25.7542H36.6375C37.0442 25.7542 37.3738 26.084 37.3738 26.4906L37.3738 33.4481Z"
        fill="currentColor"
      />
      <path
        d="M24.531 22.0578H25.705C26.0613 22.0578 26.3502 21.7693 26.3502 21.4129V17.6451C26.3502 17.2887 26.0613 17 25.705 17H24.531C24.1746 17 23.8858 17.2887 23.8858 17.6451V21.4129C23.8858 21.7693 24.1746 22.0578 24.531 22.0578Z"
        fill="currentColor"
      />
      <path
        d="M34.8416 22.0578H36.0156C36.3717 22.0578 36.6606 21.7693 36.6606 21.4129V17.6451C36.6607 17.2887 36.3717 17 36.0156 17H34.8416C34.4853 17 34.1964 17.2887 34.1964 17.6451V21.4129C34.1964 21.7693 34.4853 22.0578 34.8416 22.0578Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const timeIcon = (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M30.4998 17C25.2533 17 21 21.2534 21 26.4998C21 31.7463 25.2533 36 30.4998 36C35.7464 36 40 31.7463 40 26.4998C40 21.2534 35.7464 17 30.4998 17ZM34.6069 28.3067H30.5793C30.5657 28.3067 30.553 28.3034 30.5396 28.3029C30.5261 28.3035 30.5135 28.3067 30.4998 28.3067C30.1369 28.3067 29.8427 28.0125 29.8427 27.6497V20.9425C29.8427 20.5797 30.1369 20.2855 30.4998 20.2855C30.8626 20.2855 31.1568 20.5797 31.1568 20.9425V26.9926H34.6066C34.9695 26.9926 35.2637 27.2868 35.2637 27.6497C35.2637 28.0125 34.9697 28.3067 34.6069 28.3067Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const attentionIcon = (
  <svg
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8299 44.6932H37.2413L36.739 25.9545H40.3322L39.8299 44.6932ZM38.5163 53.2318C37.9496 53.2318 37.4731 53.0386 37.0868 52.6523C36.7004 52.2659 36.5072 51.7894 36.5072 51.2227C36.5072 50.6561 36.7004 50.1795 37.0868 49.7932C37.4731 49.4068 37.9496 49.2136 38.5163 49.2136C39.083 49.2136 39.5595 49.4068 39.9458 49.7932C40.3322 50.1795 40.5254 50.6561 40.5254 51.2227C40.5254 51.7894 40.3322 52.2659 39.9458 52.6523C39.5595 53.0386 39.083 53.2318 38.5163 53.2318Z"
      fill="currentColor"
    />
    <circle cx="39" cy="39" r="38" stroke="currentColor" strokeWidth="2" />
  </svg>
);
