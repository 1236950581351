import styled from 'styled-components';

export const AudioTrack = styled.div`
  overflow: hidden;
  position: relative;
  width: 500px;
  height: 40px;

  canvas {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
