import { IVideoT } from '../../types/recordingTest';
import { ListItemI } from '../../types/test';

export const getTestList = (array: IVideoT[]): ListItemI[] => {
  return array.map((item: IVideoT) => {
    return {
      id: item.avtesting_id,
      status: item.status,
      name: item.avtesting_name,
    };
  });
};
