import produce from 'immer';
import { RecordingTestActionTypes } from '../../types/recordingTest';

const recordingReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case RecordingTestActionTypes.FETCH_RECORDING_TESTS: {
        draft.recording.tests = action.payload;
        return draft;
      }
      case RecordingTestActionTypes.FETCH_RECORDING_QUESTION: {
        draft.recording.question.question_type = action.payload.type;
        draft.recording.singleQuestion = action.payload;
        return draft;
      }

      default:
        return draft;
    }
  },
  {
    recording: {
      tests: [],
      question: { question_type: null },
      singleQuestion: {},
    },
  },
);
export default recordingReducer;
