import React, { useState } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import StyledTitle from '../../StyledComponents/StyledTitle';
import StyledLink from '../../StyledComponents/StyledLink';
import useTypedSelector from '../../../hooks/useTypedSelector';
import * as Styled from './StyledInterviewInfo';
import { dateIcon, geoIcon, mailIcon, telIcon, timeIcon } from './icons';
import { InterviewI } from '../../../types/interview';

interface PropsInterface {
  offline?: boolean;
  registration: InterviewI;
}

const InterviewInfo: React.FC<PropsInterface> = ({ offline, registration }) => {
  const { profile } = useTypedSelector(r => r.auth);
  const [template, setTemplate] = useState(null);

  // const createTemplateLayoutFactory = (ymaps: any) => {
  //   if (ymaps && !template) {
  //     setTemplate(
  //       ymaps.templateLayoutFactory.createClass(
  //         '<div className="custom"></div>',
  //       ),
  //     );
  //   }
  // };

  return (
    <Styled.Block $offlineFlag={offline}>
      <Styled.Wrapper $offlineFlag={offline}>
        <Styled.UserName>
          {profile.last_name} {profile.name}
        </Styled.UserName>
        <StyledTitle>Вы записаны на собеседование</StyledTitle>
        <Styled.InfoList>
          <li>
            <p className="text">
              {dateIcon}
              <span>Дата собеседования:</span>
            </p>
            <span className="value">{registration.date.slice(0, 10)}</span>
          </li>
          <li>
            <p className="text">
              {timeIcon}
              <span>Время собеседования:</span>
            </p>
            <span className="value">{registration.date.slice(10, -3)}</span>
          </li>

          {offline && (
            <li>
              <p className="text">
                {geoIcon}
                <span>МЕСТО собеседования:</span>
              </p>
              <span className="value">
                ул. Барклая, 6 строение 5 Москва, 121087
              </span>
            </li>
          )}
        </Styled.InfoList>
        <Styled.ContactLinks $offlineFlag={offline}>
          <Styled.ContactLink href="tel:+74951183619">
            {telIcon}
            8-495-118-36-19
          </Styled.ContactLink>
          <Styled.ContactLink href="mailto:info@mebot24.ru">
            {mailIcon}
            info@mebot24.ru
          </Styled.ContactLink>
        </Styled.ContactLinks>
      </Styled.Wrapper>
      {offline ? (
        <>
          Карта
          {/* <YMaps>
           <Styled.Map>
             <Map 
        //       onLoad={ymaps => createTemplateLayoutFactory(ymaps)}
        //       defaultState={{ center: [55.75, 37.57], zoom: 10 }}
        //       width="100%"
        //       height="200px"
        //       modules={['templateLayoutFactory', 'layout.ImageWithContent']}
        //     >
        //       <Placemark
        //         geometry={[55.739255068993906, 37.50822949999996]}
        //         properties={{
        //           iconContent: 'ООО "МОССЕРВИС 24"',
        //         }}
        //         options={{
        //           iconLayout: 'default#imageWithContent',
        //           // здесь задана иконка для метки, но нам необходимо задать свою разметку через iconContentLayout для метки (див с классом, чтобы стилизовать цвет подписи и на фон поставить иконку)
        //           // iconImageHref: placemarkIcon,
        //           // iconImageSize: [200, 30],
        //           // iconImageOffset: [-100, -30],
        //           // iconContentOffset: [15, 15],
        //           iconContentLayout: template,
        //         }}
        //       />
        //     </Map>
        //   </Styled.Map>
        // </YMaps>*/}
        </>
      ) : (
        <Styled.AttentionBlock>
          <h3 className="title">Внимание</h3>
          <p className="text">
            Собеседование будет проходить в online формате.
          </p>

          <p className="text">
            Подготовьте ко встрече удобное, тихое место. Проверьте, работает ли
            ваша камера и микрофон.
          </p>
          <p className="text">Увидимся!</p>
          <p className="text">
            Для входа перейдите в назначенное время по ссылке ниже:
          </p>
          <Styled.LinkWrap>
            <StyledLink
              text="Перейти в чат"
              externalLink
              externalLinkPath={registration.url}
            />
          </Styled.LinkWrap>
        </Styled.AttentionBlock>
      )}
    </Styled.Block>
  );
};

export default InterviewInfo;
