import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import axios from 'axios';
import modalPict from '../../../assets/images/modal-pict.png';
import pdf from '../../../assets/images/pdf.svg';
import xls from '../../../assets/images/xls.svg';
import { config, url } from '../../../store/actions/actionsAssets';

export interface VacancyResponseI {
  setShow: (e: boolean) => void;
  setResponseSent?: (e: boolean) => void;
  vacancyId?: number;
}

interface VacancyRspI {
  id: number;
  vacancy_id: number;
  first_name: string;
  email: string;
  last_name: string;
  url: string;
  year_of_birthday: string;
  phone: string;
  file: string[];
}

const VacancyResponse: React.FC<VacancyResponseI> = ({
  setShow,
  vacancyId,
  setResponseSent,
}) => {
  const [vacancyResp, setVacancyResp] = useState<VacancyRspI>({
    file: [],
    first_name: '',
    email: '',
    id: 0,
    last_name: '',
    phone: '',
    url: '',
    vacancy_id: vacancyId || 0,
    year_of_birthday: '',
  });

  const handleVacancyResp = (
    value: number | string | string[],
    name: string,
  ) => {
    setVacancyResp(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handlePostCv = async () => {
    axios
      .post(`${url}resume/add/`, vacancyResp, config)
      .then(r => {
        console.log(r);
      })
      .then(() => {
        if (setResponseSent) {
          setResponseSent(true);
        }
      });
  };

  const files = (arr: FileList | null) => {
    if (arr) {
      const filenames: string[] = [];
      Object.keys(arr).forEach(r => {
        const a = Number(r);
        const it = arr[a];
        const ext = it.name.split('.').pop();
        filenames.push(String(ext));
      });
      handleVacancyResp(filenames, 'file');
    }
  };

  return (
    <>
      <Modal.Header>
        <div className="modal-header__img">
          <img src={modalPict} alt="#" />
        </div>
        <button className="close" type="button" onClick={() => setShow(false)}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.03127 20.0943L20.094 8.03154M20.0939 20.0942L8.03111 8.03139"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="modal-popup__title">Отклик на вакансию</div>
        <div className="modal-popup__row">
          <div className="modal-popup__row-block">
            <label className="modal-popup__label">
              Имя
              <p>
                <br />
                <input
                  className="modal-popup__input"
                  type="text"
                  value={vacancyResp.first_name}
                  onChange={e => {
                    handleVacancyResp(e.target.value, e.target.name);
                  }}
                  placeholder="Александр"
                  name="first_name"
                />
              </p>
            </label>
          </div>
          <div className="modal-popup__row-block">
            <label className="modal-popup__label">
              Фамилия
              <p>
                <br />
                <input
                  onChange={e => {
                    handleVacancyResp(e.target.value, e.target.name);
                  }}
                  className="modal-popup__input"
                  value={vacancyResp.last_name}
                  type="text"
                  placeholder="Филиппов"
                  name="last_name"
                />
              </p>
            </label>
          </div>
        </div>
        <div className="modal-popup__row">
          <div className="modal-popup__row-block">
            <label className="modal-popup__label">
              E-mail
              <p>
                <br />
                <input
                  className="modal-popup__input"
                  onChange={e => {
                    handleVacancyResp(e.target.value, e.target.name);
                  }}
                  value={vacancyResp.email}
                  type="email"
                  placeholder="info@mebot24-hr.ru"
                  name="email"
                />
              </p>
            </label>
          </div>
          <div className="modal-popup__row">
            <div className="modal-popup__row-block">
              <label className="modal-popup__label">
                Дата рождения
                <p>
                  <br />
                  <input
                    className="modal-popup__input"
                    onChange={e => {
                      handleVacancyResp(e.target.value, e.target.name);
                    }}
                    value={vacancyResp.year_of_birthday}
                    type="text"
                    placeholder="28.11.2001"
                    name="year_of_birthday"
                  />
                </p>
              </label>
            </div>
          </div>
          <div className="modal-popup__row-block">
            <label className="modal-popup__label">
              Номер телефона
              <p>
                <br />
                <input
                  className="modal-popup__input"
                  onChange={e => {
                    handleVacancyResp(e.target.value, e.target.name);
                  }}
                  value={vacancyResp.phone}
                  type="text"
                  placeholder="+7 (___) ___-__-__"
                  name="phone"
                />
              </p>
            </label>
          </div>
        </div>
        <div className="modal-popup__row">
          <div className="modal-popup__row-block comments-block">
            <label className="modal-popup__label">
              Комментарий
              <div>
                <br />
                <textarea
                  className="modal-popup__textarea"
                  id="comments"
                  name="private-textarea"
                  placeholder="Ссылка на портфолио, личный сайт, работы"
                />
              </div>
            </label>
          </div>
        </div>
        <div className="modal-popup__docs">
          <div className="modal-popup__docs-area" id="private-docs__area">
            {vacancyResp.file.map((r, i) => (
              <div className="private-docs__item" key={r + String(i)}>
                <img src={pdf} alt="#" />
                Портфолио.{r}
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6M6 1L1 6"
                    stroke="#828282"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            ))}
          </div>
          <button className="private-btn" type="button">
            <svg
              width="9"
              height="11"
              viewBox="0 0 9 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.21218 3.71179L4.59965 2.08609V3.21886C4.59992 3.49094 4.80674 3.7115 5.06184 3.71179H6.21218Z"
                fill="#CDCEDE"
              />
              <path
                d="M6.63441 1.25668V0H3.05891C2.67615 0.000481374 2.36597 0.331186 2.36561 0.739392V1.32917H4.42978C4.48882 1.32917 4.5456 1.35324 4.58857 1.39647L6.89295 3.71978C6.93908 3.76628 6.96526 3.83107 6.96526 3.89885V9.17799H8.30671C8.68946 9.17751 8.99955 8.84671 9 8.4386V2.24244H7.55889C7.04858 2.24186 6.63504 1.80082 6.63441 1.25668Z"
                fill="#CDCEDE"
              />
              <path
                d="M7.5588 1.74961H8.56064L7.0966 0.300957V1.25668C7.09696 1.52875 7.30369 1.74922 7.5588 1.74961Z"
                fill="#CDCEDE"
              />
              <path
                d="M4.13745 3.21886V1.82201H0.693292C0.310537 1.82249 0.000451356 2.15329 0 2.5614V10.2606C0.000451356 10.6687 0.310537 10.9995 0.693292 11H5.80975C6.1925 10.9995 6.50268 10.6687 6.50304 10.2606V4.20472H5.06184C4.55153 4.20414 4.13808 3.7631 4.13745 3.21886Z"
                fill="#CDCEDE"
              />
            </svg>
            Прикрепить файл
          </button>
          <input
            type="file"
            accept=".rom, .xls"
            onChange={e => files(e.target.files)}
            multiple
          />
        </div>
        <div className="modal-popup__btn">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button type="button" className="dflt-bttn" onClick={handlePostCv}>
            Отправить отклик на вакансию
          </button>
        </div>
      </Modal.Body>
    </>
  );
};
export default VacancyResponse;
