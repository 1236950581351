import produce from 'immer';
import { VacancyActionTypes } from '../../types/vacancy';
import { AutomaticActionTypes } from '../../types/automatic';
import { CompanyActionTypes } from '../../types/company';

const automaticReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case VacancyActionTypes.FETCH_VACANCY: {
        draft.vacancy = action.payload;
        return draft;
      }
      case CompanyActionTypes.FETCH_COMPANY: {
        draft.company = action.payload;
        return draft;
      }
      case VacancyActionTypes.FETCH_STAGE_VACANCY: {
        draft.stages = action.payload;
        return draft;
      }
      case AutomaticActionTypes.FETCH_CURRENT_STAGE: {
        if (action.payload === null) {
          draft.currentStage = {
            group: null,
            action: null,
            date_insert: null,
          };
        } else {
          draft.currentStage.group = action.payload.action_group;
          draft.currentStage.action = action.payload.action;
          draft.currentStage.date = action.payload.date_insert;
        }
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    vacancy: {
      id: null,
      name: null,
      company_id: null,
      status: null,
      type_location: null,
      type_frequency: null,
      type_schedule: null,
      salary_from: 0,
      salary_to: 0,
      currency_code: null,
      text_conditions: null,
      text_responsibilities: null,
      text_requirementsatus: null,
    },
    company: {
      id: null,
      name: null,
      color: null,
      image: null,
      address: null,
      email: null,
      city: null,
      phone: null,
      time_zone: null,
      description: null,
      video: null,
    },
    stages: [
      {
        code: '',
        sort: 0,
      },
    ],
    currentStage: {
      group: false,
      action: false,
    },
  },
);

export default automaticReducer;
