import React from 'react';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { VacancyI } from '../../../types/vacancy';
import StyledLink from '../../StyledComponents/StyledLink';
import * as Styled from './StyledPresentationVacancy';

interface PresentationVacancyProps {
  vacancy: VacancyI;
}

const PresentationVacancy: React.FunctionComponent<PresentationVacancyProps> = ({
  vacancy,
}) => {
  const { dictionaries } = useTypedSelector(r => r.mainData);

  return (
    <Styled.VacancyInfo>
      <h2 className="visually-hidden">Информация о вакансии</h2>
      <div className="wrap">
        <span className="name">Вакансия:</span>
        <br />
        <span className="title">{vacancy.name}</span>
      </div>
      <div className="wrap">
        <span className="name">Тип вакансии:</span>
        <br />
        <span className="title">
          {dictionaries &&
            dictionaries.vacancyLocation?.map(
              (el: { id: number; title: string }) => {
                return el.id === vacancy.type_location ? el.title : '';
              },
            )}
        </span>
      </div>
      <div className="wrap">
        <span className="name">Заработная плата:</span>
        <br />
        <span className="title">
          от {vacancy.salary_from.toLocaleString('ru')} &#8381; до{' '}
          {vacancy.salary_to.toLocaleString('ru')} &#8381;
        </span>
      </div>
      <div className="wrap">
        <span className="name">График:</span>
        <br />
        <span className="title">
          {dictionaries &&
            dictionaries.vacancySchedule?.map(
              (el: { id: number; title: string }) => {
                return el.id === vacancy.type_schedule ? el.title : '';
              },
            )}
        </span>
      </div>
      <Styled.LinkWrapper>
        <StyledLink
          text="Смотреть вакансию"
          externalLink
          externalLinkPath={vacancy.url}
        ></StyledLink>
      </Styled.LinkWrapper>
    </Styled.VacancyInfo>
  );
};

export default PresentationVacancy;
