import axios from 'axios';
import { Dispatch } from 'redux';
import { config, url } from './actionsAssets';
import { VacancyActions, VacancyActionTypes } from '../../types/vacancy';
import { CompanyActions, CompanyActionTypes } from '../../types/company';
import { AutomaticActions, AutomaticActionTypes } from '../../types/automatic';
import { IParams } from '../../types/test';

export const fetchVacancy = (id: string | null) => {
  return async (dispatch: Dispatch<VacancyActions>) => {
    const item = await axios.get(`${url}vacancy/${id}/`, config);
    console.log('vacancy', item.data);
    dispatch({
      type: VacancyActionTypes.FETCH_VACANCY,
      payload: item.data,
    });
  };
};

export const fetchCompany = (id: number | null) => {
  return async (dispatch: Dispatch<CompanyActions>) => {
    const item = await axios.get(`${url}company/${id}/`, config);
    dispatch({
      type: CompanyActionTypes.FETCH_COMPANY,
      payload: item.data,
    });
  };
};

export const fetchStageVacancy = (id: string | null) => {
  console.log('id', id);
  return async (dispatch: Dispatch<VacancyActions>) => {
    const item = await axios.get(`${url}vacancy/${id}/autostage/`, config);
    item.data.unshift({
      code: 'RESUME',
      sort: 0,
    });
    return dispatch({
      type: VacancyActionTypes.FETCH_STAGE_VACANCY,
      payload: item.data,
    });
  };
};

export const fetchCurrentStage = (vacancy_id: string | null) => {
  return async (dispatch: Dispatch<AutomaticActions>) => {
    try {
      const res = await axios.get(
        `${url}automatic/${vacancy_id}/stage/`,
        config,
      );
      dispatch({
        type: AutomaticActionTypes.FETCH_CURRENT_STAGE,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const setNextStage = (vacancy_id: string | number) => {
  return async (dispatch: Dispatch<AutomaticActions>) => {
    const res = await axios.put(
      `${url}automatic/${vacancy_id}/stage/`,
      {},
      config,
    );
    dispatch({
      type: AutomaticActionTypes.FETCH_CURRENT_STAGE,
      payload: res.data,
    });
  };
};
