import React from 'react';
import styled from 'styled-components';

const StyledRadioWrap = styled.div`
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    position: relative;
    display: block;
    margin: 0;
    padding-left: 32px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.374px;
    color: var(--main-text-color);
  }

  input ~ label::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 2px solid var(--main-text-color);
    transition: box-shadow 0.5s;
    cursor: pointer;
    border-radius: 2px;
  }
  input:checked ~ label::after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: var(--main-accent-color);
    border-radius: 2px;
  }

  label:hover,
  label:focus {
    &::before {
      border: 2px solid var(--main-accent-color);
    }
  }

  @media (min-width: 768px) {
    input ~ label::before {
      top: 0;
    }

    input:checked ~ label::after {
      top: 5px;
    }
  }
`;

interface PropsInterface {
  name?: string;
  id?: string;
  value?: string | number;
  checked?: boolean;
  handleСhange?: (val: any) => void;
  keyFlag?: string | number;
}

const StyledRadioInput: React.FC<PropsInterface> = ({
  name,
  id,
  value,
  checked,
  children,
  handleСhange,
  keyFlag,
}) => {
  return (
    <StyledRadioWrap key={keyFlag}>
      <input
        className="visually-hidden"
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={checked}
        onChange={handleСhange}
      />
      <label htmlFor={id}>{children}</label>
    </StyledRadioWrap>
  );
};

export default StyledRadioInput;
