import React from 'react';
import { ListItemI } from '../../../types/test';
import { createClassByStatus } from './functions';
import { arrowDownIcon, arrowUpIcon, checkIcon } from './icons';
import * as Styled from './StyledTestList';

interface TestListProps {
  title: string;
  items: ListItemI[];
  selectedTest: number;
}

const TestList: React.FunctionComponent<TestListProps> = ({
  title,
  items,
  selectedTest,
}) => {
  return (
    <Styled.TestNav>
      <Styled.Title>{title}</Styled.Title>
      <Styled.TestListContainer>
        {items.map((testItem: ListItemI, i: number) => (
          <Styled.TestItem
            className={`${createClassByStatus(testItem.status)} ${i ===
              selectedTest && 'd-none d-md-block active'}`}
            key={testItem.id}
          >
            <button className="button" type="button" tabIndex={-1}>
              {testItem.name}
              {i === selectedTest ? arrowDownIcon : arrowUpIcon}
              {checkIcon}
            </button>
          </Styled.TestItem>
        ))}
      </Styled.TestListContainer>
    </Styled.TestNav>
  );
};

export default TestList;
