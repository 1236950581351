import { TaskStatuses } from '../../../types/test';

export const createClassByStatus = (status: TaskStatuses): string => {
  const classString = '';
  switch (status) {
    case TaskStatuses.DONE:
      return `${classString}done`;
    case TaskStatuses.NOT_STARTED:
      return classString;
    case TaskStatuses.PROGRESS:
      return classString;
    case TaskStatuses.STARTED: {
      return classString;
    }
    default: {
      return classString;
    }
  }
};
