import { combineReducers } from 'redux';
import mainDataReducer from './reducers/mainData';
import testReducer from './reducers/test';
import testingReducer from './reducers/trialWork';
import recordingReducer from './reducers/recordingTest';
import authReducer from './reducers/auth';
import interviewReducer from './reducers/interview';
import automaticReducer from './reducers/automatic';

const reducer = combineReducers({
  mainData: mainDataReducer,
  test: testReducer,
  testing: testingReducer,
  recording: recordingReducer,
  auth: authReducer,
  interview: interviewReducer,
  automatic: automaticReducer,
});

export default reducer;
export type RootState = ReturnType<typeof reducer>;
