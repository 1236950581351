export interface ITrialWork {
  description: string;
  files: string[];
  name: string;
  target: string | number;
  time_limit: string | number;
  vacancy_id: string | number;
}

export enum TRialWorkActionTypes {
  TRIAL_WORK_START = 'TRIAL_WORK_START',
  FETCH_TRIAL_WORK_DATE = 'FETCH_TRIAL_WORK_DATE',
}

interface TrialWorkStartAction {
  type: TRialWorkActionTypes.TRIAL_WORK_START;
  payload: boolean;
}
interface FetchTrialWorkDataAction {
  type: TRialWorkActionTypes.FETCH_TRIAL_WORK_DATE;
  payload: ITrialWork;
}

export interface IFileProps {
  id: number | string;
  title: string;
  url: string;
}
