import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './StyledAudioTrack';

/* eslint-disable */

const AudioTrack = ({ stream }) => {
  const obj = useRef({});
  const canvasElement = useRef(null);

  // код

  function init() {
    obj.current.canvas = canvasElement.current;
    obj.current.ctx = obj.current.canvas.getContext('2d');
    obj.current.width = 500;
    obj.current.height = 40;
    obj.current.canvas.width = obj.current.width * window.devicePixelRatio;
    obj.current.canvas.height = obj.current.height * window.devicePixelRatio;
    obj.current.canvas.style.width = obj.current.width + 'px';
    obj.current.canvas.style.height = obj.current.height + 'px';
    obj.current.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
  }

  let timeOffset = 5;
  let now = parseInt(performance.now()) / timeOffset;

  function loop() {
    obj.current.ctx.clearRect(
      0,
      0,
      obj.current.canvas.width,
      obj.current.canvas.height,
    );

    let max = 0;

    if (parseInt(performance.now() / timeOffset) > now) {
      now = parseInt(performance.now() / timeOffset);
      obj.current.analyser.getFloatTimeDomainData(obj.current.frequencyArray);
      for (let i = 0; i < obj.current.frequencyArray.length; i++) {
        if (obj.current.frequencyArray[i] > max) {
          max = obj.current.frequencyArray[i];
        }
      }
      max = max * 100;

      var freq = Math.floor(max * 1.2);

      obj.current.bars.push({
        x: obj.current.width,
        y: obj.current.height / 2 - freq / 2,
        height: freq,
        width: 1,
      });
    }
    draw();
    requestAnimationFrame(loop);
  }

  obj.current.bars = [];

  function draw() {
    for (let i = 0; i < obj.current.bars.length; i++) {
      //console.log(obj.current.bars[i].y, obj.current.bars[i].height);
      const bar = obj.current.bars[i];
      obj.current.ctx.fillStyle = '#00E5D2';
      //console.log(bar.height - 97);
      //obj.current.ctx.fillRect(bar.x, bar.y + 57, bar.width, bar.height - 97);
      obj.current.ctx.fillRect(bar.x, bar.y, bar.width, bar.height);
      bar.x = bar.x - 3;

      if (bar.x < 1) {
        obj.current.bars.splice(i, 1);
      }
    }
  }

  useEffect(() => {
    if (stream && stream.active) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContent = new AudioContext();
      const streamSource = audioContent.createMediaStreamSource(stream);

      obj.current.analyser = audioContent.createAnalyser();
      streamSource.connect(obj.current.analyser);
      obj.current.analyser.fftSize = 512;
      obj.current.frequencyArray = new Float32Array(
        obj.current.analyser.fftSize,
      );
      init();
      loop();
    }
  }, [stream]);

  return (
    <Styled.AudioTrack>
      <canvas id="canvas" ref={canvasElement}></canvas>
    </Styled.AudioTrack>
  );
};

export default React.memo(AudioTrack);
